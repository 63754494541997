@import "../../config";

/**
 * InputNumber
 *
 *
 * @author Björn Hase
 *
 */

$input-number--input-border-color: transparent !default;
$input-number--input-background-color: white !default;

@include exports("modules/forms/InputNumber") {

	@include critical() {
		.input-number {
			input[type="number"], button {
				float: left;
			}

			input[type="number"] {
				text-align: center;
				padding: 10px 8px;
				width: 60px;
				height: 50px;
				background-color: $input-number--input-background-color;

				appearance: none;
				-moz-appearance: none;
				-webkit-appearance: none;

				border: {
					style: solid;
					width: 0;
					color: $input-number--input-border-color;
					top-width: 1px;
					bottom-width: 1px;
				}

				border-radius: 0;
			}

			input[type='number'] {
				-moz-appearance:textfield;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}

			@include pie_clearfix();
		}

		.no-js {
			.input-number {
				input[type="text"] {
					border-width: 1px;
				}

				.button {
					display: none;
				}
			}
		}
	}

	@include non-critical() {

	}
}
