$img--border-color: $ci-color2-3 !default;



img {
	max-width: 100%;

	&.img-thumbnail {
		padding: 0;
		border-color: $img--border-color;
	}
}

.figure {
	max-width: 100%;
}


.img-gallery {
	&--item {
		margin-bottom: 1rem;
	}
}