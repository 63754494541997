$button--cta--border-color: $ci-orange !default;
$button--cta--background-color: $ci-orange !default;
$button--cta--padding: 11px 20px !default;

$button--std--border-color: $ci-border-color-3 !default;
$button--std--background-color: #000 !default;
$button--std--padding: 11px 1rem !default;

$button--color--background-color-1: $ci-orange !default;
$button--color--background-color-2: #000 !default;
$button--color--background-color-3: $ci-color2-8 !default;
$button--color--background-color-4: rgb(182, 0, 0) !default;
$button--color--background-color-5: #28c928 !default;
$button--color--background-color-7: #fffffe !default;
$button--color--background-color-8: #6a655f !default;


@include exports("screen/base/buttons") {

	.btn {
		font-size: rem(14px);
		padding: 0.525rem 1rem;
		text-decoration: none;

		// Call-to-action
		&-cta {
			font-size: rem(16px);
			// padding: $button--cta--padding;
			font-weight: 900;
			letter-spacing: 1.5px;
			background-color: $button--cta--background-color;
			color: #fff;

			&:focus, &:hover, &:active {
				background: #fff;
				color: $button--cta--background-color;
			}

			&.color-2 {
				&:focus, &:hover, &:active {
					background: $button--color--background-color-2;
					color: #fff;
				}	
			}
		}

		// white outline
		&-wol {
			font-size: rem(24px);
			font-weight: 400;
			letter-spacing: 1.5px;
			background-color: transparent;
			border-color: #fff;
			color: #fff;

			&:focus, &:hover, &:active {
				background: #fff;
				color: $button--cta--background-color;
			}
		}

		// black outline
		&-bol {
			font-family: "Helvetica Neue",sans-serif;
			font-size: rem(26px);
			font-weight: bold;
			letter-spacing: 1px;
			background-color: transparent;
			border: 2px solid #000;
			color: #000;
			padding: 0.5rem 2.6rem 0.5rem 2.6rem;
			border-radius:0px;

			&:focus, &:hover, &:active {
				background: #000 !important;
				color: #fff;
			}
		}

		// black
		&-black {
			font-size: rem(16px);
			// padding: $button--cta--padding;
			font-weight: 900;
			letter-spacing: 1.5px;
			background-color: #000;
			color: #fff;

			&:focus, &:hover, &:active {
				background: #fff;
				color: #000;
			}
		}

		// grey
		&-grey {
			font-size: rem(16px);
			// padding: $button--cta--padding;
			font-weight: 900;
			letter-spacing: 1.5px;
			background-color: #b2b2b2;
			color: #fff;
			border-color: #000;

			&:focus, &:hover, &:active {
				background: $button--cta--background-color;
				color: #fff;
			}
		}

		// Standard
		&-std {
			padding: $button--std--padding;
			background-color: $button--std--background-color;
			border-color: $button--std--border-color;
			color: #fff;

			&:focus, &:hover, &:active {
				border-color: $button--cta--border-color;
				color: $ci-orange;
			}

			.input-group-btn & {
				padding-top: 0;
				padding-bottom: 0;
				height: 40px;
				line-height: 40px;
			}
		}

		&-ghost {
			background-color: transparent !important;
		}

		// Color buttons
		&-color {
			&-1 {
				background-color: $button--color--background-color-1;
			}
			&-2 {
				background-color: $button--color--background-color-2;
			}
			&-3 {
				background-color: $button--color--background-color-3;
			}
			&-4 {
				background-color: $button--color--background-color-4;
			}
			&-5 {
				background-color: $button--color--background-color-5;
			}
			&-7 {
				background-color: $button--color--background-color-7;
			}
			&-8 {
				background-color: $button--color--background-color-8;
			}
		}

	}
}
