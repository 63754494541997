@import "../config";

    @include critical{

      .row.whitewrap{
        background-color:#fff;
      }

      .slash-bk{
        background-color: #000;
      }
      /* .brandcampaign{
        &.newheadline, .bubble{
          opacity: 0;
        }
      } */
    }

    @include non-critical{

      #brandspot{
        width:100%;
        z-index: 0;
      }

      .flip{
        transform: rotate(180deg);
        float:right;
        margin-right: -1px;
      }

      .site-element-2, .site-element-3, .site-element-4{
        opacity: 0;
        transition: 2.5s ease;
      }

      .orangelink{
        margin-left: 10px;
        color: #ee7f00;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 400;
        display:none;
        &:hover{
          color:#fff;
          cursor: pointer;
        }
      }

      .orangelink-2{
        color: #ee7f00;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 400;
        display:inline-block;
        margin: 10px auto;
        &:hover{
          color:#fff;
          cursor: pointer;
        }
      }

      p{
        font-size: 20px;
      }
      
      .img-responsive{
        &>p{
          font-size: 18px;
        }
      }

      .newheadline-wrapper{
        position: absolute;
        z-index: 1;
        word-break: break-word;
        hyphens: auto;
        width: 100%;
      }
        
      .main-content--wrapper.container{
        &>div>div>.row.whitewrap{
          background-color:#fff;
          width:100%;
          justify-content: center;
          align-items: center;
          margin: 75px 0px;
        }
        &>div>div>.row.newcampaign{
          width:100vw;
          padding:0;
        }
      }

        .newheadline{
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          width: 26%;
          margin-left: 5px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2em;
          opacity: 0;
          transition:1s ease;
          &-wrapper{
            margin-top: 13%;
          }
        }

        .main-content--wrapper.container{
          width:100vw;
          &>div>div>.row.galery{
            width:100vw;
          }
        }

        .innercapt {
          width:100%;
          min-height: 180px;
          background-color:#000; 
          padding: 20px;
      }
      
      @media (min-width: 10em) and (max-width: 60em) {
          .innercapt {
              min-height: 380px;
          }
      }
      
      .slick-arrow {
          color: #fff !important;
          font-size: 2.5em !important;
          top: 42% !important;
          text-shadow: 2px 2px 10px #000;
      }
      
      .slick-list {
           padding-top:2%;
           bottom: -5px;
      }

        .slideimg{
          max-width:500px;
          margin: 0px auto;
        }

        .slick-dots{
          display:none;
        }

        .newsubline{
          color: #000;
          margin: 0 auto;
          font-size: 20px;
        }

        .img-slash{
          height: 600px;
          max-height: 100%;
          max-width: unset;
        }

        .slash-bk{
          background-color:#000;
          display:none;
        }

        .fancy-text{
          padding: 50px 10px;
          max-width: 100%;
          margin: 0px auto;
          &>p{
            margin:0px;
            color:#868686;
          }
        }

        .fancy-text-2{
          padding: 50px 10px;
          max-width: 100%;
          margin: 0px auto;
          &>p{
            margin:0px;
            color:#868686;
          }
        }

        .main-content--wrapper.container{
          width:100vw;
          &>div>div>.row.galery{
            width:100vw;
          }
        }

        .overflow-hidden{
          overflow: hidden;
        }

        .youtubevideo{
          overflow: hidden;
        }

        .graphicelementLP{
          position: absolute;
          right: 0.2%;
          height: 101%;
          top: -2px;
          &.linesmall{
            position: absolute;
            left: -146px;
            max-width: none
          }
        }

        .buttonwrap{
          display:block;
        }

        .productlink{
          margin:0px auto;
        }

        .newcampaign{
          overflow: hidden;
          padding: 0px 10px;
          &>.campaignimage{
            background-image: url("/volumes/pim/cases/purebase/purebase500/landingpage/mood.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 70% 100%;
            overflow: hidden;
            max-height: 600px;
            min-height: 500px;
            &:before{
              background-color: black;
              transform: rotate(18deg);
              content: '';
              position: absolute;
              height: calc(100% + 95px);
              width: 200px;
              right: -105.5px;
            }
          }
        }

        .linenewflip{
          transform: rotate(180deg);
        }

        h1.light{
          font-size: 20px;
        }

        .center{
          text-align: center;
          &>*{
            text-align: center;
          }
        }

        .text-right{
          text-align: right;
        }

        .text-center{
          text-align: center;
        }

        .bubble{
          width:80px;
          position: absolute;
          transition:1s ease;
          opacity: 0;
        }

        .join {
          float: right;
          z-index: 3000;
          text-align: right;
          margin-top: 0;
        }

        .exeption{
          color: #666;
          font-size: 12px;
        }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
          &>p{
            color: #666;
            font-size: 12px;
            &>strong{
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .privacytext{
          color: #666;
          font-size: 12px;
        }

        #submit{
          margin-top: .65rem;
          margin-bottom: 20px;
          padding: 8px 40px;
        }

        h2{
          /* text-transform: uppercase; */
          color:#fff;
          font-weight: 700;
        }

        .radio>label{
          &:hover{
            cursor: pointer;
          }
          &>input{
            margin-right: 10px;
          }
          
        }

        .lines{
          opacity:0.6;
        }

        .bubble{
          max-height: 180px;
          right: 40px;
          top: 145px;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        strong{
          color:#fff;
        }

        td{
          padding:5px;
        }

          @media (min-width: 490px){
            .newheadline-wrapper{
              margin-top:29%;
            }
          }

          @media (min-width: 576px){
            .main-content--wrapper.container {
              &>div>div>.row{
                width: 540px;
                max-width: 100%;
                margin: 0px auto;
              }

              .newheadline{
                font-size: 3.6vw;
              }
            }
          }

          @media (min-width: 768px) {

            .main-content--wrapper.container{
              &>div>div>.row{
                width: 720px;
                max-width: 100%;
                margin: 0px auto;
              }
            }

            .newsubline{
              font-size: 28px;
            }

            .site-element-1{
              opacity:0;
              transition: 2.5s ease;
            }

            .newheadline{
              margin-left: 10px;
              width: 30%;
              font-size:50px;
              &-wrapper{
                margin-top: 22%;
              }
            }

            .main-content--wrapper.container{
              &>div>div>.row.whitewrap{
                margin: 150px 0px;
              }
            }

            .bubble{
              width:140px;
              left: -3%;
              top: 60px;
            }

            p{
              font-size: 30px;
            }

            .graphicelementLP{
              right: 5%;
              top:0px;
            }

            .newcampaign{
              &>.campaignimage{
                &:before{
                  right: -85px;
                }
              }
            }
          }

          @media (min-width: 802px){
            .orangelink-2{
              display:none;
            }

            .orangelink{
              display:inline-block;
            }
          }

          @media (min-width: 992px) {
            .main-content--wrapper.container{
              &>div>div>.row{
                width: 960px;
                max-width: 100%;
                margin: 0px auto;
              }
            }

            .slash-bk{
              display:block;
            }

            .fancy-text{
              padding: 40px 5% 40px 20%;
              max-width: 1220px;
            }
    
            .fancy-text-2{
              padding: 40px 20% 40px 5%;
              max-width: 1220px;
            }

            .newheadline-wrapper{
              margin-top: 30%;
            }

            .newcampaign{
              &>.campaignimage{
                &:before{
                  right: -77px;
                }
              }
            }
           
          }

          @media (min-width: 1200px) {
            .main-content--wrapper.container{
              &>div>div>.row{
                width: 1240px;
                max-width: 100%;
                margin: 0px auto;
              }
            }

            .newheadline{
              font-size: 76px;
              width: 30%;
              &-wrapper{
                margin-top: 20%;
              }
            }

            .newcampaign{
              &>.campaignimage{
                &:before{
                  right: -68px;
                }
              }
            }
          }

          @media (min-width: 1430px){
            .newheadline{
              width: 27%;
            }

            .bubble{
              left: -10%;
            }
          }
          
        
    }