@import "../../config";

/**
 *
 *
 *
 * @author Björn Hase
 *
 */

$file-selection--button--background-color: transparent !default;
$file-selection--button--font-size: 14px !default;
$file-selection--filename--font-size: 15px !default;
$file-selection--icon--font-size: 12px !default;

@include exports("modules/forms/FileSelection") {
	@include critical() {
		.file-selection {
			input[type="file"] {
				display: none;
			}

			&--button {
				font-size: em($file-selection--button--font-size);
				font-weight: normal;
				padding: 8px 12px;
				min-width: 0;
				border: 0;
				background-color: $file-selection--button--background-color;
			}

			&--filename {
				display: none;
			}

			&--empty, &--filename-text {
				font-size: em($file-selection--filename--font-size);
			}

			&--delete {
				&:before {
					font-size: em($file-selection--icon--font-size);
				}
			}

			&--hint {
				margin-top: 10px;
				font-size: 80%;
			}
		}

		.no-js {
			.file-selection {
				input[type="file"] {
					display: initial;
				}

				&--button, &--filename, &--empty, &--filename-text, &--delete {
					display: none;
				}
			}
		}
	}
}
