@import "../../config";

$quicksearch--background-color: #000 !default;
$quicksearch--height: 60px !default;

$quicksearch--input-size: 14px !default;
$quicksearch--input-bgcolor: #4c4c4c !default;
$quicksearch--input-color: #e1e1e1 !default;

$quicksearch--submit-bgcolor: #222222 !default;

$quicksearch--handle-width: 60px !default;
$quicksearch--handle-height: 74px !default;
$quicksearch--handle-font-size: 20px !default;
$quicksearch--handle-color: $ci-color2-5 !default;
$quicksearch--handle-color--hover: $ci-color2-9 !default;

@include exports("modules/header/Quicksearch") {

	@include critical() {
		.mainmenu--quicksearch {
			height: $quicksearch--height;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		.quicksearch {

			&--inner {
				width: 76%;
				float: left;
				@include to(desktop) {
					.container {
						padding: 0;
					}
				}
			}

			&--input {
				height: $quicksearch--height;
				background-color: $quicksearch--input-bgcolor;
				border: 0;
				color: $quicksearch--input-color;
				padding: 0 10px;
				outline: none;
				width: 100%;
				font-size: $quicksearch--input-size;
				-webkit-appearance: 0;
				border-radius: 0;
				&::-webkit-input-placeholder {
					font-size: $quicksearch--input-size;
					color: $quicksearch--input-color;
					font-weight: 400;
				}

			}

			&--handle {
				width: 24%;
				float: right;
				padding-left: 16px;
				padding-right: 16px;
				background-color: $quicksearch--submit-bgcolor;
				height: $quicksearch--height;
				text-align: center;
				line-height: $quicksearch--height;
				font-size: 22px;
				color: #fff;

			}
			&.is-opened &--handle i:before {
				@include from(desktop) {
					content: get-icon(cross);
				}
			}

			&--submit {
				&-wrapper {
					display: none;

				}
			}
		}

		////////////////////////////////////////////////////////////////////////
		//
		// Dropdown Menu
		//
		////////////////////////////////////////////////////////////////////////
		@include from(desktop) {

			.mainmenu--quicksearch {
				// position: relative;
				position: static;
				top: 0;
				float: left;
				height: 74px;
			}

			.quicksearch {

				visibility: visible;
				// position: relative;

				&--inner {
					top: 75px;
					position: absolute;
					display: none;
					float: none;
					width: 100%;
				}

				&--handle {
					display: inline-block;
					float: none;
					padding-left: 0;
					padding-right: 0;
					width: $quicksearch--handle-width;
					height: $quicksearch--handle-height;
					text-align: center;
					line-height: $quicksearch--handle-height - 2px;
					font-size: $quicksearch--handle-font-size;
					background: transparent;
					color: $quicksearch--handle-color;

					&:focus {
						color: $quicksearch--handle-color;
					}

					.is-opened & {
						color: $ci-color1;
						&:hover {
							color: $ci-color1 !important;
						}
					}

					&:hover {
						color: $quicksearch--handle-color--hover !important;
					}

				}
			}
		}
	}

	@include non-critical() {

		@include from(desktop) {

			.quicksearch.is-opened {

				.quicksearch {

					&--inner {
						display: block;
						// position: fixed;
						position: absolute;
						// left: 0;
						left: 50%;
						// right: 0;
						margin-left: -50vw;
						width: 100vw;
						height: 100px;
						background-color: $quicksearch--background-color;
						border-bottom: 1px solid $quicksearch--submit-bgcolor;
					}

					&--input {
						background-color: $quicksearch--background-color;
						height: 99px;
						font-size: 20px;

						&::-webkit-input-placeholder {
							font-size: 20px;
						}
					}

					&--submit-wrapper {
						display: block;

						button {
							width: 100%;
						}

					}
				}
			}
		}
	}
}
