@import "../../config";

$image-button--color: $link--color !default;
$image-button--hover-color: $link--hover-color !default;
$image-button--min-height: 85px !default;
$image-button--min-height--tablet: 235px !default;
$image-button--inner--border-color: $ci-color2-8 !default;
$image-button--inner--border-hover-color: $link--hover-color !default;

@include exports("modules/content/ImageButton") {
	@include critical() {
		.image-buttons {
			margin-bottom: 40px;
			h1, h2, h3, h4 {
				margin-top: 30px;
				margin-bottom: 25px;

				@include from(tablet) {
					margin-top: 45px;
					margin-bottom: 35px;
				}
				@include from(desktop) {
					margin-top: 72px;
					margin-bottom: 42px;
				}
			}
			&--wrapper {
				@media (min-width: 1190px) {
					.col-lg-3 {
						-ms-flex: 0 0 20%;
						flex: 0 0 20%;
						max-width: 20%;
					}
				}
			}
		}
		.image-button {
			position: relative;
			display: block;
			margin-bottom: 20px;
			@include from(tablet) {
				margin-bottom: 0;
			}
			& {
				color: $image-button--color;
				text-decoration: none;
			}
			&:hover, &:focus {
				color: $image-button--hover-color;
				text-decoration: none;

				.image-button--text-wrapper {
					color: $image-button--hover-color;
				}
				.image-button--inner {
					border-color: $image-button--inner--border-hover-color;

				}
			}
			&--inner {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 3px;
				border: 1px solid $image-button--inner--border-color;
				overflow: hidden;
				// @media (min-width: 578px) {
				// 	min-height:283px;
				// }
				// @media (min-width: 992px) {
				// 	min-height:319px;
				// }
				// @media (min-width: 1200px) {
				// 	min-height:366px;
				// }
			}
			&--content {
				position: relative;
				display: block;
				padding: 20px;
			}
			&--image-wrapper {
				display: block;
				float: left;
				min-height: 200px;
				line-height: 1;
				margin: 0 0 20px;
				@include from(tablet) {
					float: none;
					text-align: center;
				}
				img {
					display: block;
				}
			}
			&--text-wrapper {
				display: block;
				margin: 0;
				font-size: rem(20px);
				font-weight: 800;
			}
		}
	}
}
