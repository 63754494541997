////////////////////////////////////////////////////////////////////////////////
// defaults
////////////////////////////////////////////////////////////////////////////////

$default--border-color: #929395;
$default--border-radius: .25rem;

$default--animation-easing: ease-in-out;
$default--animation-duration: 400ms;

////////////////////////////////////////////////////////////////////////////////
// colors
////////////////////////////////////////////////////////////////////////////////

$bequiet-color-orange: #ee7f00;
$bequiet-color-red: #cb0120;
$bequiet-color-green: #16b401;
$bequiet-color-grey-1: #1a1a1a;
$bequiet-color-grey-2: #141414;
$bequiet-color-grey-3: #222;
$bequiet-color-grey-4: #262626;
$bequiet-color-grey-5: #4c4c4c;
$bequiet-color-grey-6: #666;
$bequiet-color-grey-7: #7f7f7f;
$bequiet-color-grey-8: #929395;
$bequiet-color-grey-9: #ccc;
$bequiet-color-grey-10: #212121;
$bequiet-color-grey-11: #333;
$bequiet-color-grey-12: #b2b2b2;
$bequiet-color-grey-13: #070707;
$bequiet-color-grey-14: #0f0f0f;
$bequiet-color-grey-15: #e1e1e1;


$ci-color1: $bequiet-color-orange;
$ci-color2: $bequiet-color-grey-1;
$ci-color2-2: $bequiet-color-grey-2;
$ci-color2-3: $bequiet-color-grey-3;
$ci-color2-4: $bequiet-color-grey-4;
$ci-color2-5: $bequiet-color-grey-5;
$ci-color2-6: $bequiet-color-grey-6;
$ci-color2-7: $bequiet-color-grey-7;
$ci-color2-8: $bequiet-color-grey-8;
$ci-color2-9: $bequiet-color-grey-9;
$ci-color2-10: $bequiet-color-grey-10;
$ci-color2-11: $bequiet-color-grey-11;
$ci-color2-12: $bequiet-color-grey-12;
$ci-color2-13: $bequiet-color-grey-13;
$ci-color2-14: $bequiet-color-grey-14;
$ci-color2-15: $bequiet-color-grey-15;


$ci-orange: $bequiet-color-orange;
$ci-red: $bequiet-color-red;
$ci-green: $bequiet-color-green;
$ci-grey: $bequiet-color-grey-1;

$ci-border-color: $default--border-color;
$ci-border-color-2: $bequiet-color-grey-10;
$ci-border-color-3: $bequiet-color-grey-8;
$ci-border-color-4: $bequiet-color-grey-3;


$ci-success: $bequiet-color-green;
$ci-error: $bequiet-color-red;
$ci-warn: $bequiet-color-orange;


$default--border-color: $bequiet-color-grey-8;


// general page
$page--background-color: #000;
$text--color: $ci-color2-9;
$header--color: #fff;
$header-border--color: $ci-color2-3;
$link--color: #fff;
$link--hover-color: $ci-color1;

//footer
$footer--border-color: #373a3c;
$footer--background-color: $page--background-color;


// Social Media icon colors. DO NOT CHANGE THEM!
$youtube-red:     #e91d00;
$google-plus-red: #dd4330;
$facebook-blue:   #395697;
$linkedin-blue:   #0076b7;
$xing-green:      #cfde00;


////////////////////////////////////////////////////////////////////////////////
// fonts
// Use pixels here! They will be converted properly
////////////////////////////////////////////////////////////////////////////////

$ci-font--font-family: "Helvetica Neue", sans-serif;
$ci-font: $ci-font--font-family; // shorthand

// general page
$base--font-size: 18px;
$base--line-height: 24px;
$base--font-family: $ci-font;
$base--font-weight: normal;

$headline--color: #fff;
$headline--font-family: $ci-font;

$headline-styles: (
	color: $headline--color,
	font-family: $headline--font-family,
	// line-height: $headline--line-height,
	font-weight: 200,
	// font-style: $headline--font-style,
	// text-transform: uppercase,
	margin-top: (25px - 10px), // - 10px for visual margin of 25px
	margin-bottom: (25px - 10px), // - 10px for visual margin of 25px

	tablet: (
		margin-top: (40px - 10px), // - 10px for visual margin of 40px
		margin-bottom: (40px - 10px), // - 10px for visual margin of 40px
	),
);

$h1: (
	font-size: 34px,
	line-height: 38px,
	font-weight: 800,

	tablet: (
		font-size: 38px,
		line-height: 42px,
	),
	desktop: (
		font-size: 50px,
		line-height: 50px,
	),
);

$h2: (
	font-size: 28px,
	line-height: 32px,

	tablet: (
		font-size: 31px,
		line-height: 35px,
	),
	desktop: (
		font-size: 34px,
		line-height: 40px
	),
);

$h3: (
	font-size: 22px,
	line-height: 26px,

	tablet: (
		font-size: 24px,
		line-height: 28px,
	),
	desktop: (
		font-size: 26px,
		line-height: 30px,
	),
);

$h4: (
	font-size: 20px,
	line-height: 24px,
	font-weight: 800,

	tablet: (
		font-size: 20px,
		line-height: 26px,
	),
);


@import "danger-zone";

// temporarily disabled! find out what polyfills are actually needed!
// @import "polyfill";
@import "functions";
@import "mixins";

@import "iconfont";




////////////////////////////////////////////////////////////////////////////////
// module overrides
////////////////////////////////////////////////////////////////////////////////

$main--max-width: 1240px;
$page--max-width: 1240px;

$form-input--font-size: 16px;
$select--placeholder--color: #ccc;

// $header--height: $offcanvas--header--height;
// $header--height--desktop: 150px;
// $header--height--desktop--small-header: 80px;

$mainmenu--mobile-drodown: true;
$mainmenu--fixed-submenu-width: false;
$mainmenu--item--color: #fff;
$mainmenu--item--hover--color: #fff;
$mainmenu--item--border: false;
$mainmenu--border-color: $ci-color2-3;

$mainmenu--back-stylistic-element--background-color: $ci-color1;
$mainmenu--back-handle--color: #fff;
$mainmenu-handle--color: #fff;
$mainmenu-handle--hover--color: #fff;
$mainmenu--submenu-wrapper--background-color: $ci-color2-3;
$mainmenu--submenulevel3-wrapper--background-color: $ci-color2-5;


$navfade--background-color: rgba(#000, 0.6);
$lightbox--thumbnails--border-color: $ci-color2-9 !default;
$lightbox--thumbnails--active--border-color: #000 !default;
$mfp-overlay-color: $ci-color2-3;
$mfp-overlay-opacity: 0.9;
$mfp-iframe-background: #fff;


$boxed-selection--inner--line-height: 20px;



//
// css/js communication
//
$css-conf: (
	default--animation-duration: $default--animation-duration,
	default--animation-easing: $default--animation-easing,

	mainmenu--mobile-drodown: $mainmenu--mobile-drodown,
);

$slicer-breakpoints:        0px      575px       767px       991px      1199px;
