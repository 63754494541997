@import "../../config";

/**
 * type
 *
 *
 * @author Björn Hase
 *
 */

$type--choice-color: $ci-color1;
$type--font-size: 16px !default;

@include exports("modules/forms/Type") {

	@include critical() {
		.type {
			.button {
				min-width: 0;
				width: auto;
				font-weight: 300;
				font-size: em($type--font-size);

				padding: 12px 10px;
				margin-bottom: 10px;
			}

			&--title {
				display: block;
				font-weight: normal;
				padding: 0 0 10px;
			}

			&--choice {
				color: $type--choice-color;
			}
		}

		.js {
			.type {
				select {
					display: none;
				}
			}
		}

		.no-js {
			.type {
				span {
					display: none;
				}
			}
		}
	}

	@include non-critical() {

	}

}
