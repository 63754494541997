@import "../../config";

/**
 *
 * @author Dennis Özet
 *
 */

$header-slider--item-title--color: $ci-color1 !default;
$header-slider--item-title--font-size: em(40px) !default;
$header-slider--item-subtitle--color: #fff !default;
$header-slider--item-subtitle--font-size: em(34px) !default;
$header-slider--item-text--font-size: em(18px) !default;

$header-slider--item--aspect-ratio--small: 575 / 315 !default;
$header-slider--item--aspect-ratio--medium: 768 / 330 !default;
$header-slider--item--aspect-ratio--large: 1920 / 550 !default;



@include exports("modules/header/HeaderSlider") {

	@include critical() {

		.headvisual.aspect-ratio {
			padding-top: 100% / $header-slider--item--aspect-ratio--small;

			@include from(smalldesktop) {
				padding-top: 100% / $header-slider--item--aspect-ratio--medium;
			}

			@include from(largedesktop) {
				padding-top: 100% / $header-slider--item--aspect-ratio--large;
			}
		}

		.header-slider {

			overflow: hidden;

			.aspect-ratio & {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}


			figure {
				margin: 0;
			}

			img {
				width: 100%;
			}

		}

		.header-slider--item {
			position: relative;
			display: none;

			// .no-js &:first-child {
			&:first-child {
				display: block;
			}

			&-content {
				position: relative;
				//padding: 10px 30px 40px 30px;
				// border-top: 2px solid $ci-color2-3;
				// border-bottom: 1px solid $ci-color2-4;

				visibility: hidden;

				// .js & {
				// 	visibility: hidden;
				// }


				height:100%;
				//Anpassung für den schwarzen Hintergrund
				//background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 12%, rgba(0,0,0,0.7) 49%, rgba(0,0,0,0.6) 88%, rgba(0,0,0,0) 100%);
				//background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0.7) 49%,rgba(0,0,0,0.6) 88%,rgba(0,0,0,0) 100%);
				//background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 12%,rgba(0,0,0,0.7) 49%,rgba(0,0,0,0.6) 88%,rgba(0,0,0,0) 100%);
				//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=1 );

				.slick-initialized & {
					visibility: visible;
				}

				@include from(smartphone) {
					padding: 20px 20px 40px;
				}
				@include from(smalldesktop) {
					position: absolute;
					top: 0;
					bottom: 0;
					// right: 40px;
					// left: 42.5%;
					// padding: 10px 0;
					// border-top: none;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				@include from(largedesktop) {
					max-width: 710px;
				}
				/* // Erzeugt bei smartphones einen Schimmereffekt am unteren Rand des Textbausteins
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: -1;

					background: radial-gradient(
						circle closest-corner at 70% -25%,
						rgba(#fff, 0.2) 0,
						transparent 100%
					);

					@include from(tablet) {
						background: radial-gradient(
							circle closest-corner at 75% -35%,
							rgba(#fff, 0.2) 0,
							transparent 100%
						);
					}

					@include from(smalldesktop) {
						content: none;
					}
				}
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: -1;

					background: radial-gradient(
						circle closest-corner at 50% 165%,
						rgba(#fff, 0.3) 0,
						transparent 100%
					);

					@include from(tablet) {
						background: radial-gradient(
							circle closest-corner at 40% 200%,
							rgba(#fff, 0.3) 0,
							transparent 100%
						);
					}

					@include from(smalldesktop) {
						content: none;
					}
				} */
			}

			&-inner {
				// flex: 1 1 auto;
				// align-self: center;
			}

			&-title {
				line-height: 1.125em;
				color: $header-slider--item-title--color;
				font-size: $header-slider--item-title--font-size;
				font-weight: 300;

				@include from(smalldesktop) {
					font-size: ($header-slider--item-title--font-size + em(8px));
				}

				@include from(largedesktop) {
					font-size: ($header-slider--item-title--font-size + em(36px));
				}
			}

			&-subtitle {
				margin-top: -30px;
				margin-bottom: 15px;
				line-height: 1.125em;

				color: $header-slider--item-subtitle--color;
				font-size: $header-slider--item-subtitle--font-size;
				font-weight: 900;
				min-height:10px;

				@include from(smalldesktop) {
					margin-top: -34px;
					margin-bottom: 30px;
					font-size: ($header-slider--item-subtitle--font-size + em(6px));
				}

				@include from(largedesktop) {
					margin-top: -50px;
					margin-bottom: 0;
					font-size: ($header-slider--item-subtitle--font-size + em(18px));
				}
			}

			&-text {
				display: none;
				margin: 15px 0 25px;
				font-size: $header-slider--item-text--font-size;
				color: $ci-color2-9;

				@include from(desktop) {
					display: block;
				}
			}

			&-link {
				text-align: center;

				@include from(smalldesktop) {
					text-align: left;
				}
			}
		}

	}

	@include critical(){
		.newcampaign{
			overflow: hidden;
			padding: 0px;
			/* &>.campaignimage{
			  background-image: url("/volumes/pim/cases/purebase/purebase500/landingpage/mood.jpg");
			  background-size: cover;
			  background-repeat: no-repeat;
			  background-position: 70% 100%;
			  overflow: hidden;
			  max-height: 600px;
			  min-height: 500px;
			} */
		}

		.graphicelement{
			position: absolute;
			left: 24%;
			height: 101%;
			top: -80px;
		}

		.custom-header-slider-item-4{
			overflow: hidden;
		}
		.custom-header-slider-item-4 .header-slider--item-title{
			color: white;
			line-height: 53px;
			font-family: 'Open Sans', sans-serif;
			font-size: 1.5em;
			text-transform: uppercase;
			font-weight: 600;
			font-stretch: condensed;
			line-height: 1em;
			position: absolute;
			top: -75%;
			right: 52%;
			line-height: 1.2em;
		}

		@media (min-width: 500px){
			.custom-header-slider-item-4 .header-slider--item-title{
				top: -90%;
			}
		}

		@media (min-width: 768px){
			.custom-header-slider-item-4 .header-slider--item-title{
				position: initial;
				top: -75%;
				right: 52%;
				font-size: 2.5em;
			}

			.custom-header-slider-item-4 .header-slider--item-content, .custom-header-slider-item-5 .header-slider--item-content{
				background-color: transparent;
			}

			.custom-header-slider-item-whiteleft .header-slider--item-title{
				color: black;
				line-height: 53px;
				font-family: 'Open Sans', sans-serif;
				font-size: 5em;
				text-transform: uppercase;
				font-weight: 400;
				font-stretch: condensed;
				line-height: 1em;
				position: absolute;
				top: -75%;
				line-height: 1.2em;
				padding-bottom: 0;
			}

			.custom-header-slider-item-whiteleft .header-slider--item-text{
				color: black;
				font-size: 1.2em;
			}

			@media (min-width: 500px){
				.custom-header-slider-item-whiteleft .header-slider--item-title{
					top: -90%;
				}
			}

			@media (min-width: 768px){
				.custom-header-slider-item-whiteleft .header-slider--item-title{
					position: initial;
					top: -75%;
					font-size: 3.5em;
				}

				.custom-header-slider-item-whiteleft .header-slider--item-content{
					background-color: transparent;
				}

			.graphicelement{
				top:-2px;
				left: 12%;
			}
		}

		@media (min-width: 1200px){
			.graphicelement{
				left: 24%;
			}
			/* .newcampaign{
				&>.campaignimage{
					&:before{
						right: -6%;
						width: 50vw;
						top: -85px;
					}
				}
			} */
		}
	}
}}
