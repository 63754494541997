@import "../../config";

// 38px = .btn.btn-std height
$product-variants--min-height: 41px !default;
$product-variants--margin: 10px !default;
$product-variant--is-active--border-color: $ci-color1 !default;
$product-variant--is-active--color: $ci-color1 !default;

@include exports("modules/product/ProductVariants") {
	@include critical() {

		.product-variants {
			margin-bottom: $product-variants--margin;
			&--show-button + & {
				display: none;

				.no-js & {
					display: block;
				}
			}
			&--show-button {
				.no-js & {
					display: none;
				}
			}

			// &--container {
				// margin: 20px 0;
				// @include from(desktop) {
				// 	margin: 40px 0;
				// }
			// }

			&--list {
				list-style: none;
				margin-top: 0;
				margin-bottom: 0;
				padding: 0;

				/* &#colorbox{
					-webkit-box-reflect: below 1px -webkit-linear-gradient(top,transparent 32%,rgba(255,255,255,.5) 100%);

					& > li{
						margin-bottom:0px!important;
					}
				} */
			}

			&--list > li {
				position: relative;
				// margin-bottom: $product-variants--margin;
				min-height: $product-variants--min-height;
				padding-bottom: 10px;
				padding-top: 10px;
			}

			&--title {
				text-transform: uppercase;
				@include from(tablet) {
					margin-bottom: -15px;
				}
			}
		}

		.product-variant {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			text-align: center;
			white-space: normal;
			&.is-active {
				border-color: $product-variant--is-active--border-color;
				color: $product-variant--is-active--color;
				//font-weight: 800;
			}
			&--image {
				display: block;
				margin: 0 auto 5px;
			}
			.btn-std {
				padding: 0.75rem;
			}
			@include from(desktop) {
				// &:hover {
				// 	position: absolute;
				// 	bottom: 0;
				// 	left: 10px;
				// 	right: 10px;
				// }
				// &:hover &--image {
				// 	display: block;
				// 	margin: 0 auto -15px;
				// }
				/* &:hover &--title {
					margin-bottom: 1px;
				} */
			}
		}

		.color-variant {
			&--item {
				// margin-bottom: $product-variants--margin;
				max-width: ($product-variants--min-height + 22);
				// padding: 0;
				// margin-right: 3px;
			}
			&--link {
				padding: 0;
				width: ($product-variants--min-height + 2);
				height: ($product-variants--min-height + 2);
				//text-indent: -9999em;
				position: relative;
				&.is-active {
					border: 3px solid #fff;
					&:hover{
						border:none;
					}
				}
			}
			&--overlay{
				width: 190px;
				z-index: 100;
				margin-left: -75px;
				padding: 10px 0px;
				background-color: #000;
				border: 1px solid #ee7f00;
				border-radius: .25rem;
				display: none;
			}
			@include from(desktop) {
				
				&--link{

					&:hover {
						.color-variant--overlay {
							display:block;
						}

						border:none;
					}
				}
			}
		}
	}
}
