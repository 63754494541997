@include exports("layout/main") {

	.main-content--wrapper {
		@include pie-clearfix();

		position: relative;

		@include from(smalldesktop) {
			.main-content {
				margin-bottom: 0;
			}

			// workaround for height of product teaser
			&--slider {
				position: relative;
				margin-bottom: 30px;
			}

		}
	}

	.main-content {
		position: relative;
		@include from(smartphone) {
			.intro
			{
				margin-bottom: 40px;
			}

		}
		@include from(tablet) {
			.intro
			{
				margin-bottom: 50px;
			}

		}

	}
}

@media print {
	.header {display: none;}
	.footer {display: none;}
	.printfullwidth {min-width:100% !important;}
	.btn {display: none;}
}
