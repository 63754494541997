@import "../../config";

/**
 *
 * @author Dennis Özet
 *
 */
$product-teaser-category--color: #fff !default;
$product-teaser-category--title--font-size: 20px !default;
$product-teaser-category--title--line-height: 18px !default;
$product-teaser-category--item--bg: $ci-color2-3 !default;
$product-teaser-category--item--radius: 0.25rem !default;

@include exports("modules/product/ProductCategoryTeaser") {
	@include critical() {
		.product-category-teaser {
			padding: 10px 0;
			color: $product-teaser-category--color;
			text-align: center;

			@include from(desktop) {
				padding: 50px 0;
			}

			.award--wrapper {
				img {
					display: block;
					width: 100%;
					height: auto;
					max-width: 420px;
					margin: 0 auto;
				}
			}

			.btn {
				margin-bottom: 10px;
				padding-left: 10px;
				padding-right: 10px;
				white-space: normal;
			}

			&--item {
				position: relative;
				min-height: 250px;
				padding: 20px 0;
			}

			&--inner {
				border-radius: $product-teaser-category--item--radius;
				padding: 20px;
			}

			&--title {
				display: block;
				position: absolute;
				left: 0;
				bottom: 15px;
				width: 100%;
				padding: 0 11%;

				font-weight: 900;
				font-size: em($product-teaser-category--title--font-size);
				line-height: line-height($product-teaser-category--title--line-height, $product-teaser-category--title--font-size);
			}

			&--subtitle {
				margin: 10px 0 0 0;
			}
		}

		@media (max-width: 1200px)
		{	
			.product-category-teaser .col-xl-2 {
				flex: 0 0 20%;
				max-width: 20%;
			}

			.product-category-teaser .col-xl-2.award-img {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (max-width: 768px)
		{
			.product-category-teaser .col-xl-2 {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		@media (min-width: 960px)
		{
			.icon-buttons--wrapper .col-lg-2 {
				flex: 0 0 33%;
				max-width: 33%;
			}
			.icon-button-frontpage {
				.icon-button--extra-content .icon-button--text-wrapper {
					font-size: 18px;
				}
				.icon-button--actions-wrapper {
					margin-top: 20px;
				}
			}
		}

		@media (min-width: 1200px)
		{
			.icon-buttons--wrapper .col-lg-2 {
				flex: 0 0 20%;
				max-width: 20%;
			}
			.product-category-teaser .col-xl-2 {	
				flex: 0 0 15%;
				max-width: 15%;
			}
			.product-category-teaser .col-xl-2.award-img {
				flex: 0 0 25%;
				max-width: 25%;
			}
		}
	}
}
