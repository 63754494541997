/**
 * Basic styles for links
 *
 *
 * @author Dennis Özet
 */

$link--color: #000 !default;
$link--color--hover: $ci-color1 !default;
$link-icon--font-family: $ci-font--font-family !default;
$link-icon--font-size: 20px !default;
$link-icon--color: $ci-color2-9 !default;
$link-icon--sign--dimension: 55px !default;
$link-icon--sign--background-color: #000 !default;
$link-icon--sign--border-color: $ci-color2-10 !default;
$link-icon--sign--color: $ci-color2-12 !default;
$link-icon--bottom-margin: 20px !default;
$link-icon--cta--color: $ci-color1 !default;
$link-icon--cta--sign--background-color: $ci-color1 !default;
$link-icon--cta--sign--border-color: $ci-color1 !default;

 @include exports("base/links") {

 	a {
		color: $link--color;
		&:focus,
		&:hover,
		&:active {
			color: $link--color--hover;
			cursor: pointer;
			text-decoration: none;
		}
	}

	// Links with icon and text
	.link-icon {
		&:focus,
		&:hover,
		&:active,
		&.added-to-product-collector {
			text-decoration: none;
			.link-icon--sign {
				color: white;
				background: $link--color--hover;
				border-color: $link--color--hover;
			}
		}

		&--wrapper {
			margin: (-$link-icon--bottom-margin / 2) -30px;
			overflow: hidden;
		}

		&--item {
			display: block;
			min-width: 100%;
			padding: ($link-icon--bottom-margin / 2) 30px;
			float: left;
			// margin-bottom: $link-icon--bottom-margin;

			@include from(tablet) {
				// 	margin-bottom: 0;
				min-width: 50%;
			}
			// @include from(smalldesktop) {
			// 	min-width: 33.333%;
			// }
			@include from(desktop) {
				// min-width: 25%;
				min-width: 0;
			}
		}

		&--sign {
			border: 1px solid #888;
			display: inline-block;
			width: $link-icon--sign--dimension;
			height: $link-icon--sign--dimension;
			background: $link-icon--sign--background-color;
			color: $link-icon--sign--color;
			line-height: $link-icon--sign--dimension;
			text-align: center;
			vertical-align: middle;
			i {
				font-size: em(24px);
			}
		}

		&--text {
			display: inline-block;
			margin-left: 10px;
			color: $link-icon--color;
			font-family: $link-icon--font-family;
			font-weight: 500;
			font-size: em($link-icon--font-size);
		}

		// Call to action
		&--cta {
			.link-icon--sign {
				background: $link-icon--cta--sign--background-color;
				border: 1px solid $link-icon--cta--sign--border-color;
				color: #fff;
			}
			.link-icon--text {
				color: $link-icon--cta--color;
			}
		}

		&-box {
			border: 1px solid #ee7f00 !important;
    		border-radius: 0.25rem !important;
			overflow: hidden;
			padding: 0 !important;
			margin: 15px 0 !important;
			min-width: auto !important;
			width: auto;
			.link-icon--text {
				margin: 0 15px;
				color: #ccc;
			}
		}
	}
 }
