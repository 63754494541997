@import '../../config';

/**
 * flex
 *
 *
 * @author Björn Hase
 *
 */

@include exports("modules/helper/Flex") {
	@include critical() {

		.flex-wrap {
			display: flex;
			flex-wrap: wrap;
		}

		.flex-wrap-item {
			display: flex;
		}

		.margin-bottom-none {
			margin-bottom: 0;
		}

	}
}
