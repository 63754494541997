@import "../../config";

$series-category--textsize: 12px !default;

@include exports("modules/series/Category") {

	@include critical() {

		.series {

			&-category--item {

				margin: 0 0 15px;

				p {
					font-size: $series-category--textsize;
					font-weight: 400;
				}
			}

			&-category--item {

				&-img {
					width: 100%;
				}

				&-title {
					font-size: 14px;
					font-weight: 500;
				}

			}

			&-category--title-container {

				@include from(tablet) {
					order: 1;
					&:nth-child(3n+7) {
						order: 10;
					}
				}

				@include from(desktop) {
					order: -1 !important;
				}
			}
			&-category--description-container {
				font-size: 14px;
				min-height: 75px;
				@include from(tablet) {
					// min-height:56px;
					order: 2;
					&:nth-child(3n+8) {
						order: 20;
					}
				}

				@include from(desktop) {
					order: 0 !important;
				}
			}
			&-category--items-container {

				@include from(tablet) {
					order: 3;
					&:nth-child(3n+9) {
						order: 30;
					}
				}


				@include from(desktop) {
					order: 1 !important;
				}
			}

		}
	}
}
