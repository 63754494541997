@import "../config";

$dealer-search--item--border-color: $ci-color2-4 !default;
$dealer-search--item--background: linear-gradient(90deg, $ci-color2-3 0, #000 100%) !default;
$dealer-search--item--margin: 20px !default;
$dealer-search--item--padding: 20px !default;

$dealer-search--show-on-map--width: 120px !default;

$dealer-search--map--border-color: $default--border-color !default;

@include exports("modules/DealerSearch") {
	@include critical() {
		.dealer-search--zip-search {
			position: relative;

			.is-visible {
				position: relative;
				z-index: 10;
			}

			.is-hidden {
				position: absolute;
				width: 100%;
				visibility: hidden;
				z-index: -1;
			}
		}
		.dealer-logo{
			&-wrapper{
				border: 1px solid #262626;
				text-align:center;
				padding: 15px 10px;
			}
			margin:0px auto;
		}
	}
	@include non-critical() {
		.dealer-search--item {
			position: relative;
			border: 1px solid $dealer-search--item--border-color;
			background: $dealer-search--item--background;
			margin-top: $dealer-search--item--margin;
			padding: 20px;
		}

		@media (max-width: 500px){
			.dealer-search--item--title.h3 {
				font-size:1em;
			}
		}

		.dealer-search--show-on-map {
			width: $dealer-search--show-on-map--width;

			@include from(smalldesktop) {
				margin-top: 20px;
			}
		}
		.dealer-search-premium-img {
			width: 100%;
			height: auto;
			display: block;
			margin: 0 auto;
		}
		.dealer-search-partner {
			position: absolute;
			top: 0;
			right: 0;
			width: 72px;
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}

		.dealer-search--map {
			overflow: hidden;

			&.initialized {
				border: 1px solid $dealer-search--map--border-color;
				height: 300px;

				@include from(smalldesktop) {
					height: 460px;
				}
			}
		}

		.dealer-search-more {
			display: none;
		}
	}
}
