@import "../../config";

/**
 * Boxed
 *
 *
 *
 * @author Björn Hase
 *
 */

$boxed--border-color: $ci-color2 !default;
$boxed--contrast--border-color: $ci-color1 !default;
$boxed--notably--border-color: $ci-color1 !default;
$boxed--thick--border-color: $ci-color1 !default;

$boxed--title--color: white !default;
$boxed--title--border-color: white !default;
$boxed--title--underline--border-color: $ci-color2;

$boxed--border--padding: 20px !default;

@include exports("modules/helper/Boxed") {
	 @include critical() {

		.boxed {
			position: relative;
			width: 100%;
			padding: $boxed--border--padding;
			margin-bottom: 30px;
			border: 1px solid $boxed--border-color;

			&--title {
				margin-top: 0;

				&-underline {
					border-bottom: 1px solid $boxed--title--underline--border-color;
				}
			}

			&--notably {
				border-color: $boxed--notably--border-color;
			}

			&--thick {
				border-color: $boxed--thick--border-color;
				border-width: 3px;
			}

			&--button--bottom {
				@include from(tablet) {
					position: absolute;
					bottom: $boxed--border--padding;
				}
			}

			&--contrast {
				background-color: $boxed--contrast--border-color;

				.boxed--title {
					width: 100%;
					color: $boxed--title--color;
					border-color: $boxed--title--border-color;
				}
			}
		}
	}

	@include non-critical() {

	}
}
