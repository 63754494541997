@import "../config";

.nouserlike{
  width: 50px;
  margin: 0px auto;
  display: block;
  margin-top: 10px;
 }

 .checkfunctional.rounded-circle{
  display: block;
  background-color: #575757;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 14px;
  right: 12px;
  z-index: 999;
  &:hover{
    cursor: pointer;
  }
 }
