@import "../config";

$cookie-hint--background: rgba($ci-color2-3, 0.98) !default;
$cookie-hint--z-index: 2000 !default;

$cookie-hint--readmore--color: $ci-color1 !default;

@include exports("modules/Breadcrumb") {
	@include critical() {

		#cookie-policy-link:hover{
			cursor: pointer;
			color: #ee7f00;;
		}

		.modal-content {
			position: relative;
			background-color: #222;
			border: 1px solid rgba(0,0,0,.2);
			border-radius: .3rem;
			outline: 0;
			color: #ccc;
		}

		.modal, .modal-title {
			color: #ccc;
		}

		.modal-title{
		   display: contents;
		}

		.modal-footer {
			padding: 15px;
			text-align: right;
			border-top: 1px solid #4c4c4c;
			&>a{
				color: #7f7f7f;
				font-size:15px;
				&:hover{
					color:#fff;
				}
			}
		}

		.modal-header .close {
			color: #7f7f7f;
		}

		.modal-body a {
			color: #7f7f7f;
			text-decoration:none;
			&:hover{
				color:#ee7f00;
			}
		}
		
		.cookie-hint {
			display: none;
		}

		.cookie-banner{
            &>.main--wrapper{
                background: #545454;
            }
            &-text{
                font-size:11px;
            }
            position: fixed;
            bottom: 0;
            width: 100vw;
            z-index: 1;
        }

        @media (min-width: 768px){
            .cookie-banner{
                &-text{
                    font-size:18px;
                }
            }
        }

        .cookie-footer{
            text-align: right;
            background: #000;
            font-size:15px;
        }

        .float-right{
            float: right;
        }

        .cookie-accept{
            padding: 0.8rem 6rem;
        }
	}
	@include non-critical() {
		.cookie-hint {
			display: block;
			background: $cookie-hint--background;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: $cookie-hint--z-index;

			padding: 20px 0;

			&--readmore {
				display: inline-block;
				color: $cookie-hint--readmore--color;
			}
		}

		.cookieinput{
			margin-right: 5px;
			margin-top: -5px;
			vertical-align: middle;
		}
 
		.onlycookie{
			/* float:right; */
			vertical-align: middle;
		}
 
		.cookielabel{
			cursor: default!important;
			&>.custom-control-indicator{
			 border-color: #7f7f7f!important;
			 background-color: #7f7f7f!important;
			}
			&>.custom-control-description{
				color:#767676;
			}
			&:hover{
			 color: #fff;
			 }
		 }
 
		   @media (min-width: 768px) {
 
			
		   }
 
		   @media (min-width: 992px) {
			
		   }
 
		   @media (min-width: 1200px) {
			 
		   }
	}
}
