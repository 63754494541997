@import "../config";


$series-compare--border-color: $ci-color2-11 !default;

@include exports("modules/SeriesCompare") {


	@include critical() {

		.series-compare {

			.series-list {
				margin: 0;
				font-size: 16px;
				line-height: 1.5;

				& li {
					margin: 0;
				}
			}

			.custom-control.custom-checkbox {
				position: absolute;
				left: 20px;
				bottom: 20px;
			}

			&--item {
				margin: 10px;
				border: 1px solid $series-compare--border-color;
				border-radius: $default--border-radius;
				position: relative;
				flex: 0 1 100%;

				@include from(desktop) {
					flex: 0 0 calc(50% - 22px);
					.browser-ie & {
						flex: 0 0 46.5%;
					}
				}

				&-inner {
					padding: 20px 20px 50px;
					//height: 100%;
				}

				&-wrapper {
					//flex-wrap: nowrap;
				}

				h4 {
					margin: 0 0 15px;
				}
			}

			&--table {
				border: 1px solid $default--border-color;
				border-radius: $default--border-radius;
				margin: 0 0 10px;
				overflow-x: scroll;

				@include from(largedesktop) {
					overflow: hidden;
				}

				& table {
					table-layout: fixed;
					width: 100%;
					font-size: 14px;
					border: 0;

					th,td {
						padding: .55rem;
						width: 150px;
						border: 1px solid #222222;
						text-align: center;

						&:nth-child(1) {
							border-right: 1px solid $default--border-color;
							width: 170px;
						}
					}


					tr {
						&:nth-child(1) {
							color: #fff;
						}
						td {
							&:nth-child(1) {
								color: #fff;

							}
						}
					}

					tbody tr {
						&:nth-child(even) {
							background-color: $bequiet-color-grey-14;
						}
						&:nth-child(odd) {
							background-color: $bequiet-color-grey-13;
						}
					}

				}
			}
		}

	}
}