@import "../config";

$product-button--border-color: $ci-color2-11 !default;
$product-box--flex: 1 1 100% !default;
$product-box--flex-smalldesktop: 1 1 45% !default;
$product-box--flex-largedesktop: 1 1 25% !default;

$filter-box--bg-color: #000 !default;
$config-border--color: $ci-color2-3 !default;
$config-custom--color: $link--color !default;

$progress-height: 28px !default;
$progress-background--color-small: red !default;
$progress-background--color-yellow: #fbff1b !default;
$progress-background--color-medium: orange !default;
$progress-background--color-large: #5cb85c !default;

@include exports("modules/PsuCalculatorNew") {

	@include critical() {

		#blk_focus_auswahl {
			@include from(smalldesktop) {
				margin-top: -220px;
			}
			@include from(desktop) {
				margin-top: 0;
			}
		}

		.pricebreak{
			word-break: break-all;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		.config-tools {
			@include from(smartphone) {
				display:none!important;
			}

			@include from(desktop) {
				display:flex!important;
			}
		}

		.template-psu-calculator {

			.btn-hidden {
				display: none;
			}

			.container-break--line-wrapper {
				//margin: 0 -10px;

				&.is-bordered {
					border-top: 2px solid $config-border--color;
					border-bottom: 2px solid $config-border--color;

					&:last-child {
						border-bottom: 2px solid $config-border--color;
					}

					#btn_back{
						width:80%;
					}
				}
			}

			#blk_cpu>div>div>.col-xs-12{
				display:inline-block;
				float:left;
			}

			#blk_cpu>div>.col-xs-12{
				padding:0px;
			}

			#blk_cpu>.mb-2>.col-xs-12{
				padding:0px 10px;
			}

			label[for=cpu_model]{
				float:left;
			}

			.icon-black{
				display:none;
			}

			.slider-handle{
				cursor:pointer;
				z-index:100;
			}

			#blk_focus>.row{
				@include from(smartphone) {
					margin-bottom:15px;
				}
				@include from(tablet) {
					margin-bottom:15px;
				}
			}

			.prefmobile{
				@include from(smartphone) {
					margin-left:-30px;
				}
				@include from(tablet) {
					margin-left:0px;
				}
			}

			.serie.card{
				@include from(smartphone) {
					padding: 0px
				}
				@include from(tablet) {
					padding-left:10px;
					padding-right:10px;
				}
			}

			.custom-control-description {
				color: $config-custom--color;
				@include from(smartphone) {
					font-size:16px;
				}
			}

			#result .series--image-container
			{
				margin: 0 auto;

				@include from(smartphone) {
					max-height: 350px;
					margin-left:0;
				}
				@include from(tablet) {
					max-height: 250px;
					margin-left:2.5%;
				}
					@include from(desktop) {
						margin-left: 5%;
					}
					@include from(largedesktop) {
						max-height: auto;
						margin-left: 0;
					}
			}

			//reconfigure flex grid for equal height boxes
			.series-container {

				min-height: auto;

				@include from(largedesktop) {
					flex-wrap: nowrap;
				}
				.serie,
				.produkte {
					flex: $product-box--flex;
					margin: 0 0px 10px;
					@include from(tablet) {
						margin-bottom: 20px;
					}
					@include from(smalldesktop) {
						flex: $product-box--flex-smalldesktop;
						.browser-ie & {
							flex-basis: 40%;
						}
					}
					@include from(largedesktop) {
						flex: $product-box--flex-largedesktop;
					}
				}

				.serie {
					border: 0;
					.browser-firefox & {
						@include from(smalldesktop) {
							margin-left: 0;
							margin-right: 0;
						}
					}
					.browser-ie & {
						@include from(smalldesktop) {
							margin-right: 40px;
						}
						@include from(largedesktop) {
							margin-right: 10px;
						}
					}
				}
				.produkte .card-text {
					font-weight: 400;
				}
			}

			.card {

				.tag {
					// margin-left: 10px;
					font-size: rem($base--font-size - 2px);
					font-weight: 800;
				}
				.tag-info {
					background-color: $ci-color1;
				}
				.tag-default {
					background-color: $ci-color2-15;
					color: #000;
				}

				&-highlight {
					border-color: $default--border-color;
					background-color: $ci-color2-3;
					font-size: $base--font-size;
				}

				&-title {
					font-size: $base--font-size + 2px;
					font-weight: 800;
					color: $config-custom--color;
					&>a.newpsuname, a.newpsuname>*{
						font-size: 16px;
						&:hover{
							color: #ee7f00;
						}
					}
				}

				&-text {
					font-size: $base--font-size - 2px;
					font-weight: 200;
				}
			}

			/*****************
			*	Styling progress bar start
			*	p2media - JH - Need to style progress bar itself. Bootstrap styling has problems in IE10+ width the progress bar.
			******************/

			// Firefox fix start
			.stride.stride-small::-moz-progress-bar {
				background-color: $progress-background--color-small;
			}
			.stride.stride-medium::-moz-progress-bar {
				background-color: $progress-background--color-medium;
			}
			.stride.stride-large::-moz-progress-bar {
				background-color: $progress-background--color-large;
			}
			// Firefox fix end

			//ie fix start
			.stride.stride-small {
				color: $progress-background--color-small;
			}
			.stride.stride-medium {
				color: $progress-background--color-medium;
			}
			.stride.stride-large {
				color: $progress-background--color-large;
			}
			//ie fix end

			.stride::-moz-progress-bar {
				border-top-left-radius: $default--border-radius;
				border-bottom-left-radius: $default--border-radius;
			}
			.stride {
				background-color: #000;
				height: $progress-height;
				width: 100%;
				border: 0 none;
				border-radius: $default--border-radius;
				margin-bottom: 1rem;



				&[value]::-webkit-progress-bar {
					background-color: $filter-box--bg-color;
					border-radius: $default--border-radius;
				}

				&[value],
				&[value]::-webkit-progress-value {
					border-top-left-radius: $default--border-radius;
					border-bottom-left-radius: $default--border-radius;
				}

				&-small {
					&[value]::-webkit-progress-value {
						background-color: $progress-background--color-small;
					}
				}

				&-medium {
					&[value]::-webkit-progress-value {
						background-color: $progress-background--color-medium;
					}
				}

				&-large {
					&[value]::-webkit-progress-value {
						background-color: $progress-background--color-large;
					}
				}

				&-container {
					position: relative;
				}

				&-title {
					position: absolute;
					left: 0;
					right: 0;
					font-size: $base--font-size + 6px;
					font-weight: 700;
					color: $config-custom--color;
				}
			}
			/*****************
			*	Styling progress bar end
			******************/

			.btn-back {
				margin: 20px 0;

				@include from(largedesktop) {
					margin-top: 0;
				}
			}
		}

		.psu-calculator {

			&--configuration {
				list-style-type: none;
				padding: 0;
				font-size: $base--font-size - 2px;

				b {
					color: $config-custom--color;
				}
			}

			&--form-icon {
				float: left;
				margin: 0 15px 0 0;
				height: 60px;
				@include from(tablet) {
					height: 80px;
				}
			}
			&--form-connection {
				@include from(desktop) {
					.my-2 {
						margin-top: 0 !important;
					}
				}
			}

			&--filter-box {
				border: 1px solid $default--border-color;
				background-color: $filter-box--bg-color;
				padding: 15px;
				margin-bottom: 20px;
				height: calc(100% - 20px);

				.custom-control-description {
					font-size: $base--font-size - 2px;
				}
				.custom-control,
				.custom-control+.custom-control {
					margin-right: 10px;
				}

				.number {
					color: $ci-color1;
					font-size: $base--font-size + 8px;
				}
			}

			&--filter-headline {
				font-weight: 700;
				// margin-bottom: 20px;
				height: 48px;
				color: $config-custom--color;
				overflow: hidden;
			}

			&--filter-counter {
				text-align: center;
				padding: 0;
				margin: 0;
				list-style-type: none;
				li {
					display: inline;
					font-size: $base--font-size + 2px;
					margin: 0 10px;

					&.minus{
						&:before {
							@include iconfont-icon(minus);
						}
					}

					&.plus {
						&:before {
							@include iconfont-icon(plus);
						}
					}
				}
			}
		}
	}

	@include non-critical(){

		.card.number {
			font-size: $base--font-size + 8px;
			color: $config-custom--color;
		}

		.onlymobile{
			display:none;
		}

		.nomobile{
			display:block;
		}

		@media (max-width: 768px){
			#configurationhide{
				display:none;
			}

			.nomobile{
				display:none;
			}

			.onlymobile{
				display:block;
			}
		}

		.card.number {
			font-size: 18px;
			color: $config-custom--color;
		}

		.template-psu-calculator .card .tag{
			position: absolute;
			top: -10px;
			left: -1px;
		}

		/* .series-container>.col-xs-6.col-md-6.col-xl-3:nth-of-type(3){
			display:none;
		} */
	}
}
