@import "../../config";


@include exports("layout/header") {}
@import "../../screen/layout/header";

$mainmenu--mobile-drodown: false !default;
$mainmenu--width: 261px !default;
$mainmenu--min-width: 260px !default;
$mainmenu--background-color: $page--background-color !default;
$mainmenu--border-color: $ci-border-color !default;

$mainmenu--item--height: 50px !default;
$mainmenu--item--height--desktop: 50px !default;
// 2016-03-18 MN: discussed with AV!
$mainmenu--item--font-size: 18px !default;
$mainmenu--item--line-height: 20px !default;
$mainmenu--item--color: $ci-color2-9 !default;
$mainmenu--item--hover--color: $ci-orange !default;

$mainmenu--item--border: false !default;
$mainmenu--item--border-color: $mainmenu--border-color;

$mainmenu--submenu-handle--icon-size: 12px !default;
$mainmenu--submenu-wrapper--border-color: $mainmenu--border-color !default;
$mainmenu--submenu-wrapper--background-color: $mainmenu--background-color !default;
$mainmenu--submenulevel3-wrapper--background-color: $mainmenu--background-color !default;
// @TODO JH: Define right ci-color
$mainmenu--submenu-wrapper--a---hover--background-color: #000;

$mainmenu--decoration--image: "none" !default;
$mainmenu--decoration--image--hidpi: "none" !default;
$mainmenu--decoration--image--width: 173px !default;
$mainmenu--decoration--image--height: 140px !default;
$mainmenu--decoration--image--container-height: 200px !default;

// If this variable is set to true, the `.mainmenu--back-handle` may be cut-off of the viewport
$mainmenu--fixed-submenu-width: true !default;
$mainmenu--max-levels: 3 !default;

$mainmenu--transition-duration: 400ms !default;
$mainmenu--transition-easing: ease-in-out !default;
$mainmenu--z-index: $header--z-index + 100 !default;

// @notneeded
// $mainmenu--back-stylistic-element--border-width: 5px !default;
// $mainmenu--back-stylistic-element--border-color: $ci-color2 !default;
$mainmenu--back-stylistic-element--background-color: $ci-color2 !default;

$mainmenu--back-handle--width: if($mainmenu--fixed-submenu-width,
	$mainmenu--width - $mainmenu--min-width,
	50px
) !default;
$mainmenu--back-handle--height: $mainmenu--back-handle--width !default;
$mainmenu--back-handle--color: contrast-color($mainmenu--back-stylistic-element--background-color) !default;


$mainmenu--close-handle--width: $mainmenu--back-handle--width !default;
$mainmenu--close-handle--height: $mainmenu--close-handle--width !default;


@include exports("modules/header/Mainmenu") {
	@include critical() {

		//
		// This part is for moving the whole mainmenu and page to the right if the mainmenu is open!
		//

		.mainmenu {
			position: fixed;
			position: absolute;
			top: 0;
			left: -$mainmenu--width;
			bottom: 0;
			width: 100%;
			min-width: $mainmenu--min-width;
			max-width: $mainmenu--width;
			background-color: $mainmenu--background-color;
			z-index: $mainmenu--z-index;

			.container {
				padding: 0;
			}

			.apply-transitions & {
				transition: left $mainmenu--transition-duration $mainmenu--transition-easing;
			}
			.csstransforms3d & {
				left: 0;
				// change for IOS rendering bug by ipad orientation change
				//transform: translate3d(-$mainmenu--width, 0, 0);
				transform: translateX(-$mainmenu--width);
			}
			.csstransforms3d .apply-transitions & {
				transition-property: transform;
			}

			&--is-opened & {
				left: 0;

				.csstransforms3d & {
					// change for IOS rendering bug by ipad orientation change
					//transform: translate3d(0, 0, 0);
					transform: translateX(0);
				}
			}

			&--wrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				border-right: 1px solid #282828;

				.scrollable-area {
					top: 0px;
				}
			}

			.level-1 {
				@include from(desktop) {
					> li {
						&:last-child {
							a {
								padding-right: 0;
							}
						}
					}
				}
			}

			.careermenu{
				color:#ee7f00;
				&:hover{
					color:#fff;
				}
			}
		}
		.offcanvas--page {
			position: relative;
			left: 0;

			.apply-transitions & {
				transition: left $mainmenu--transition-duration $mainmenu--transition-easing;
			}
			.csstransforms3d & {
				// change for IOS rendering bug by ipad orientation change
				//transform: translate3d(0, 0, 0);
				transform: translateX(0);
			}
			.csstransforms3d .apply-transitions & {
				transition-property: transform;
			}

			.mainmenu--is-opened & {
				left: $mainmenu--width;

				.csstransforms3d & {
					left: 0;
					// change for IOS rendering bug by ipad orientation change
					//transform: translate3d($mainmenu--width, 0, 0);
					transform: translateX($mainmenu--width);
				}
			}
		}



		//
		// This block is for positioning and transitioning the menu-levels
		//
		.mainmenu {
			&--menu-wrapper {
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				padding-top: 60px;

				// .apply-transitions & {
				// 	transition: left $mainmenu--transition-duration $mainmenu--transition-easing;
				// }
				// .csstransforms3d .apply-transitions & {
				// 	transition-property: transform;
				// }

				// @for $i from 1 through $mainmenu--max-levels {

				// 	.mainmenu-level-#{$i}--is-opened & {
				// 		$__level-offset: -100% * $i;
				// 		@if $mainmenu--fixed-submenu-width {
				// 			$__level-offset: -$mainmenu--width * $i;
				// 		}

				// 		left: $__level-offset;

				// 		.csstransforms3d & {
				// 			left: 0;
				// 			@if $mainmenu--mobile-drodown==false {
				// 				transform: translate3d($__level-offset, 0, 0);
				// 			}

				// 		}
				// 	}

				// }
			}

			&-image {
				display: none;
			}

			&--submenu-wrapper {
				display: none;
				position: relative;
				padding-left: $mainmenu--back-handle--width;

				@if ($mainmenu--mobile-drodown == false) {
					display: block;
					position: absolute;
					left: 100%;
					top: 0;
					// bottom: 0;
					min-height: 100%;
				}
				@else {
					padding-left: 0;

					// transition: height $mainmenu--transition-duration $mainmenu--transition-easing;
					// will-change: height;
				}


				// We need the width here!
				@if $mainmenu--fixed-submenu-width {
					width: $mainmenu--width;
				}
				@else {
					width: 100%;
				}


				li.is-opened > & {
					display: block;
				}

				// &:before,
				// &:after {
				// 	content: "";
				// 	position: absolute;
				// 	top: 0;
				// 	left: 0;
				// 	bottom: 0;

				// 	// @if ($mainmenu--decoration--image) {
				// 	// 	bottom: -$mainmenu--decoration--image--container-height;
				// 	// }
				// }
				// &:before {
				// 	width: $mainmenu--back-handle--width;
				// 	background: $mainmenu--back-stylistic-element--background-color;
				// }
				// // &:after {
				// // 	width: $mainmenu--back-stylistic-element--border-width;
				// // 	background: $mainmenu--back-stylistic-element--border-color;
				// // }
				// //
			}
		}


		//
		// This block is for actual styling of the menu
		//
		@mixin mainmenu--item() {
			$_padding: ceil((($header--menu-bar--height + 14) - $mainmenu--item--line-height) / 2);
			display: inline-block;
			padding: (($_padding)) 12px;

			font-size: $mainmenu--item--font-size;
			line-height: $mainmenu--item--line-height;
			//color: $mainmenu--item--color; Variable doesn't work
			color: $ci-color2-9;
			// hover--color: $mainmenu--item--hover--color;

			font-family: $ci-font;
			font-weight: 200;
			//text-transform: uppercase;
			text-decoration: none;

			cursor: default;

			&:hover {
				text-decoration: none;
				color: $mainmenu--item--hover--color;
			}
		}

		.mainmenu {
			font-size: em($mainmenu--item--font-size);
			line-height: line-height($mainmenu--item--line-height, $mainmenu--item--font-size);


			.level-1 &--submenu-wrapper {
				background-color: $mainmenu--submenu-wrapper--background-color;
			}
			.level-2 &--submenu-wrapper {
				background-color: $mainmenu--submenulevel3-wrapper--background-color;
			}


			&--item {
				@include mainmenu--item();
			}

			ul {
				list-style-type: none;
				padding-left: 0;
				margin: 0;
			}

			li {
				.mainmenu--item {
					@include mainmenu--item();

					$_padding: ceil(($mainmenu--item--height - $mainmenu--item--line-height) / 2);
					$_padding-right: if($mainmenu--item--height < 40px, 40px, $mainmenu--item--height);

					position: relative;
					display: block;
					padding: ($_padding) ($_padding-right) ($_padding) 10px;


					.submenu-handle {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						width: $_padding-right;
						margin: 0 0 0 3px;

						text-align: center;
						line-height: $_padding-right;
						font-size: $mainmenu--submenu-handle--icon-size;
					}
				}
				a.mainmenu--item {
					cursor: pointer;
					.new {
						display: inline-block;
						position: relative;
						width: 32px;
						height: 20px;
						img {
							display: block;
							position: absolute;
							top:-4px;
							left:4px;
							width: 32px;
							height: 32px;	
						}
					}
				}

				&.is-active > .mainmenu--item {
					color: $ci-color1;
				}
				&.is-active:hover > .mainmenu--item {
					color: #fff;
				}
				&.is-opened > a {
					font-weight: 400;
					color: #fff;
				}
			}

			@if ($mainmenu--item--border) {
				li a {
					border-bottom: 1px solid $mainmenu--item--border-color;
				}
			}

			&--back-handle {
				&:before {
					@include iconfont-icon(caret-left);

					.level-2 & {
						content: get-icon(caret-left-double);
					}
				}
			}
			&--close-handle {
				&:before {
					@include iconfont-icon(cross);
				}
			}


			@if ($mainmenu--decoration--image != none) {
 				&--level-wrapper ul:after {
 					content: "";
 					background: url($mainmenu--decoration--image) no-repeat center center;
 					background-size: $mainmenu--decoration--image--width $mainmenu--decoration--image--height;

 					display: block;
 					width: 100%;
 					height: $mainmenu--decoration--image--container-height;

					@if ($mainmenu--decoration--image--hidpi) {

	 					@include hidpi() {
	 						background-image: url($mainmenu--decoration--image--hidpi);
	 					}

					}

 				}

			}
		}


		////////////////////////////////////////////////////////////////////////
		//
		// Dropdown Menu
		//
		////////////////////////////////////////////////////////////////////////
		@include from(desktop) {

			.offcanvas--page/*,
			.mainmenu--menu-wrapper*/ {
				left: 0 !important;
				transition-property: none !important;
				transform: none !important;
			}

			.mainmenu {
				background: none;
				transition-property: none !important;
				transform: none !important;

				position: fixed;
				top: 0; // @TODO: variable
				left: 0 !important;
				right: auto;
				bottom: auto;
				width: 100%;
				max-width: none;
				// z-index: 1200;
				visibility: hidden;

				&--wrapper {
					border-right: 0;
				}

				.container {
					padding: 0 15px;
				}

				&--menu-wrapper {
					float: left;
					padding-top: 0;
					margin: 0 0 0 -16px;
				}

				&-image {
					display: block;
				}

				.scrollable-area {
					position: relative;
					top: 0;
					overflow: visible;
				}

				&--level-wrapper {
					visibility: visible;
				}


				.level-1 {
					border-bottom: none;
					margin: 0;
					text-align: left;
					float: left;

					& > li {
						display: inline-block;
						text-align: left;
					}
				}

				li {
					position: relative;

					.no-js &:hover > .mainmenu--submenu-wrapper,
					&.is-opened > .mainmenu--submenu-wrapper {
						visibility: visible;
						z-index: 1;
					}
				}

				&--submenu-wrapper {
					/* min-height: 300px; */
					position: absolute;
					top: 100%;
					left: auto;
					bottom: auto;
					width: 290px; 
					padding-left: 0;
					padding-top: 1px;
					display: block;
					z-index: -1000;
					visibility: hidden;

					.browser-firefox &,
					.browser-edge &,
					.platform-ios & {
						padding-top: 0;
					}

					// .apply-transitions & {
					// 	transition: visibility $mainmenu--transition-duration $mainmenu--transition-easing;
					// }

					// &.opposite-direction {
					// 	left: auto;
					// 	right: 0;
					// }

					&:before,
					&:after {
						content: none;
					}

					ul {
						border: 1px solid $mainmenu--submenu-wrapper--border-color;
						//background: $mainmenu--submenu-wrapper--background-color;
						//background-color: $mainmenu--background-color;
						background-color: #222;

						//box-shadow: 0 2px 5px rgba(#000, 0.35);
					}
				}
				.level-2 .mainmenu--submenu-wrapper {
					top: 1px;
					left: 100%;
					padding-top: 0;
					background-color: $mainmenu--background-color;
					border: 1px solid $mainmenu--submenu-wrapper--border-color;
					margin-left: -1px;

					.browser-firefox &,
					.browser-edge &,
					.platform-ios & {
						top: 0;
					}

					&.mainmenu--has-image {
						width: 488px;
						.level-3 {
							width: 50%;
						}
					}

					.level-3 {
						border: 0;
					}

					.mainmenu-image {
						width: 50%;
						float: right;
					}

					@if ($mainmenu--item--border) {
						top: -1px;
					}

					// &.opposite-direction {
					// 	left: auto;
					// 	right: 100%;
					// }
				}
			}





			.mainmenu {

				&--item {
					&.logout--link {
						margin-right: 0;
					}
				}

				.level-1 > li > .mainmenu--item {
					padding: 27px 16px;
				}

				.level-3 > li > .mainmenu--item {
					background-color: #000;
				}

				li {
					position: static;

					&.is-opened > a {
						font-weight: 200;
					}
				}

				.submenu-handle {
					display: none;
				}


				@if ($mainmenu--item--border) {
					.level-1 > li > .mainmenu--item,
					li:last-child > .mainmenu--item {
						border-bottom: none;
					}
				}

				.level-2 {
					li {
						.mainmenu--item {
							$_padding: ceil(($mainmenu--item--height--desktop - $mainmenu--item--line-height) / 2);
							// $_padding-right: if($mainmenu--item--height--desktop < 40px, 40px, $mainmenu--item--height--desktop);
							$_padding-right: 20px;
							padding: ($_padding) ($_padding-right) ($_padding) 20px;

							.submenu-handle {
								line-height: $mainmenu--item--height--desktop;
							}
						}
					}
				}

				@if ($mainmenu--decoration--image != none) {
					&--level-wrapper ul:after {
						content: none;
					}
				}

			}



		}

	}
}
