@import "../../config";

$fancy-hover--content--z-index: 0 !default;

$fancy-hover--extra-content--background: $ci-color2-3 !default;
$fancy-hover--extra-content--background-transparent: rgba($fancy-hover--extra-content--background, 0.8) !default;
$fancy-hover--extra-content--z-index: $fancy-hover--content--z-index + 10 !default;

$fancy-hover--static--z-index: $fancy-hover--extra-content--z-index + 10 !default;

$fancy-hover--transition-duration: 0.4s !default;
$fancy-hover--transition-easing: ease-out !default;

@include exports("modules/content/FancyHover") {
	@include critical() {

		.fancy-hover {
			position: relative;

			// Positioning of the elements
			&--content {
				position: relative;
				z-index: $fancy-hover--content--z-index;
			}
			&--extra-content {
				background: $fancy-hover--extra-content--background;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				z-index: $fancy-hover--extra-content--z-index;

				&.fancy-hover--transparent-background {
					background: $fancy-hover--extra-content--background-transparent;
				}
			}
			&--static {
				transform: translateZ(0);
				position: relative;
				z-index: $fancy-hover--static--z-index;
			}


			// Stylings when the extra content shouldn't be visible
			&--extra-content {
				opacity: 0;
				overflow: hidden;
				transition: opacity $fancy-hover--transition-duration $fancy-hover--transition-easing;
			}
			&--extra-content--effect-1 {
				transform: translateX(-100%);
				transition: transform $fancy-hover--transition-duration ;
			}
			&--extra-content--effect-2 {
				transform: translateX(100%);
				transition: transform $fancy-hover--transition-duration ;
			}

			// Stylings when the extra content should be visible.
			// Per default the effects should only be used from a certain breakpoint.
			// To enable the effect on smartphone, too, you have to specify an additional class.
			.no-js &--enable-on-smartphone:hover &--extra-content,
			.js &--enable-on-smartphone.is-active &--extra-content {
				opacity: 1;
			}
			.no-js &--enable-on-smartphone:hover &--extra-content--effect-1,
			.no-js &--enable-on-smartphone:hover &--extra-content--effect-2,
			.js &--enable-on-smartphone.is-active &--extra-content--effect-1,
			.js &--enable-on-smartphone.is-active &--extra-content--effect-2 {
				transform: translateX(0);
			}
			@include from(tablet) {
				.no-js &:hover &--extra-content,
				.js &.is-active &--extra-content {
					opacity: 1;
				}
				.no-js &:hover &--extra-content--effect-1,
				.no-js &:hover &--extra-content--effect-2,
				.js &.is-active &--extra-content--effect-1,
				.js &.is-active &--extra-content--effect-2 {
					transform: translateX(0);
				}
			}

		}

	}
}
