@include exports("screen/layout") {

	html {
		display: block;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100%;

		background-color: $page--background-color;
	}

	body {
		display: block;
		min-width: 320px;
		min-height: 100%;
		position: relative;
		background-color: $page--background-color;

		// For iOS: force overflow-x: hidden
		overflow-x: hidden;

		// Max width for people who runs 4K at native resolution…
		max-width: 2560px;
		margin: 0 auto;
	}

	.disable-hover,
	.disable-hover * {
		pointer-events: none !important;
	}

	@import "layout/page";
	@import "layout/header";
	@import "layout/main";
	@import "layout/footer";

}
