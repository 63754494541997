@import "../../config";

@include exports("layout/header") {}
@import "../../screen/layout/header";

$mainmenu-handle--width: $header--menu-bar--height !default;
$mainmenu-handle--height: $header--menu-bar--height !default;
$mainmenu-handle--color: $header--color !default;
$mainmenu-handle--font-size: 24px !default;
$mainmenu-handle--icon-size-size: 24px !default;
$mainmenu-handle--hover--color: $header--color !default;

@include exports("modules/header/MainmenuHandle") {
	@include critical() {

		.mainmenu-handle {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: $mainmenu-handle--width;
			height: $mainmenu-handle--height;

			font-size: em($mainmenu-handle--font-size);
			color: $mainmenu-handle--color;
			line-height: $mainmenu-handle--height;
			text-align: center;
			text-decoration: none;

			&:hover,&:focus {
				color: $mainmenu-handle--hover--color;
			}

			@include from(desktop) {
				display: none;
			}

			i {
				font-size: em($mainmenu-handle--icon-size-size, $mainmenu-handle--font-size);
			}

		}

	}
}
