@import "../config";

@include critical {
  .inside-be-quiet {
    header {
      margin-bottom: 40px;
    }
    header img {
      display: block;
      margin-bottom: 40px;
    }
    header .introtext {
      font-size: 20px;
      font-weight: 400;
    }    
    div.block {
      margin-bottom: 40px;
    }
    div.block h2 {
      font-weight: 400;
      margin: 0 0 20px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    .introtext {
      background: #1a1a1a;
      padding: 20px;
    }
    footer .img-responsive {
      display: block;
    }
    &-highlight-texture {
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;        
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;
        background-image: url("../img/textures/texture-highlight-smartphone.jpg");
        background-repeat: repeat-y;
        background-position: top center;
        @include from(tablet)
        {
          background-image: url("../img/textures/texture-highlight-tablet.jpg");
        }
        @include from(desktop)
        {
          background-image: url("../img/textures/texture-highlight-desktop.jpg");
        }	
      }
    }
    .quote, blockquote {
      display: block;
      font-size: 22px;
      background: $bequiet-color-grey-4;
      color: $bequiet-color-grey-9;      
      text-align: center;
      padding: 1rem;
      margin-bottom: 1rem;
      &:before {
        content: '';
      }
      &:after {
        content: '';
      }
    }
  }

  .share-post {
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0 -10px;
      li {
        display: block;
        padding: 0 10px;
        margin: 0;
      }
    }
    &-link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: #1A1A1A;
      color: #ccc;
    }
    &-icon {
      display: block;
      padding: 10px;
      background-color: #202020;
      img {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
    &-text {
      display: none;
      width: 100px;
      font-size: 16px;
      font-weight: 400;
      padding-left: 10px;
    }
    @media (min-width: 992px) {
      &-text {
        display: block;
      }
    }
  }

}

.inside-buttomnavi {
  text-align: center;
  background-color: #1a1a1a;

  ul{
    text-align: justify;
    padding: 25px 5% 0px 5%;
  }
  li{
    display: inline-block;
    list-style: none;
  }
  li.line{
    padding-left:100%;
    height:0px;
  }
  &-overview
  {
    margin-left:-25px;
  }
}
