@import "../../config";

$support-wizard-form--product-list-item--border-color: $ci-color2-3 !default;

@include exports("modules/supportWizard/SupportWizardFormNew")
{
	@include critical()
    {
		#support-wizard-form--product-list--container
        {
			display: none;
		}
	}

	@include non-critical()
    {
		// .form-upload-files {
		// 	display: none;
		// }

		.custom-control-input:disabled ~ .custom-control-indicator {
			background-color: #222;
		}		

		.form-section.current {
			display: block;
		}

		.form-section {
			display: none;
		}
	}
}
