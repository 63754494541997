@import "compass/utilities/general/clearfix";


$breakpoint--hidpi: min-resolution 1.5dppx !default;

@mixin hidpi() {

	@include breakpoint($breakpoint--hidpi) {
		@content;
	}

}


@mixin apply-transition($property: left, $duration: $default--animation-duration, $easing: $default--animation-easing) {
	.apply-transitions & {
		transition: $property $duration $easing;

		.csstransforms3d & {
			transition-property: transform;
		}

	}
}


@mixin arrow($direction, $color, $width, $height: $width, $position: false) {
	display: block;
	height: 0;
	width: 0;
	border-style: solid;

	@if $position == true {
		content: "";
		position: absolute;

		@if $direction == "top" {
			bottom: 100%;
			left: 50%;
			margin-left: ($width / -2);
		} @else if $direction == "bottom" {
			top: 100%;
			left: 50%;
			margin-left: ($width / -2);
		} @else if $direction == "left" {
			right: 100%;
			top: 50%;
			margin-top: ($height / -2);
		} @else if $direction == "right" {
			left: 100%;
			top: 50%;
			margin-top: ($height / -2);
		}
	}

	@if $direction == "top" {
		border-width: 0 ($width / 2) $height ($width / 2);
		border-color: transparent transparent $color transparent;

	} @else if $direction == "right" {
		border-width: ($height / 2) 0 ($height / 2) $width;
		border-color: transparent transparent transparent $color;

	} @else if $direction == "bottom" {
		border-width: $height ($width / 2) 0 ($width / 2);
		border-color: $color transparent transparent transparent;

	} @else if $direction == "left" {
		border-width: ($height / 2) $width ($height / 2) 0;
		border-color: transparent $color transparent transparent;

	} @else if $direction == "top-left" {
		border-width: $height $width 0 0;
		border-color: $color transparent transparent transparent;

	} @else if $direction == "top-right" {
		border-width: 0 $width $height 0;
		border-color: transparent $color transparent transparent;

	} @else if $direction == "bottom-left" {
		border-width: $height 0 0 $width;
		border-color: transparent transparent transparent $color;

	} @else if $direction == "bottom-right" {
		border-width: 0 0 $height $width;
		border-color: transparent transparent $color transparent;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Don't touch this unless you know what you are destroying ;)
////////////////////////////////////////////////////////////////////////////////



@mixin convert-properties-to-relative-values($styles, $context: $base-font-size) {

	$font-size: map-get($styles, "font-size");

	@each $property, $value in $styles {

		@if (index($slicer-breakpoint-names, $property) != null) {

			$breakpoint-name: $property;
			$properties: $value;

			@include from($breakpoint-name) {
				@include convert-properties-to-relative-values($properties, $context);
			}
		}
		@else {

			@if ($property == "line-height") {
				$value: line-height($value, $font-size);
			}
			@else if ($property == "font-size") {
				$value: em($value, $context);
			}
			@else if (type-of($value) == "number") and (unit($value) == "px") and ($font-size != null) {
				$value: em($value, $font-size);

			}

			#{$property}: $value;
		}
	}
}


// IMPORT ONCE
// We use this to prevent styles from being loaded multiple times for components that rely on other components.
$modules: () !default;
@mixin exports($name) {

	// Import from global scope
	$modules: $modules !global;

	// Check if a module is already on the list
	$module_index: index($modules, $name);
	@if (($module_index == null) or ($module_index == false)) {
		$modules: append($modules, $name) !global;
		@content;
	}

}



@import "critical-mode";
$critical-mode : false !default;

@mixin non-critical($critical: $critical-mode) {
	@if ($critical == false) {
		@content;
	}
}

@mixin critical($critical: $critical-mode) {
	@if ($critical == true) {
		@content;
	}
}

