@import "../../config";

@include exports("modules/helper/text-transformer") {
	@include critical() {
		.capitalize {
			text-transform: capitalize;
			}
    .uppercase {
  			text-transform: uppercase;
  			}
    .lowercase {
    			text-transform: lowercase;
    			}
		}
}
