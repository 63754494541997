
$headline--border-color: $default--border-color !default;
$headline--border--padding: 0.15em !default;
$headline-styles: () !default;
$h1: () !default;
$h2: () !default;
$h3: () !default;
$h4: () !default;
$h5: () !default;
$h6: () !default;

%headline-styles {
	@include convert-properties-to-relative-values($headline-styles);

	// &.has-border {
	// 	border-bottom: 1px solid $headline--border-color;

	// 	span {
	// 		display: inline-block;
	// 		padding-bottom: $headline--border--padding;
	// 	}
	// }
}
h1 {
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h1);
	// custom stylings below this line!
}
h2{
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h2);
	// custom stylings below this line!
}
h3 {
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h3);
	// custom stylings below this line!
}
h4 {
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h4);
	// custom stylings below this line!
}
h5 {
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h5);
	// custom stylings below this line!
}
h6 {
	@extend %headline-styles !optional;
	@include convert-properties-to-relative-values($h6);
	// custom stylings below this line!
}


// Generate classes heading classes
@for $i from 1 through 6 {
	.h#{$i} {
		@extend h#{$i};
	}
}


// Special headlines
h1, .h1 {
	& + .subtitle, & + .subtitle {
		margin-top: -10px;
		@include from(tablet) {
			margin-top: -25px;
		}
	}

	&.light {
		font-weight: 200;
	}

	.heavy {
		font-weight: 800;
	}
}

h2.heavy {
	font-weight: 800;
	& + .subtitle {
		margin-top: -10px;
		@include from(tablet) {
			margin-top: -20px;
		}
	}
}

h3, .h3 {
	&.has-border {
		border-bottom: 1px solid $headline--border-color;
		padding-bottom: 15px;
	}
}

h4, .h4 {
	&.has-border {
		border-bottom: 1px solid $headline--border-color;
		padding-bottom: (20px - 5px); // - 5px for visual margin of 20px
		margin-bottom: 20px;

	}
}
