@import "../config";

$multiselect-font--size: $base--font-size !default;
$multiselect-border--color: $default--border-color !default;
$multiselect-border--color-active: #fff !default;
$multiselect-item--background-hover: $ci-color2-3 !default;

@include exports("modules/SafariMultilineSelect") {

	@include critical() {

		.multilineselect {
			border-radius: $default--border-radius;
			width: 100%;
			padding: 0;
			border-color: $multiselect-border--color;

			&:hover,&:focus {
				border-color: $multiselect-border--color-active;
			}

			li {
				padding: 10px;
				font-size: ($multiselect-font--size - 2px);

				&.selected {
					background-color: $multiselect-item--background-hover;
				}
			}
		}
	}
}