@import "../../config";

$series-font--size: 18px !default;
$series-button--width: 200px !default;

@include exports("modules/series/Series") {

	@include critical() {

		.coming-soon-button {
			display: block;
			z-index: 1000;
			position: absolute;
			right: 0;
			top: 0;
			a {
				display: block;
			}
			img {
				display: block;
				width: 160px;
				height: auto;
			}
		}

		.series {

			&-container {
				font-size: $series-font--size;
				margin-bottom: 30px;
				min-height: 450px;

				span.h4 {
					margin-bottom: 15px;
				}

				.series-variant
				{
					a{
						text-decoration: none;
						margin-right:5px;
					}
					a:after {
						content: "|";
						padding-left: 10px;
						color: #ccc;
					}
					a:last-child:after{
						content:"";
					}
				}
			}

			&--content {
				h2, h3{
					font-size: 1em;
					letter-spacing: 0.1em;
				}
				h3
				{
					// text-transform: uppercase;
				}

				@include from(desktop) {
					min-height: 330px;
				}

			}

			&-list {
				padding-left: 18px;
				list-style-type: square;
			}

			&-variant {
				margin-bottom: 30px;
				@include from(desktop) {
					margin-bottom: 60px;
				}
			}

			&-link {
				@include from(tablet) {
					width: $series-button--width;
				}
			}

			&--image {
				@include from(desktop) {
					position: relative;

					.template-product & {
						right: -300px;
						left: 10px;
					}

					.template-series & {
						right: -150px;
						left: 0;
					}

				}
			}

			&-container.new &--image {
				.template-product & {
					right: auto;
					left: auto;
				}
				.template-series & {
					right: auto;
					left: auto;
				}				
			}

			&-container.new &--image.m-left {
				position: relative;
				overflow: hidden;
				@include from(desktop) {
					img {
						display: inline-block;
						-webkit-box-sizing: border-box;
						box-sizing: border-box;
						position: relative;
						left: -7.5%;
					}
				}
			}

			&-container.new {
				.series--content {
					
				}

				.product-price {
					font-weight: 600;
					margin-bottom: 30px;
				}

				.product-successor-avaible {
					color: #fff;
				}

				.link-icon--wrapper {
					margin: auto;
				}

				.first-video {
					position: relative;
					width: 100%;
					padding-bottom: 56.25%;
	
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 0;
					}
				}

				.link-icon--item {
					display: inline-block !important;
					@include from(smartphone) {
						padding: 10px 0;
					}
					@include from(largedesktop) {
						padding: 15px 0;
					}
				}

				.link-icon--wrapper .product-collector--handler--show {
					display: block;
				}

				.product-price span {
					color: #ffffff;
					display: block;
				}

				.product-price span:first-child {
					font-size: 1.5em;
				}

				.product-price span:last-child {
					font-size: 1em;
				}				
				.product-ranking {
					position: relative;
					border-top: 2px solid #222;
					border-bottom: 2px solid #222;
					overflow: hidden;
					margin: 0 -50vw;
					&-wrapper {
						position: relative;
						margin: 2vw 50vw;
					}
					.row {
						min-height: 160px;
						z-index: 100 !important;
						p:last-child {
							margin-bottom: 0;
						}
					}
					img {
						display: block;
						width: auto;
						height: 100%;
						max-height: 120px;
						margin: 0 auto;
					}
					.product-ranking-bg {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background-repeat: repeat-y;
						background-position: center center;
						background-size: 2560px auto;
						z-index: -1;
					}
					&-content {
						font-size: 18px;
					}
					@media (max-width: 768px) {
						// margin: 0 -10px;
						.container {
							padding: 0;
						}
						// &-wrapper {
						// 	margin: 0;
						// }
						.row {
							min-height: unset;
						}						
						.product-ranking-bg {
							background-size: 1600px auto;
						}
						&-content {
							font-size: 18px;
						}						
					}
				}
			}
		}

		.series--image-container {
			margin-bottom: -20px;

			@include from(tablet) {
				margin-bottom: -40px;
			}
			@include from(smalldesktop) {
				margin-bottom: -60px;
			}
			@include from(largedesktop) {
				margin-bottom: 0;
			}

			img {
				width: 300px;
				@include from(tablet) {
						width:400px;
				}
				@include from(smalldesktop) {
						width:450px;
				}
				@include from(largedesktop) {
						width:520px;
				}
			}
		}

		.series--article-number	{
			font-size: 14px;

			color:#7f7f7f;
			@include from(smartphone) {
				margin-top: 5px;
			}
			@include from(tablet) {
				margin-top: 0px;
			}
			@include from(smalldesktop) {
				float:right;
				margin-top:-36px;
				margin-right: -10px;
				margin-left: -10px;
				padding-right:10px;
				display: flex;
			}
		}

		.video-cookie-on {
			display: block;
		}
		
		.video-cookie-off {
			display: none;
		}

		/* embed video */
		.embed-video {
			position: relative;
			width: 100%;
			padding-bottom: 56.25%;
		}
		.embed-video {
			iframe,
			video {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
		.embed-play-btn {
			display: block;
			position: absolute;
			z-index: 2;
			border: none;
			background-color: transparent;
			padding: 0;
			color: inherit;
			text-align: inherit;
			top: 50%;
			left: 50%;
			width: 96px;
			transform: translate(-50%,-50%);
			font-size: 100%;
			font-family: inherit;
			cursor: pointer;
			line-height: inherit;
			&.small {
				top: 60%;
				width: 72px;
			}
		}
		.embed-play-btn:active {
			outline: none;
			border: none;
		}
		.embed-preview {
			position: absolute;
			cursor: pointer;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
			z-index: 1;
		}
		.embed-preview img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}
