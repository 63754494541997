@import "../config";

	@include critical{

        .cookie-banner {
            &>.main--wrapper{
                background: #545454;
            }
            &-text{
                font-size:11px;
                color: #ccc;
            }
            position: fixed;
            bottom: 0;
            width: 100vw;
            z-index: 149;
            background:#000;
            display:none;
            #cookie-accept-technical,
            #cookie-policy-link,
            .cookie-accept {
                width: 100%;
                max-width: 460px;
                text-align: center;
            }
            .cookie-accept {
                padding: 1.2rem 1rem;
            }
        }

        @media (min-width: 768px){
            .cookie-banner{
                &-text{
                    font-size:18px;
                }
            }
        }

        .cookie-footer{
            text-align: right;
            background: #000;
            font-size:15px;
        }

        .float-right{
            float: right;
        }

        .cookie-accept{
            padding: 0.8rem 6rem;
            &.customer{
                padding: 8px;
            }
        }

        
    }

    @include non-critical{

       .cookieinput{
           margin-right: 5px;
           margin-top: -5px;
           vertical-align: middle;
       }

       .onlycookie{
           float:right;
           vertical-align: middle;
       }

       .modal-content {
            position: relative;
            background-color: #222;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: .3rem;
            outline: 0;
            color: #ccc;
        }

        .modal, .modal-title {
            color: #ccc;
        }

        .modal-footer {
            padding: 15px;
            text-align: right;
            border-top: 1px solid #4c4c4c;
            &>a{
                color: #7f7f7f;
                font-size:15px;
                &:hover{
                    color:#fff;
                }
            }
        }

        .modal-header .close {
            color: #7f7f7f;
        }

        .modal-body a {
            color: #7f7f7f;
            text-decoration:none;
            &:hover{
                color:#ee7f00;
            }
        }

       .cookielabel{
           cursor: default!important;
           &>.custom-control-indicator{
            border-color: #7f7f7f!important;
            background-color: #7f7f7f!important;
           }
           &>.custom-control-description{
               color:#767676;
           }
           &:hover{
            color: #fff;
            }
        }

          @media (min-width: 768px) {

           
          }

          @media (min-width: 992px) {
           
          }

          @media (min-width: 1200px) {
            
          }
          
        
    }