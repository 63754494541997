@import "../../config";

$accordion-background--color: transparent !default;
$accordion-border--color: $ci-border-color-3 !default;
$accordion-header--size: 20px !default;

@include exports("modules/content/Accordion") {

	@include critical() {

		#accordion {

			.card {
				background-color: $accordion-background--color;
				border: 0;
			}

			.card-header {
				margin-bottom: 20px;
				padding-left: 2px;
				padding-right: 2px;

				background-color: $accordion-background--color;
				border-bottom: 1px solid $accordion-border--color;

				.mb-0 {
					font-size: $accordion-header--size;
					font-weight: bold;
					margin-top: 0;

					a {
						text-decoration: none;
						&.collapsed {
							&:after {
								@include iconfont-icon(plus);
								float: right;
							}
						}
						&:after {
							@include iconfont-icon(minus);
							float: right;
						}
					}
				}
			}

			.card-block {
				// padding: 1.25rem 1.25rem 0;

				// @include from(desktop) {
				// 	padding: 4.25rem 1.25rem 1.25rem;
				// }
				// Change in fix value, top & bottom is to be set with pt or pb
				padding: 0 10px;
			}
		}
	}
}