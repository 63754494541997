@import "../config";

	@include critical{

        .form-area{
          width:100%;
        }

        .bottom, .top {
          position:absolute;
          left:0;
        }

        .bubble{
          max-height: 180px;
          width: 110px;
          float: right;
          &:hover{
            cursor: pointer;
          }
        }

        .icons{
          &>div{
            &>img{
              width:111px;
              max-width:100%;
            }
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
          &.onlytablet{
            max-height: 170px;
            position: relative;
            float: right;
            max-width: 100%;
            &>img{
              float: right;
              max-width: 160px;
            }
          }
        }

        .join{
          float: right;
          z-index: 3000;
          text-align: right;
          margin-top: -90px;
        }

        .h3fix>h3{
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom:10px;
        }

        .nomobile, .card-button>.front .go-button, .go, .videodiv, .lgtext{
          display:none;
        }

        .xmas-visual{
          display: none;
          position: absolute;
        }
    }

    @include non-critical{

      .radiobutton{
        margin-right: 5px;
      }

      .radio{
        &:hover{
          cursor: pointer;
        }
        &>label:hover{
          cursor: pointer;
        }
      }

      .txt{
        display:none;
      }

      #videoplay{
        max-width:100%;
      }

      // flip
      .card-container {
        width: 100%;
        min-height: 167px;
        position: relative;
        -webkit-perspective: 800px;
        -moz-perspective: 800px;
        -o-perspective: 800px;
        perspective: 800px;
        margin-bottom: 20px;
    }

    img.img-responsive{
      vertical-align: top;
    }
    .card-button, .go-button-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-transition: -webkit-transform 1s;
        -moz-transition: -moz-transform 1s;
        -o-transition: -o-transform 1s;
        transition: transform 1s;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    
    .go-button{
      display: none;
    }

    .card-button div {
        display: block;
        height: 100%;
        width: 100%;
       /*  line-height: 260px; */
        color: white;
        text-align: center;
        position: absolute;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .go-button-wrapper div{
      display:none;
      height: 100%;
        width: 100%;
       /*  line-height: 260px; */
        color: white;
        text-align: center;
        position: absolute;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    td{
      vertical-align: top;
    }

    .card-button .front, .go-button-wrapper .front {
      padding: 8px;
      border: 1px solid #666;
          border-radius: 7px;
          &:hover{
            border: 1px solid rgb(158, 158, 158);
            cursor: pointer;
            -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
          }
    }
    .card-button .back {
        background-color: black;
        -webkit-transform: rotateY( 180deg );
        -moz-transform: rotateY( 180deg );
        -o-transform: rotateY( 180deg );
        transform: rotateY( 180deg );
        border: 1px solid rgb(158, 158, 158);
        border-radius: 7px;
        font-size: 14px;
        line-height: 23px;
        padding:6px;
          &:hover{
            cursor: pointer;
          }
        display: table;
        width: 100%;
    }
    .card-button.flipped {
        -webkit-transform: rotateY( 180deg );
        -moz-transform: rotateY( 180deg );
        -o-transform: rotateY( 180deg );
        transform: rotateY( 180deg );
    }
    
      // end flip

        .img-button{
          border: 1px solid #666;
          border-radius: 25px;
          &:hover{
            border: 1px solid rgb(158, 158, 158);
            cursor: pointer;
            -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
          }
          &.clicked{
            border: 1px solid rgb(158, 158, 158);
            cursor: pointer;
            -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
          }
        }

        /* .img-buttons{
          padding: 0px;
        } */

        .go-button{
          border-radius: 25px;
          &:hover{
            border: 1px solid rgb(158, 158, 158);
            cursor: pointer;
          }
        }

        .go{
          margin-top: -125px;
          font-size: 16px;
          font-weight: 900;
          color: #fff;
          &:hover{
            cursor: pointer;
          }
        }

        .text-center{
          text-align: center;
        }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
          &>p{
            color: #666;
            font-size: 12px;
            &>strong{
              color: #fff;
              font-size: 18px;
            }
          }
        }

        h3{
          
          &.colorgray{
            color: #666;
            transition: 1s ease;
          }
        }

        .visual-wrapper{
          min-height:500px;
        }

        .mdtext{
          display: table-cell;
          vertical-align: middle;
        }

          @media (min-width: 768px) {

            .join{
              margin-bottom: -160px;
              padding-right: 0px;
              margin-top: -100px;
            }

            .img-button, .card-button .front, .go-button-wrapper .front{
              border: 1px solid #666;
              border-radius: 20px;
            }

            .card-button .back{
              border-radius: 20px;
              -webkit-filter: brightness(1.5);
              filter: brightness(1.5);
            }

            .go-button{
              border-radius: 20px;
              width: 100%;
              background-color: #ee7f00;
            }

            .img-buttons{
              padding: 0px 10px;
            }

            .visual-wrapper {
              min-height: 623px;
            }
          }

          @media (min-width: 992px) {
            
            .bubble{
              width: 180px;
            }

            .card-container{
              min-height: 120px;
            }
            
            .nomobile{
              display: block;
            }

            .tabletup{
              display:none;
            }
            
          }

          @media (min-width: 1200px) {

            .card-container{
              min-height: 167px;
            }

            .lgtext{
              display:block;
              display: table-cell;
              vertical-align: middle;
              hyphens: auto;
            }

            .mdtext{
              display:none;
            }

            .visual-wrapper {
              min-height: 723px;
            }
            
          }


    }
