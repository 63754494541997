

@include exports("modules/header/Mainmenu") {}
@import "../../modules/header/Mainmenu";


$footer--background-color: #e5e5e5 !default;
$footer--border-color: $ci-color1 !default;


$footer-link--color: $ci-color2-7 !default;
$footer-font--size: em(15px) !default;

$footer--icon--color: $ci-color2-9 !default;
$footer--color: $ci-color2-7 !default;

/* @TODO Customize font-sizes after implement correct font */

@include exports("layout/footer") {

	.footer {
		position: relative;

		background-color: $footer--background-color;
		margin: 0;
		padding: 30px 0;
		border-top: 1px solid $footer--border-color;
		z-index: 0;
		color: $footer--color;

		@include from(smalldesktop) {
			padding: 60px 0;
		}

		a {
			color: $footer-link--color;
			&:hover{
				color:#fff;
			}
			&.career{
				color:#7f7f7f;
				&:hover{
					color:#fff;
				}
			}
		}

		&-claim {
			text-align: center;
			margin: 0 0 5px;
			margin-top: -24px;
			color: #fff;

			@include from(smalldesktop) {
				text-align: left;
			}

			.small {
				font-size: em(11px);
			}
			img {
				max-width: 200px;
				margin-bottom: 6px;

				@include from(smalldesktop) {
					margin-left: 35px;
				}
			}
		}

		&-header {
			font-weight: 800;
			margin-bottom: 18px;
		}

		&-developed-img {
			display: block;
			width: 200px;
			height: auto;
			margin: 0 auto;
		}

		// Adblock fix
		div.footer-social {
			display: block !important;
		}

		&-social {
			text-align: center;
			margin-bottom: 15px;
			font-size: em(20px);

			&--wrapper {
				text-align:center;
				@include from(smalldesktop) {
					// width: 60%;
					// margin: 0 auto;
					// line-height: 1.2;
					span {
						display: block;
					}
				}
				@include from(largedesktop) {
					span {
						display: inline;
					}
				}
			}
			&--links {
				margin: 10px -5px 0;
				a {
					display: inline-block;
					vertical-align: middle;
					margin: 0 5px;
					color: $footer--icon--color;
					img {
						display: inline;
						vertical-align: middle;
						width: auto;
						height: 20px;
					}
				}
				i {
					font-size: em(24px);
				}
			}
			&--geolang{
				font-size: 0.8em;
				margin-top: 10px;
				@include from(smalldesktop) {
					margin-top: 4px;
				}
				@include from(desktop) {
					margin-top: 10px;
				}
				p {
					font-size: 0.8em;
				}
			}
		}

		&-info {
			text-align: center;

			@include from(smalldesktop) {
				text-align: right;
			}
		}

		&-contact {
			margin: 0 0 17px;
			font-size: em(18px);

			@include from(smalldesktop) {
				line-height: 1.2;
			}
		}

		&-menu {
			font-size: $footer-font--size;
			ul {
				margin: 0 0 9px;
				padding: 0;
			}
		}

		&-copyright {
			font-size: $footer-font--size;
			margin: 0 0 17px;

			ul {
				padding: 0;

				&.horizontal-list > li:before {
					@include from(smalldesktop) {
						content: none;
					}
					@include from(desktop) {
						content: " | ";
					}
				}

				&.horizontal-list {
					margin-bottom:9px;
				}

			}
		}

	}

}
