@import "../config";

@include exports("modules/MainboardCheckNew") {

	@include critical() {
		.template-mainboard-check {
			.select2-container {
				min-width: initial;
			}
		}
	}

	@include non-critical {
        .custom-select2 option:hover,
        .custom-select2 option:checked,
        .custom-select2 option[selected],
        .custom-select2:focus option:hover,
        .custom-select2:focus option:checked,
        .custom-select2:focus option[selected] {
            background: #222222 !important;
            color: #cccccc !important;
        }
		.color-white {
			color: #fff;
		}
		.select2-results__option {
			&.archived {
				color: #555 !important;
			}
		}
		.motherboard-check {
			z-index: 1;
			h3 {
				display: flex;
				align-items: center;
				img {
					display: block;
					width: 80px;
					height: auto;
					margin-right: 15px;
				}
				span {
					display: block;
				}
			}
			&-configuration {	
				background: linear-gradient(to left,#000 30%,#222 70%);
			}
			&-btn {
				width: 100%;
				&.disabled {
					background-color: #222222;
					color: #cccccc;
				}
				box-shadow: none !important;
				outline: none !important;
			}
			&-lga1700 {
				padding-top: 10px;
				color: #ee7f00;
			}
			&-result {
				.configuration-text {
					display: none;
				}
				#motherboard-check-archived {
					display: none;
					border-top: 1px solid #373a3c;
					.archived-products {
						display: none;
					}
				}
				#motherboard-check-archived {
					display: none;
				}
				.motherboard-check-result-products-archived {
					display: none;
				}
				#motherboard-check-btn-archived-hide {
					display: none;
				}

				&-content {
					.product-card {
						display: flex;
						-webkit-flex-direction: column; 
						flex-direction: column;
						height: initial;
						min-height: 450px;
						.product-card-row {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							margin-bottom: 15px;
						}
						&-header {
							background-color: #000;
							a {
								display: block;
								text-decoration: none;
								padding: 0;
								img {
									display: block;
									width: auto;
									height: 100%;
									margin: 0 auto;
								}
							}
						}
						&-content {
							padding: 15px;
							a {
								color: #fff;
								font-size: 1.1111111111em;
								font-family: "Helvetica Neue",sans-serif;
								font-weight: 800;
    							line-height: 1.3;
								text-decoration: none;
							}
						}
						&-footer {
							padding: 0 15px;
							margin-top: auto;
							.product-card-row:first-child {
								strong {
									color: #fff;
								}
							}
							.product-card-row:last-child {
								justify-content: flex-start;
								margin-bottom: 15px;
							}
						}
					}
					.cpu-compatibility {
						span {
							display: none;
						}
						ul {
							display: flex;
							flex-direction: row;						
							margin: 0;
							padding: 0 !important;
							list-style: none;
							li {
								display: block;
								position: relative;
								margin: 0 3px;
								width: 16px;
								height: 16px;
								border: 2px solid #ee7f00;
								background-color: transparent;
								-webkit-border-radius: 8px;
								-moz-border-radius: 8px;
								border-radius: 8px;
								&:before {
									content: '';
									display: block;
									position: absolute;
									background-color: #ee7f00;
									width: 8px;
									height: 8px;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
									-webkit-border-radius: 4px;
									-moz-border-radius: 4px;
									border-radius: 4px;
								}
							}							
						}
						&.compatibility-0 {
							ul {
								li {
									&:before {
										display: none !important;
									}									
								}
							}
							span.compatibility-0-text
							{
								display: block;
							}
						}
						&.compatibility-1 {
							ul {
								li:nth-of-type(2):before, li:nth-of-type(3):before {
									display: none;
								}
							}
							span.compatibility-1-text
							{
								display: block;
							}
						}
						&.compatibility-2 {
							ul {
								li:nth-of-type(3):before {
									display: none;
								}
							}
							span.compatibility-2-text
							{
								display: block;
							}
						}
						&.compatibility-3 {
							span.compatibility-3-text
							{
								display: block;
							}
						}

					}
				}
			}
		}
		.icon-check-exclamation {
			display: inline-block;
			position: absolute;
			right: 20px;
		}
		.more_info .title {
			position: absolute;
			top: 210px;
			background: silver;
			padding: 4px;
			right: 9px;
			white-space: nowrap;
			color: #000;
		}
		@media (min-width: 992px) {
			.more_info .title{
				display: none;
			}
		}
	}	
}
