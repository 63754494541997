@import "../../config";

$product-quality--max-num: 3 !default;

$product-quality--height: 50px !default;

$product-quality--color: #000 !default;
$product-quality--font-family: $ci-font--font-family !default;
$product-quality--font-size: 20px !default;
$product-quality--font-weight: 900 !default;
$product-quality--line-height: $product-quality--height !default;

@include exports("modules/product/ProductCategoryTeaser") {
	@include critical() {

		.product-quality {
			background: no-repeat 0 50%;
			height: $product-quality--height;
			overflow: hidden;

			color: $product-quality--color;
			font-family: $product-quality--font-family;
			font-size: rem($product-quality--font-size);
			line-height: $product-quality--line-height;
			font-weight: $product-quality--font-weight;

			// emboss effect
			// text-shadow: -1px -1px 4px #fff, 1px 1px 4px #000;
			 text-shadow: 0px 1px 1px #ccc;

			@for $i from 1 through $product-quality--max-num {
				$_filenumber: $i;
				@if ($i < 10) {
					$_filenumber: "0" + $i;
				}

				&-#{$i} {
					background-image: url("../img/product-quality/product-quality-#{$_filenumber}-smartphone.jpg");

					@include from(tablet) {
						background-image: url("../img/product-quality/product-quality-#{$_filenumber}-tablet.jpg");
					}
					@include from(smalldesktop) {
						background-image: url("../img/product-quality/product-quality-#{$_filenumber}-smalldesktop.jpg");
					}
					@include from(desktop) {
						background-image: url("../img/product-quality/product-quality-#{$_filenumber}-desktop.jpg");
					}
				}
			}
		}

	}
}
