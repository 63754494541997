@import "../../config";

/**
 * FieldReadonly
 *
 *
 * @author Björn Hase
 *
 */

$field-readonly--color: lighten($text--color, 25%);

@include exports("modules/forms/FieldReadonly") {
	@include critical() {
		.field-readonly {
			display: block;
			color: $field-readonly--color;
		}

		.readonly-group {
			label {
				display: block;
				margin-top: -18px;
			}
		}
	}
}
