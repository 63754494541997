@import "../../config";

$model-compare--title--color: #fff !default;
$model-compare--different--color: $ci-color1 !default;
$model-compare--label--color: $ci-color2-9 !default;
$model-compare--title--font-size: em(20px) !default;

$model-compare--border--color: $ci-color2-8 !default;

@include exports("modules/product/ModelCompare") {

	@include critical() {

		.model-compare {

			margin-top: 30px;

			&--item-title {
				//margin-bottom: 1rem;
				font-weight: 900;
				font-size: $model-compare--title--font-size;
				color: $model-compare--title--color;
				min-height: (2 * 26px); // 2 lines
			}

			&--item-divider {
				margin: 1.667rem 0;
				border-top-color: $model-compare--border--color;
			}


			&--result {

				.is--different {
					color: $model-compare--different--color !important;
				}

				&--label {
					color: $model-compare--label--color;
				}

				&--image {
					margin-bottom: 2.222rem;
				}

				&--accordion {
					margin-top: 1rem;
				}

			}

		}

	}

}
