@import "../../config";

$login--height: 70px !default;
$login--size: 24px !default;
$login--border-color: $ci-border-color !default;
$login--background-color: $ci-color2 !default;
$login--wrapper--height: 170px !default;
$login--form-height: 50px !default;
$login--animation-duration: $default--animation-duration !default;
$login--animation-easing: $default--animation-easing !default;

$login--p--font-size: 16px !default;
$login--title--color: $ci-color1 !default;
$login--title--font-size: $base--font-size + 2px !default;

$login--handle--color: $ci-color2 !default;
$login--handle--active--color:$ci-color2 !default;

@include exports("modules/header/Quicklogin") {

	@include critical() {

		.quicklogin {

			transition: all $login--animation-duration $login--animation-easing;

			&-handle {
				display: none;
				color: $login--handle--color;
				padding-left: 8px;
				text-decoration: none;
				font-size: $login--size;

				@include from(tablet) {
					display: block;
				}

				&.active,&:hover {
					color: $login--handle--active--color;
				}

				&--wrapper {
					position: absolute;
					right: 0;
					height: $login--height - 10px;
					width: $login--height - 10px;
					line-height: $login--height;
					border-bottom: 2px solid $login--border-color;
					text-align: center;
				}
			}

			// Following style can be non critical

			&-text {
				padding: 0 10px;
			}

			&-form {
				margin: 20px 0;

				@include from(smalldesktop) {
					margin: 30px 0;
				}

				input {
					width: 100%;
					height: $login--form-height;
				}

				&--input {
					padding: 0 10px;
					display: inline;
				}

				&--group {
					width: 33.33%;
					float: left;
					padding: 0 5px;
				}

				fieldset {
					border: none;
					padding: 0 10px;
					margin: 0 -5px;
				}
			}

			&-wrapper {
				display: none;
				margin: 0 -10px;
				background: $login--background-color;
				text-align: left;
				height: $login--wrapper--height;
				overflow: hidden;
				visibility: hidden;

				@include from(smalldesktop) {
					height: $login--wrapper--height - 60px;
				}

				&:before {
					content: "";
					position: absolute;
					height: $login--wrapper--height;
					bottom: 0;
					right: 100%;
					left: -1000%;
					left: -100vw;
					background: $login--background-color;

					@include from(smalldesktop) {
						height: $login--wrapper--height - 60px;
					}
				}
				&:after {
					content: "";
					position: absolute;
					height: $login--wrapper--height;
					bottom: 0;
					left: 100%;
					right: -1000%;
					right: -100vw;
					background: $login--background-color;

					@include from(smalldesktop) {
						height: $login--wrapper--height - 60px;
					}
				}

				&--left {
					float: left;
				}

				&--right {
					float: right;
				}

				.quicklogin-is-opened & {
					visibility: visible;
					display: block;
				}

				p {
					margin: 0;
					font-size: $login--p--font-size;

					@include from(desktop) {
						font-size: 18px;
					}
				}
			}

			&-title {
				color: $login--title--color;
				font-weight: bold;
				text-transform: uppercase;
				line-height: 100%;
				margin: 30px 0 10px;
				font-size: em($login--title--font-size);
			}
		}
	}
}
