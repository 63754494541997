// THIS FILE WAS GENERATED AND WILL BE OVERWRITTEN BY grunt-webfont

$iconfont--include-base: false !default;

$icons: (

	actions: "\f101",

	bubble2: "\f102",

	bubbles3: "\f103",

	bullhorn: "\f104",

	calculator: "\f105",

	caret-down: "\f106",

	caret-left: "\f107",

	caret-right: "\f108",

	caret-up: "\f109",

	cart: "\f10a",

	check-exclamation: "\f10b",

	checkmark: "\f10c",

	chevron-thin-down: "\f10d",

	chevron-thin-left: "\f10e",

	chevron-thin-right: "\f10f",

	chevron-thin-up: "\f110",

	clipboard: "\f111",

	cogs: "\f112",

	compare-case: "\f113",

	compare: "\f114",

	cross: "\f115",

	dimensions: "\f116",

	download3: "\f117",

	facebook2: "\f118",

	file-text2: "\f119",

	film: "\f11a",

	globe: "\f11b",

	image: "\f11c",

	instagram: "\f11d",

	location: "\f11e",

	mail2: "\f11f",

	menu: "\f120",

	minus: "\f121",

	newspaper: "\f122",

	notification: "\f123",

	pencil: "\f124",

	phone: "\f125",

	plus: "\f126",

	podcast: "\f127",

	search: "\f128",

	select: "\f129",

	sphere: "\f12a",

	support: "\f12b",

	twitter: "\f12c",

	user-check: "\f12d",

	vk: "\f12e",

	youtube: "\f12f",

);



@mixin iconfont() {
	font-family: "iconfont";
	display: inline-block;
	vertical-align: baseline;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
%iconfont {
	@include iconfont();
}

/// Mixin iconfont.
/// This mixin is useful if you want to use a iconfont in a :before or :after
///
/// @param   $icon          The icon to be displayed
/// @param   $extend: true  whether the mixin should extend the selector or not
///
@mixin iconfont-icon($icon, $extend: true) {
	@if ($extend) {
		@extend %iconfont !optional;
	}
	@else {
		@include iconfont();
	}

	content: map-get($icons, $icon);
}

@function get-icon($icon) {
	@return map-get($icons, $icon);
}

@mixin iconfont-base() {
	[class*=" icon-"]:before,
	[class^="icon-"]:before {
		@include iconfont();
	}
}
@mixin iconfont-classes() {
	@each $__icon, $__content in $icons {
		.icon-#{$__icon}:before {
			content: quote($__content);
		}
	}
}

