/**
 * Basic styles for form elements
 *
 *
 * @author Dennis Özet
 */
$form-label--color: #fff !default;
$form-label--font-size: 18px !default;

$form-input--border-color: $ci-color2-8 !default;
$form-input--background-color: $ci-color2-3 !default;
$form-input--font-size: $base--font-size !default;
$form-input--color: $ci-color2-9 !default;
$form-input--hover--color: $ci-color2-9 !default;
$form-input--placeholder--color: $ci-color2-6 !default;
$form-input--active--background-color: $ci-color1 !default;
$form-input--active--border-color: $ci-color1 !default;
$form-input--active--background-color2: $ci-color2 !default;
$form-input--active--border-color2: #fff !default;


$form-checkbox--width: 25px !default;
$form-checkbox--height: $form-checkbox--width !default;
$form-checkbox--border-color: $ci-color2-8 !default;
$form-checkbox--background-color: $ci-color2-3 !default;

$form-radio--width: 20px !default;
$form-radio--height: $form-radio--width !default;
$form-radio--border-color: $form-checkbox--border-color !default;
$form-radio--background-color: $form-checkbox--background-color !default;

$form-disabled--font-color: $ci-color2-6 !default;
$form-disabled--bg-color: #000 !default;
$form-disabled--opacity: 0.4 !default;

$parsley-error--color: $ci-color1 !default;
$parsley-error--border-color: $ci-color1 !default;

@include exports("base/form") {

	input[type="text"],
	input[type="search"],
	input[type="email"] {
		&::placeholder {
			opacity: 1;
			color: $form-input--placeholder--color;
		}
	}

	.form-group {
		margin-bottom: 0.65rem;

		&.form-group-mt {
			margin-top: 0.65rem;
		}
	}

	// Label
	.form-label {
		color: $form-label--color;
		font-size: em($form-label--font-size);
	}
	label[for] {
		cursor: pointer;
	}

	// Input, Textarea, Select
	.form-control {
		font-size: rem($form-input--font-size);
		border-color: $form-input--border-color;
		background-color: $form-input--background-color;
		color: $form-input--color;


		&:disabled {
			background-color: $form-disabled--bg-color;
			opacity: $form-disabled--opacity;
			color: $form-disabled--font-color;
		}

		&:focus,
		&:hover,
		&:active {
			color: $form-input--hover--color;
			border-color: $form-input--active--border-color2;
			background-color: $form-input--active--background-color2;
		}


	}


	// Radio, Checkbox
	.custom-control {

		&.custom-checkbox {
			padding-left: $form-checkbox--width + 10px;
			margin-bottom: 0;
		}
		&.custom-radio {
			padding-left: 20px + 10px;
			margin-bottom: 0;
		}

		.custom-checkbox &-indicator {
			width: $form-checkbox--width;
			height: $form-checkbox--height;
			border: 1px solid $form-checkbox--border-color;
			background-color: $form-checkbox--background-color;
		}

		.custom-radio &-indicator {
			width: $form-radio--width;
			height: $form-radio--height;
			border: 1px solid $form-radio--border-color;
			background-color: $form-radio--background-color;
		}

		&-input {
			&:checked, &:focus, &:active {
				& ~ .custom-control-indicator {
					box-shadow: none;
					border-color: $form-input--active--border-color;
					background-color: $form-input--active--background-color;
				}
			}
		}

		&-description {
			display: inline-block;
		}

		.custom-checkbox &-description {
			margin-top: rem(7px);
		}
		.custom-radio &-description {
			margin-top: rem(5px);
		}


		// bootstrap override
		&,
		&+& {
			margin-left: 0;
			margin-right: 15px;
		}
	}

	.custom-checkbox .custom-control-input:checked~.custom-control-indicator{
		//background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");	//doesn't work in IE
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%208%208%27%3E%3Cpath%20fill%3D%27%23fff%27%20d%3D%27M6.564.75l-3.59%203.612-1.538-1.55L0%204.26%202.974%207.25%208%202.193z%27/%3E%3C/svg%3E"); //work in IE
	}

	.custom-radio .custom-control-input:checked~.custom-control-indicator {
		//background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23fff'/%3E%3C/svg%3E"); //doesn't work in IE
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%27-4%20-4%208%208%27%3E%3Ccircle%20r%3D%273%27%20fill%3D%27%23fff%27/%3E%3C/svg%3E"); //work in IE
	}



	.form-check-inline.custom-control {
		margin-right: .75rem;
	}

	.custom-controls-stacked {
		.custom-control {

			&.custom-radio {
				padding: 0 0 10px 30px;
			}

			.custom-radio &-indicator {
				top: .30rem;
			}

		}
	}


	// Parsley form validation

	.parsley-errors-list li {
		font-size: 14px;
	}

	.has-danger .parsley-errors-list {
		list-style-type: none;
		padding: 0;
		color: $parsley-error--color;
	}

	.has-danger .form-control {
		border-color: $parsley-error--border-color;
	}

	.has-danger .custom-control {
		color: $parsley-error--color;
	}

}
