@import "../config";

$slider-handle--bg-color: $ci-color1 !default;
$slider-selected--bg-color: $ci-color2-8 !default;
$slider-track--bg-color: $ci-color2-3 !default;

@include exports("modules/RangeSlider") {


	@include critical() {
		@import "../libs/bootstrap/bootstrap-slider";

		.slider {

			&.slider-horizontal {
				width: 100%;
			}

			&-selection {
				background: linear-gradient(to bottom,$slider-selected--bg-color 0,$slider-selected--bg-color 100%);
			}

			&-track {
				background: linear-gradient(to bottom,$slider-track--bg-color 0,$slider-track--bg-color 100%);
			}

			&-handle {
				background: linear-gradient(to bottom,$slider-handle--bg-color 0,$slider-handle--bg-color 100%);
			}
		}

		.range-slider {

			&--container {
				margin-bottom: 10px;
			}

			&--min, &--max {
				font-size: 16px;
			}

			&--item {
				padding: 0;
			}
		}

	}
}