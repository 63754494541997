@import "../config";

	@include critical{

        #features{
            display:flex;
        }

        .form-area{
          width:100%;
        }

        #db700headline{
          opacity: 0;
          transition:.7s ease;
        }

        #cycler{
          position:relative;
          margin-top:35px;
        }

        #cycler img{position:absolute;z-index:1}
        #cycler img.active{z-index:3}

        
    }

    @include non-critical{

        .bubble{
          width:140px;
          opacity:0;
        }

        .join {
          float: right;
          z-index: 3000;
          text-align: right;
          margin-top: 0;
        }

        #db700headline{
          position: absolute;
          margin-top: 0px;
          margin-left: 80px;
        }

        .showfeatures{
            margin: 0px auto;
        }

        .headervideosb{
            &>video{
              max-width:100%;
              margin-top: -110px;
              z-index: 0;
              margin-bottom: 50px;
            }
          }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
        }

        #submit{
          margin-top: .65rem;
          margin-bottom: 20px;
          padding: 8px 40px;
        }

        h2{
          /* text-transform: uppercase; */
          color:#fff;
          font-weight: 700;
        }

        .radio>label{
          &:hover{
            cursor: pointer;
          }
          &>input{
            margin-right: 10px;
          }
          
        }

        .lines{
          opacity:0.6;
        }

        .bubble{
          max-height: 180px;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        strong{
          color:#fff;
        }

        td{
          padding:5px;
        }

          @media (min-width: 768px) {

          }

          @media (min-width: 992px) {
           
          }

          @media (min-width: 1200px) {
           
          }
          
        
    }