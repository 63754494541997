@import "../../config";

$image-hover-zoom--image--scale-factor: 1.15 !default;
$image-hover-zoom--image--transition-duration: 2s !default;

$image-hover-zoom--overlay--background: #000 !default;
$image-hover-zoom--overlay--alpha: 0.6 !default;
$image-hover-zoom--overlay--transition-duration: 0.3s !default;

$image-hover-zoom--content--transition-duration: 0.3s !default;

@include exports("modules/content/ImageHoverZoom") {
	@include critical() {
		.image-hover-zoom {
			position: relative;
			overflow: hidden;
			// border-radius: 0;
			text-align: center;

			.card {
				overflow: hidden;
			}

			&--image {
				// transform: scale3d(1,1,1);
				transform: scale(1);
				transition: transform $image-hover-zoom--image--transition-duration;
				// transform-origin: 50% 50%;

				// border-top-left-radius: 0;
				// border-top-right-radius: 0;
				overflow: hidden;
			}

			&--overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				display: flex;
				justify-content: center;
				align-content: center;
				align-items: center;

				background: rgba($image-hover-zoom--overlay--background, $image-hover-zoom--overlay--alpha);
				opacity: 0;

				transition: opacity $image-hover-zoom--overlay--transition-duration;
			}

			&--content {
				flex: 0 1 auto;
				transform: translateY(100%);
				opacity: 0;
				transition: all $image-hover-zoom--content--transition-duration;
			}

			@include from(largedesktop) {

				&--trigger:hover &--image,
				&--trigger:focus &--image {
					// transform: scale3d($image-hover-zoom--image--scale-factor, $image-hover-zoom--image--scale-factor, 1);
					transform: scale($image-hover-zoom--image--scale-factor);
				}
				&--trigger:hover &--overlay,
				&--trigger:focus &--overlay {
					opacity: 1;
				}
				&--trigger:hover &--content,
				&--trigger:focus &--content {
					transform: translateY(0);
					opacity: 1;
				}
				//Safari rendering bug
				.browser-safari & {
					margin-left: 1px !important;
				}

			}

		}
	}
}
