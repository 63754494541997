@import "../../config";

$video-gallery--video--aspect-ratio: 16 / 9 !default
$video-gallery--video--border-color: $ci-color2-3 !default;

$video-gallery--list-item--border-color: $ci-color2-3 !default;
$video-gallery--list-item--max-width: 190px !default;
$video-gallery--list-item--margin: 20px !default;
$video-gallery--list-item--active--border-color: $ci-color2-8 !default;

$video-gallery--list-item--title--font-size: 11px !default;

$video-gallery--list-item--thumbnail--scale-factor: rgba(#000, 0.3) !default;
$video-gallery--list-item--transition-duration: 0.5s !default;


@include exports("modules/product/VideoGallery") {
	@include critical() {

		#youtubevideos--list{
			width:100%;
		}

		.video-gallery {

			&--video-wrapper,
			&--list--wrapper {
				margin: 20px 0;

				@include from(desktop) {
					margin: 40px 0;
				}
			}

			&--video {
				padding-top: 100% / $video-gallery--video--aspect-ratio;
				position: relative;
				border: 1px solid $video-gallery--video--border-color;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			&--list {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				margin: 0 (-$video-gallery--list-item--margin / 2);
				padding: 0;

				list-style: none;

				@include from(desktop) {
					justify-content: flex-start;
				}
			}

			&--list-item {
				flex: 0 0 auto;
				max-width: $video-gallery--list-item--max-width;
				margin: 0 ($video-gallery--list-item--margin / 2);
				float: left;

				&--link {
					&:hover,
					&:focus {
						text-decoration: none;
					}
				}

				&--thumbnail {
					position: relative;
					border: 1px solid $video-gallery--list-item--border-color;
				}
				&--title {
					font-size: rem($video-gallery--list-item--title--font-size);
					font-weight: 200;
					margin: 5px 0;
				}

				&.is-active &--thumbnail {
					border-color: $video-gallery--list-item--active--border-color;
				}
			}
		}
	}
}
