
$header--background-color: $page--background-color !default;


$header--height: 70px !default;
$header--height-top: 105px !default;
$header--height-smalldesktop: 74px !default;
$header--height-bottom: 105px !default;
$header--menu-padding: 40px !default;
$header--color: $header--color !default;
$header--z-index: 100 !default;

$header--animation-duration: $default--animation-duration !default;
$header--animation-easing: $default--animation-easing !default;

$header--menu-bar--height: 60px !default;

$header-border--color: #4b4b4b !default;

@include exports("layout/header") {

	.header {
		position: fixed;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: $header--z-index;
		background-color: $header--background-color;
		backface-visibility: hidden;
		border-bottom: 1px solid $header-border--color;

		@include from(desktop) {
			position: fixed;
		}

		.container {
			@include to(desktop) {
				padding: 0;
				width: 100%;
			}
		}

		&--menu-bar {
			&--inner {
				min-height: $header--menu-bar--height;
				position: relative;

				.apply-tansitions & {
					transform: height $header--animation-duration $header--animation-easing;
				}
			}
		}
	}

	@include from(desktop) {

		.header {

			min-height: $header--height-smalldesktop;

			.container {
				padding: 0 15px;
			}

			&--menu-bar {
				&--inner {
					min-height: $header--height-smalldesktop;
				}
			}

		}

	}
}
