@import "../../config";

/**
 *
 * @author Dennis Özet
 *
 */

 @include exports("modules/product/ProductImageCarousel") {
	@include critical() {
		.carousel {
			&--product-images {
				padding: 0 30px;

				max-height: 157px;
				overflow: hidden;

				@include from(tablet) {
					max-height: 133px;
				}
				@include from(smalldesktop) {
					max-height: 157px;
				}
			}
			&--product-images &--item {
				margin: 15px 0;
			}
			&--item	img {
				margin: 0 auto;
			}
			&--item.embed-video	{
				max-height: 127px;
			}
			&--item.embed-video	a {
				display: inline-block;
				position: relative;
				overflow: hidden;
				margin: 0 auto;
			}
			&--item.embed-video	img {
				max-height: 127px;
			}
			&--item.view-360,
			&--item.embed-video {
				position: relative;
				overflow: hidden;
			}
			&--item.view-360 img {
				width: auto;
				height: 100%;
				max-height: 127px;
			}
			&--item.view-360 img:first-child {
				opacity: 0.4;
				z-index: 10;
			}
			&--item.view-360 img:last-child {
				position: absolute;
				left: 50%;
				top: 10px;				
    			transform: translateX(-50%);
				z-index: 20;
			}
			&--item.embed-video img:first-child {
				opacity: 1;
				z-index: 10;
			}
			&--item.embed-video img:last-child {
				position: absolute;
				width: 48px;
				height: auto;				
				left: 50%;
				top: 50%;
    			transform: translate(-50%, -50%);
				z-index: 20;
			}
		}
	}
 }
