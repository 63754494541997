@import "../config";

$breadcrumb--font-size: 14px !default;
$breadcrumb--color: $ci-color2-7 !default;
$breadcrumb-active--color: #fff !default;

@include exports("modules/Breadcrumb") {
	@include critical() {
		.breadcrumb {

			display: none;
			margin: em(20px) 0;
			padding: 0;
			background: none;
			font-size: $breadcrumb--font-size;

			@include from(tablet) {
				display: block;
			}


		}

		.breadcrumb-item {

			&+&:before {
				content: "\00BB";
				position: relative;
				top: -3px;
				vertical-align: top;

				color: $breadcrumb--color;
				font-size: em(20px);
			}

			a {
				color: $breadcrumb--color;
			}
			&.active {
				a {
					color: $breadcrumb-active--color;
				}
			}

		}

	}
}
