@import "../../config";


@include exports("layout/page") {}
@import "../../screen/layout/page";

$language-selection--background-color: $page--background-color !default;
$language-selection--border-color: $ci-color1 !default;

$language-selection--not-translated-color: $ci-grey !default;


@include exports("module/header/LanguageSelection") {
	@include critical() {
		.language-selection {

			&--list {
				display: none;

				position: absolute;
				top: 100%;
				// right: -($page--padding); // @TODO: maybe we should use variable for the page padding here
				right: 0;
				min-width: 100%;
				padding: 5px;

				background: $language-selection--background-color;
				border: 1px solid $language-selection--border-color;

				text-align: left;
			}
			&--handler:hover &--list {
				display: block;
			}

			&--current {
				cursor: default;
			}

			a {
				display: block;
			}

			.is-not-translated &--label {
				color: $language-selection--not-translated-color;
				cursor: default;
			}
		}
	}

	@include non-critical() {
	}
}
