@import "../../config";

$highlight-background--background-color: $ci-color2-3 !default;
$highlight-background--background-color--alternative: $ci-color2 !default;
$highlight-background--background-color--bright: $ci-color2-12 !default;

$highlight-background--texture-1: "../img/textures/texture-highlight-smartphone.jpg" !default;
$highlight-background--texture-1--tablet: "../img/textures/texture-highlight-tablet.jpg" !default;
$highlight-background--texture-1--desktop: "../img/textures/texture-highlight-desktop.jpg" !default;

$highlight-background--texture-2: "../img/textures/texture-02-smartphone.jpg" !default;
$highlight-background--texture-2--tablet: "../img/textures/texture-02-tablet.jpg" !default;
$highlight-background--texture-2--desktop: "../img/textures/texture-02-desktop.jpg" !default;

$highlight-background--texture-3: "../img/textures/texture-03-smartphone.jpg" !default;
$highlight-background--texture-3--tablet: "../img/textures/texture-03-tablet.jpg" !default;
$highlight-background--texture-3--desktop: "../img/textures/texture-03-desktop.jpg" !default;

$highlight-background--inner-wrapper--margin: 25px !default;
$highlight-background--inner-wrapper--margin--tablet: 40px !default;
$highlight-background--inner-wrapper--margin--desktop: 50px !default;

@include exports("modules/content/HighlightBackground") {
	@include critical() {

		.highlight-non {
			margin-bottom: $highlight-background--inner-wrapper--margin;

			@include from(tablet) {
				margin-bottom: $highlight-background--inner-wrapper--margin--tablet;
			}
			@include from(smalldesktop) {
				margin-bottom: $highlight-background--inner-wrapper--margin--desktop;
			}
		}
						
		.highlight-fxworld {					
			min-height: 260px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			p {
				color: #000;
			}
		}

		.highlight-wrapper {
			
			@include from(tablet) {
				min-height: 450px;
			}
			@include from(smalldesktop) {
				min-height: 450px;
			}
		}

		.highlight-slogan {
			background: linear-gradient(to bottom,#000 50%,#1a1a1a 75%) !important;
		}

		.highlight-background {
			background: $highlight-background--background-color repeat-y top center;
			position: relative;
			margin: 0 -10px;
			overflow: hidden;
			&:nth-child(3n-2), :nth-child(3n-2)>*{
				&:hover{
					.highlight-background--image-wrapper{
							transform:scale(1.05);
							-webkit-transform:scale(1.05);
							-ms-transform:scale(1.05);
					}
				}
			}

			.pt-4 {
				padding-top: 3rem !important;
				@media screen and (min-width:1200px) {
					padding-top: 4rem !important;
				}
			}
			.pb-4 {
				padding-bottom: 3rem !important;
				@media screen and (min-width:1200px) {
					padding-bottom: 4rem !important;
				}
			}

			.icon-button--inner {
				border: 1px solid #ccc;
			}

			@include from(tablet) {
				margin: 0 -50vw;
			}

			&.highlight-background--alternative {
				background-color: $highlight-background--background-color--alternative;
			}
			&.highlight-background--bright {
				background: $highlight-background--background-color--bright;
			}
			&.highlight-background--texture-1 {
				background-image: url($highlight-background--texture-1);
				// background-size: cover;
				border-top: 2px solid $highlight-background--background-color;
				border-bottom: 2px solid $highlight-background--background-color;
				@include from(tablet) {
					background-image: url($highlight-background--texture-1--tablet);
				}
				@include from(desktop) {
					background-image: url($highlight-background--texture-1--desktop);
				}
				&.new {
					background-image: url('../img/textures/Startseitengrafik_smartphone.jpg');
					background-size: auto 100%;
					background-color: #000;
					background-position: center bottom;
					background-repeat: no-repeat;
					.icon-button {
						margin-bottom: 10px;
					}
					@include from(tablet) {
						background-image: url('../img/textures/Startseitengrafik_tablet.jpg');
						background-size: auto 100%;
						background-position: center bottom;
						.icon-button {
							margin-bottom: 20px;
						}
					}
					@include from(desktop) {
						background-image: url('../img/textures/Startseitengrafik_desktop.jpg');
						background-size: auto 150%;
						background-position: center bottom;
						.icon-button {
							margin-bottom: 0;
						}
					}

				}
			}
			&.highlight-background--texture-2 {
				background-image: url($highlight-background--texture-2);
				// background-size: cover;
				
				border-top: 2px solid $highlight-background--background-color;
				border-bottom: 2px solid $highlight-background--background-color;

				@include from(tablet) {
					background-image: url($highlight-background--texture-2--tablet);
				}
				@include from(desktop) {
					background-image: url($highlight-background--texture-2--desktop);
				}
			}
			&.highlight-background--texture-3 {
				background-image: url($highlight-background--texture-3);
				// background-size: cover;

				border-top: 2px solid $highlight-background--background-color;
				border-bottom: 2px solid $highlight-background--background-color;

				@include from(tablet) {
					background-image: url($highlight-background--texture-3--tablet);
				}
				@include from(desktop) {
					background-image: url($highlight-background--texture-3--desktop);
				}
			}
			&.highlight-background--texture-4 {
				position: relative;
				border-top: 2px solid $highlight-background--background-color;
				border-bottom: 2px solid $highlight-background--background-color;
				z-index: 1;
				&:before {
					content: "";
					z-index: -1;
					position: absolute;
					left: 0;
					top: 0;					
					opacity: 0.8;
					width: 100%;
					height: 100%;
					background-image: url($highlight-background--texture-1);
					@include from(tablet) {
						background-image: url($highlight-background--texture-1--tablet);
					}
					@include from(desktop) {
						background-image: url($highlight-background--texture-1--desktop);
					}
				}
			}

			&--image-wrapper {
				@media (max-width: 992px) {
					width: 100%;
					height: auto;
				}
				text-align: center;
				transition: all 2s linear;
				-webkit-transition: all 2s linear;
				-ms-transition: all 2s linear;
				&--fade {
					@include from(tablet) {
						margin-top: -30px;
						width: 100%;
						height: 30px;
						position: absolute;
						background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
					}
					@include from(largedesktop) {
						display: none;
					}
				}
			}

			&--inner-wrapper, &--inner-wrapper-wide {
				margin: 0 10px;

				@include from(tablet) {
					margin: 0 50vw;
				}
				@include from(desktop) {
					margin: 0 50vw;
				}
			}

			//&--title {
			//	font-weight: 800;
			//	margin: 0 0 (40px - 10px); // - 10px for visual margin of 40px
			//}
			//&--sub-title {
			//	margin: 0 0 (40px - 10px); // - 10px for visual margin of 40px
			//}
			//&--title + &--sub-title {
			//	margin-top: -(40px - 15px);
			//}

			&.highlight-background--image {
				background: none;
				border-bottom: 2px solid $highlight-background--background-color;
				&-strips {
					background-image: repeating-linear-gradient(-45deg, #000 0%, #000 30%, #111 30%, #111 50%, #000 50%);
				}
				@include from (tablet) {
					.highlight-background--inner-wrapper {
						display: flex;
						@media (min-width: 992px) {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
						}						
					}
					.highlight-background--inner-wrapper-wide {
						position:relative;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: flex;
					}
					.highlight-background--center-wrapper {
						flex: 1 1 auto;
						align-self: center;
					}
				}
			}

			&.highlight-background--bright {
				.h2, .h3, .h4 {
					font-weight: 900;
					color: black;
				}
				.h2 {
					font-size: rem(30px);
					margin-bottom: rem(24px);
				}
				.h3 {
					font-size: rem(26px);
				}
				.h4 {
					font-size: rem(22px);
				}
			}

			.highlight-video {
				&-wrapper {
					position: relative !important;
				}
				.embed-video {
					position: relative;
					width: 100%;
					padding-bottom: 56.25%;
					iframe {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 0;
						z-index: 10;
					}
					@media screen and (min-width:768px) {
						&:before {
							content: '';
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							z-index: 100;
						}
					}
				}
			}
		}

		.highlight-gradient {
			// First one
			&--1 {
				background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
			}
			&--2 {
				background: linear-gradient(to right,#000 60%,#1f1f1f 70%);
			}
			&--3 {
				background: linear-gradient(to left,#000 30%,#222222 70%);
			}

			@include from(tablet) {
				&--sm-1 {
					background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
				}
				&--sm-2 {
					background: linear-gradient(to right,#000 60%,#1f1f1f 70%);
				}
				&--sm-3 {
					background: linear-gradient(to left,#000 30%,#222222 70%);
				}
			}

			@include from(smalldesktop) {
				&--md-1 {
					background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
				}
				&--md-2 {
					background: linear-gradient(to right,#000 60%,#1f1f1f 70%);
				}
				&--md-3 {
					background: linear-gradient(to left,#000 30%,#222222 70%);
				}
			}
			@include from(desktop) {
				&--lg-1 {
					background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
				}
				&--lg-2 {
					background: linear-gradient(to right,#000 60%,#1f1f1f 70%);
				}
				&--lg-3 {
					background: linear-gradient(to left,#000 30%,#222222 70%);
				}
			}
			@include from(largedesktop) {
				&--xl-1 {
					background: linear-gradient(to bottom, #000 0%, #1a1a1a 100%);
				}
				&--xl-2 {
					background: linear-gradient(to right,#000 60%,#1f1f1f 70%);
				}
				&--xl-3 {
					background: linear-gradient(to left,#000 30%,#222222 70%);
				}
			}
		}
	}
	.product-intro {
		.order-1 {
			order: 1;
		}
		.order-2 {
			order: 2;
		}
		.order-3 {
			order: 3;
		}
		@media (min-width: 768px) {
			.order-md-1 {
				order: 1;
			}
			.order-md-2 {
				order: 2;
			}
			.order-md-3 {
				order: 3;
			}
		}
		@media (max-width: 768px) {
			.mb-xs-2 {
				margin-bottom: 1.5rem !important;
			}
		}
		&-icon {
			img {
				display: block;
				margin: 0 auto;
				width: 100%;
				max-width: 128px;
				height: auto;
			}
			&.watercooler {
				img {
					max-width: 228px;
				}
			}
			@media (max-width: 768px) {
				display: none;
			}
		}
		&-image {
			img {
				display: block;							
				width: 100%;
				height: auto;
				max-width: 200px;
				max-height: 300px;
				margin: 0 auto;
				mix-blend-mode: multiply;
			}
		}
	}
}
