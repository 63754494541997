@import "../../config";

@include exports("modules/content/FancyHover") {}
@import "../../modules/content/FancyHover";

$icon-button--color: $link--color !default;
$icon-button--min-height: 85px !default;
$icon-button--min-height--tablet: 260px !default;

$icon-button--icon-size: 50px !default;
$icon-button--icon--color: $ci-color2-12 !default;
$icon-button--background: $ci-color2-3 !default;

$icon-button--inner--border-color: $ci-color2-11 !default;
// $icon-button--extra-content--background: $ci-color2-3 !default;


@include exports("modules/content/IconButton") {
	@include critical() {
		.icon-button {
			position: relative;
			display: block;
			margin-bottom: 10px;
			// background: rgba($icon-button--background, 0.6);
			@include from(tablet) {
				margin-bottom: 20px;
			}
			&, &:hover, &:focus {
				color: $icon-button--color;
				text-decoration: none;
			}
			&--inner {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				min-height: $icon-button--min-height;
				border-radius: 3px;
				border: 1px solid $icon-button--inner--border-color;
				overflow: hidden;

				@include from(tablet) {
					min-height: $icon-button--min-height--tablet;
				}
			}
			&.no-extra-content {
				.icon-button--inner {
					transition: border $fancy-hover--transition-duration $fancy-hover--transition-easing;
				}
				&:hover,
				&:focus {
					.icon-button--inner {
						border-color: $ci-color2-8;
					}
				}
			}
			&--content {
				position: relative;
				display: block;
				padding: 15px 20px;
			}
			&--icon-wrapper {
				display: block;
				float: left;

				color: $icon-button--icon--color;
				font-size: em($icon-button--icon-size);
				line-height: 1;

				&>i>img{
					height:50px;
				}

				@include from(tablet) {
					margin: 43px 0;
					float: none;
					text-align: center;
				}
			}
			&--text-wrapper {
				display: block;
				margin: 0 0 0 ($icon-button--icon-size + 15px);
				hyphens: manual;

				@include from(tablet) {
					margin: 0;
					text-align: center;
				}
			}
			&--extra-content {
				display: block;
				padding: 20px;
				text-align: center;

				@include from(largedesktop) {
					padding: 40px;
				}

				.icon-button--text-wrapper {
					font-family: $ci-font--font-family;
					font-size: 20px;
					h3 {
						font-size: 20px;
					}
				}
			}
			&--actions-wrapper {
				display: block;
				margin-top: 30px;
				@include from(largedesktop) {
					margin-top: 40px;
				}

				.btn {
					width: 100%;
				}
			}
		}
		// Rounded icon button
		.icon-button--rounded {
			background: black;
			border-radius: 2em;
			width: 50px;
			height: 50px;
			margin-right: 15px;
			display: inline-block;
			text-align: center;
			color: $ci-color2-12;
			&.cart {
				color: white;
				background-color: $ci-color1;
			}
			&:hover {
				color: white;
				background-color: $ci-color1;
			}
			i {
				font-size: rem(24px);
				padding-top: 8px;
				margin-top:0px;
				height:50px;
				display: block;
			}
		}

	}
}
