@import "config";


@include critical() {
	@import "libs/bootstrap/bootstrap-flex";

	html {
		font-size: $base--font-size;
		font-weight: $base--font-weight;
		line-height: line-height($base--line-height);
	}


	//include iconfont
	@include iconfont-base();
	@include iconfont-classes();

	//@import "libs/gridlayout";

	@import "screen/helper";
	@import "screen/base";
	@import "screen/layout";

	.pt-10 {
		padding-top: 10px;
	}
	.pb-10 {
		padding-bottom: 10px;
	}
	.py-10 {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
