@import "../../config";

// @TODO: extract this to a separate module!
$switch--width: 32px !default;
$switch--height: 18px !default;
$switch--background: $ci-color2 !default;
$switch--background--is-active: $ci-success !default;
$switch--border-color: $default--border-color !default;

$switch--handle--width: $switch--height - 2 !default;
$switch--handle--height: $switch--handle--width !default;
$switch--handle--border-color: $switch--border-color !default;
$switch--handle--background-color: $page--background-color !default;

// $switch--handle--animation-duration: $default--animation-duration !default;
$switch--handle--animation-duration: 150ms !default;
$switch--handle--animation-easing: $default--animation-easing !default;

.switch {
	background: $switch--background;

	position: relative;
	display: inline-block;
	top: 4px; // @TODO:
	width: $switch--width;
	height: $switch--height;

	border: 1px solid $switch--border-color;
	border-radius: 50em;

	transition: all $switch--handle--animation-duration $switch--handle--animation-easing;

	cursor: pointer;

	user-select: none;
	-webkit-touch-callout: none;

	&:before {
		content: "";
		background-color: $switch--handle--background-color;

		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: $switch--handle--width;
		height: $switch--handle--height;

		border-radius: 50em;

		transition: all $switch--handle--animation-duration $switch--handle--animation-easing;
	}
	&.on {
		background: $switch--background--is-active;

		&:before {
			left: 100%;
			margin-left: -$switch--handle--width;
		}
	}

	& + input[type="checkbox"] {
		display: none;

		& + label {
			&:before,
			&:after {
				content: none;
			}
		}
	}


	.checkbox > & {
		position: absolute;

	}
	.check-group .checkbox > & + input + label {
		// @TODO: find a way that this can work!
		// $_decimals: pow(10, logarithm($switch--width , 10));
		$_decimals: 10;
		padding-left: ceil($switch--width / $_decimals) * $_decimals;
	}

}
