$table--bg-color: $ci-color2-3 !default;
$table--font-size: em(16px) !default;



.table {

	&.has-no-border {
		& th, & td {
			border-top: none;
		}
	}

	&.table-hover > tbody tr:hover,
	&.table-hover > .row:hover {
		background-color: $table--bg-color;
	}
	.highlight-background &.table-hover > tbody tr:hover,
	.highlight-background &.table-hover > .row:hover {
		background-color: $ci-color2;
	}
	.highlight-background.highlight-background--alternative &.table-hover > tbody tr:hover,
	.highlight-background.highlight-background--alternative &.table-hover > .row:hover {
		background-color: $ci-color2-3;
	}


	// Make it more smaller
	&.table-sm th, &.table-sm td, &.table-sm .col {
		padding: 0.2rem;
	}

	// Change default behaviour, before back to the roots
	& th, & td {

		display: block;
		width: 100%;
		font-size: $table--font-size;

		@include from(tablet) {
			display: table-cell;
			width: 50%;
		}

		&.table-value {
			padding-left: 50px;

			@include from(tablet) {
				padding-left: 0;
			}
		}

	}

}
