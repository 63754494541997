@import "../config";

@include exports("modules/Nonoise") {
    @include critical() {
        .nonoise {
            color: #fff;
            h2 {
                display: inline-block;                
                position: relative;
                font-size: 2.5em;
                line-height: 100%;           
                margin-top: 0;
                z-index: 100;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100vw;
                    height: 4px;
                    background:#ed8000;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                }
                @media(max-width:768px) {
                    font-size: 2em;
                    &:after {
                        height: 3px;
                    }
                }
            }
            img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 auto;
            }
            .embed-video {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;    
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 0;
                }
            }
            .video-title {
                display: block;
                padding-top: 20px;
                font-size: 24px;
                font-weight: 400;
                text-align: center;
            }
            .block {
                padding: 20px 0;
                margin-bottom: 80px;
                z-index: 50;
                @media(max-width:768px) {
                    margin-bottom: 20px;
                }
                &.no-mrg-bot {
                    margin-bottom: 0 !important;
                }
                &.specials {
                    margin-bottom: 0;
                }
            }
            .quote, .grey {
                background:#1a1a1a;
            }
            .quote {
                font-size: 4em;
                font-weight: 200;                
                text-align: center;
                text-transform: uppercase;              
            }
            .specials {                
                &-heading {
                    font-size: 4em;
                    font-weight: 200;                
                    text-align: center;
                    text-transform: uppercase;
                    background:#1a1a1a;
                    padding: 20px 0;
                }
                &-content {
                    background:#1a1a1a;
                    padding: 20px 0 40px;
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
                &-videos {
                    padding-bottom: 20px;
                    .col-lg-6 {
                        margin-top: 20px;
                    }
                    .col-lg-5 {
                        padding: 20px;
                    }
                    /*
                    @media(max-width:992px) {
                        .col-lg-6:nth-child(2) {
                            margin-top: 20px;
                        }
                    }
                    */
                }
                &-link {
                    padding: 40px 0;                   
                    a {
                        font-size: 24px;
                        text-transform: uppercase;
                    }
                    .container {
                        text-align: center;
                    }
                }              
            }
            .heading {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                min-height: 550px;
                background-image: url("/volumes/general/landingpages/no_noise_header_l.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                margin-bottom: 0;
                @media(max-width:768px) {
                    min-height: 320px;
                }
                h1 {
                    font-size: 10em;
                    font-weight: 600;
                    line-height: 100%;
                    text-align: center;
                    margin: 0;                    
                    @media(max-width:1400px) {
                        font-size: 7em;
                    }
                    @media(max-width:768px) {
                        font-size: 5em;
                    }
                    @media(max-width:576px) {
                        font-size: 4em;
                    }
                }
                span {
                    display: block;
                    text-align: center;
                    font-size: 2.5em; 
                    font-weight: 200;
                    margin-bottom: 20px;
                    @media(max-width:1400px) {
                        font-size: 2em;
                    }
                    @media(max-width:768px) {
                        font-size: 1.5em;
                    }
                    @media(max-width:576px) {
                        font-size: 1.35em;
                    }
                }
            }
/*
            .menu {
                ul {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center; 
                    margin-bottom: 60px;
                    padding: 0;
                    background: #383535;
                    border-top: 1px solid #ed8000;
                }
                ul li {
                    display: block;
                    flex: 0 0 25%;
                    padding: 20px 0;
                    text-align: center;
                    @media(max-width:1200px) {
                        flex: 0 0 33%;
                    }
                    @media(max-width:992px) {
                        flex: 0 0 50%;
                    } 
                    @media(max-width:576px) {
                        flex: 0 0 100%;
                    }
                }
                ul li a {
                    font-size: 18px;
                    font-weight: 400;
                }
                .subtext {
                    text-align: center;
                }
            }
*/          .btn {
                border: 1px solid #fff;
                color: #fff;
                font-size: 1.3333333333rem;
                font-weight: 400;
                border-radius: 0;
                &:focus, &:hover {
                    background: #fff;
                    color: #ee7f00;
                }
            }
            .menu-btn {
                display: block;
                position: relative;
                font-size: 24px;
                text-align: center;
                width: 50px;
                height: 50px;
                line-height: 50px;
                margin: 0 auto;
                background: #ed8000;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                border-radius: 25px;
                color: #fff !important;
                cursor: pointer;
                overflow: hidden;               
                @media(max-width:768px) {
                    
                }
            }
            .menu {
                position: relative;
                z-index: 1000;
                @media(max-width:768px) {
                    position: fixed;
                    bottom: 5px;
                    left: 5px;                    
                }
                ul {
                    display: none;
                    position: absolute;
                    margin-bottom: 60px;
                    max-width: 480px;
                    padding: 0;
                    background: #111;
                    border-top: 2px solid #ed8000;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    z-index: 1250;
                }
                ul.active {
                    display: block;
                    /*
                    @media(max-width:768px) {
                        position: fixed;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    */
                    @media(max-width:768px) {
                        position: absolute;
                        top: auto;
                        margin-bottom: 0;
                        bottom: 10px;
                        left: 10px;
                        transform: none;
                    }
                }
                ul li {
                    display: block;
                    padding: 20px;
                    text-align: left;
                }
                ul li a {
                    display: block;
                    font-size: 18px;
                    font-weight: 400;
                    white-space: nowrap;
                    @media(max-width:768px) {
                        font-size: 16px;
                    }
                    @media(max-width:576px) {
                        font-size: 14px;
                    }                    
                }
            }
            .subtext {
                font-size: 20px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 40px;
            }
            .subheading {
                position: relative;
                padding: 0;
                background-image: url("/volumes/general/landingpages/no_noise_bild_line.jpg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center bottom; 
                .container {
                    position: relative;
                    min-height: 420px;
                    @media(max-width:768px) {
                        min-height: 240px;
                    }
                }
                span {
                    display: block;
                    position: absolute;
                    font-size: 90px;
                    font-weight: 600;
                    line-height: 90px;
                    left: 0;
                    bottom: 25px;
                    @media(max-width:1200px) {
                        left: 10px;
                        font-size: 60px;
                        line-height: 50px;
                        bottom: 30px;
                    }
                }
            } 
            .partners {
                text-align: center;
                background: #fff;
                .video-title {
                    color: #000;
                    padding-top: 0;
                }
                img {
                    display: inline-block;
                    max-height: 60px;
                    width: auto;
                    margin: 20px 10px;
                }
            }     
        }
    }
}