@import "../../config";

$navtabs-width: 300px !default;
$navtabs-color: $ci-border-color-4;
$navtabs-fontsize: 20px !default;

$navcontent-hover--color: $ci-color1 !default;
$navcontent-fontsize: 14px !default;

$quickcontact-wrapper--background-color: #000 !default;
$quickcontact-wrapper--border-color: $ci-border-color-4 !default;
$quickcontact-height: 74px !default;
$quickcontact-handle--width: 60px !default;
$quickcontact-handle--color: $ci-color2-5 !default;
$quickcontact-handle--color--hover: $ci-color2-9 !default;

@include exports("modules/header/quickcontact") {

	@include critical() {

		.quickcontact {

			@include from(smartphone) {
			float:none;
			}
			@include from(tablet) {
			float:none;
			}
			@include from(desktop) {
			float:left;
			}

			li .mainmenu--item {
				background-color: #222;

				.submenu-handle {
					font-size: 22px;
					color: $quickcontact-handle--color;
				}
			}

			&-handle, &-wrapper {
				display: none;
		 	}

			&-content--element {
				list-style-type: none;
				padding-left: 0;

				li {
					margin: 5px 0;

					&.active {
						.quickcontact-title {
							font-weight: 700;
							color: #fff;
						}

						a {
							&.active {
								font-weight: 700;
								text-decoration: none;
								color: #fff;
							}
						}
					}
				}

				.quickcontact-title {
					font-size: 20px;
				}
			}
		}

		@include from(desktop) {

			.mainmenu--quickcontact {
				// position: relative;
				top: 0;
				float: left;
				height: $quickcontact-height;
			}

			.quickcontact {

				visibility: visible;
				// position: relative;

				&-handle {
					display: inline-block;
					// width: $quickcontact-handle--width;
					padding-left: 16px;
					padding-right: 16px;
					
					height: $quickcontact-height;
					text-align: center;
					line-height: $quickcontact-height - 2px;
					font-size: 20px;
					color: $quickcontact-handle--color;

					&:focus {
						color: $quickcontact-handle--color;
					}

					.is-opened & {
						color: $ci-color1;
						&:hover {
							color: $ci-color1 !important;
						}
					}

					&:hover {
						color: $quickcontact-handle--color--hover !important;
					}
				}

				.level-1 {
					display: none;
				}
			}
		}
	}

}
