@import "../../config";

@include exports("modules/forms/TinyMCE") {
	@include critical() {

		textarea.tinymce {
			min-height: 350px;
		}

	}
	@include non-critical() {

		body.mce-content-body {
			min-height: 0;
			margin: 20px;
		}

	}
}
