
$select-input--color: $ci-color2-9 !default;
$select-background--color: $ci-color2-3 !default;
$select-border--color: $default--border-color !default;
$select-svg--color: ""+$select-input--color !default;

@mixin set-static-svg($color) {
	// @INFO: '#' must be URL encoded for Firefox!
	$color: "" + $color;
	$color: str-replace($color, "#", "%23");
	//background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='"+ $color +"' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"); //doesn't work in IE
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%204%205%27%3E%3Cpath%20fill%3D%27"+ $color +"%27%20d%3D%27M2%200L0%202h4zm0%205L0%203h4z%27/%3E%3C/svg%3E"); //work in IE
}

@include exports("base/select") {

	/** NO JS start *****/
	.custom-select {
		@include set-static-svg($select-svg--color);
	}

	.custom-select2 {
		padding: 0;

		&.form-control {
			background-color: #000;
			&:hover, &:focus {
				background-color: #000;
			}
		}

		option {
			//padding-left: .75rem;
			//padding-right: .75rem;
			padding: 10px 20px;
			font-weight: 200;
			font-size: 18px;

			&:checked,
			&[selected]{
				//background-color: $ci-color2-3;
				background: $select-input--color linear-gradient(0deg, $select-input--color 0%, $select-input--color 100%);
				//color: $select-input--color;
			}

		}

		&:focus option:checked,
		&:focus option[selected]{
			//background-color: $ci-color2-3;
			background: $select-input--color linear-gradient(0deg, $select-input--color 0%, $select-input--color 100%);
			//color: $select-input--color;
		}
	}
	/** NO JS end *****/
}


