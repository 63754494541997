@import "../../config";

$differences--width: 24px !default;
$differences--height: 24px !default;
$differences--switch--width: 60px !default;

$differences--bg--color1: $ci-color2-3 !default;
$differences--bg--color2: #fff !default;
$differences--bg--color--on: $ci-color1 !default;

@include exports("modules/content/Differences") {

	@include critical {
		.differences--label {
			padding-left: 10px;
			font-size: em(14px);
		}
	}

	@include non-critical {

		.switch {
			position: relative;
			top: 5px;
			right: 0;
			display: inline-block;
			width: $differences--switch--width;
			border: 1px solid $differences--bg--color1;
			background: $differences--bg--color1;
			cursor: pointer;
			user-select: none;
			-webkit-touch-callout: none;

			&, &:before {
				height: $differences--height;
				border-radius: 50em;
				transition: all 150ms ease-in-out;
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: -1px;
				left: 0;
				width: $differences--width;
				background-color: $differences--bg--color2;
			}

			&.is--on {
				background: $differences--bg--color--on;

				&:before {
					left: 100%;
					margin-left: -$differences--width;
				}
			}

			& + input[type="checkbox"] {
				display: none;
			}


			// & + input + label {
			// 	padding-left: 10px;
			// }

		}

	}

}