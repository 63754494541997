@import "../../config";

/**
 * button + field
 *
 *
 * @author Dennis Özet
 *
 */

$inputGroupButton--color: $ci-color2-6;
$inputGroupButton--bg: $ci-color2-3;
$inputGroupButton--border: $ci-color2-8;
$inputGroupButton--border-hover: $ci-color1;

$input-color: $ci-color2-9 !default;

@include exports("modules/forms/InputGroupButton") {

	@include critical() {

		.input-group {

			.form-control {
				color: $inputGroupButton--color;
				border-color: $inputGroupButton--border;
				background: $inputGroupButton--bg;
			}

			input, input.form-control {
				color: $input-color;
			}

			.btn-std {

				i {
					font-size: em(20px);
				}

				&:focus, &:hover, &:active {
					border-color: $inputGroupButton--border-hover;
				}
			}

		}

	}

}
