// @INFO: These spaces are reserved spaces!!! (ASCI:160 [ ])
$horizontal-list--spacing-content: " | " !default;
$horizontal-list--bullet--spacing-content: " • " !default;

$vertical-list--padding: 20px !default;
$vertical-list--margin: 1rem !default;



ul.horizontal-list {
	position: relative;

	& > li {
		display: inline-block;

		&:before {
			content: $horizontal-list--spacing-content;
		}
		&:first-child:before {
			content: none !important;
		}
	}

	&.bullet > li:before {
		content: $horizontal-list--bullet--spacing-content;
	}
}


ul.vertical-list {

	list-style: square;
	margin-top: $vertical-list--margin;
	padding-left: $vertical-list--padding;

	& li {
		margin-bottom: 10px;
	}

	& ul, & ul ul {
		list-style: disc;
		margin-top: $vertical-list--margin;
		padding-left: ($vertical-list--padding * 2);

		& ul {
			list-style: circle;
		}

	}

}
