@import "../../config";



$logo--url: "../img/bequiet_logo.png" !default;
$logo--width: 154px !default;
$logo--height: 34px !default;
$logo--width--tablet: $logo--width !default;
$logo--height--tablet: $logo--height !default;

$logo--font-size: 18px !default;
$logo--font-family: $ci-font !default;

@include exports("module/header/Logo") {
	 @include critical() {
		.logo {
			position: absolute;
			right: 10px;
			top: 13px;
			text-align: left;

			@include from(desktop) {
				top: 21px;
				right: 0;
			}


			&--link {
				display: inline-block;
			}

			&--image {
				display: block;
				width: $logo--width;
				height: $logo--height;
				background: url($logo--url) no-repeat scroll 0 0 transparent;
				background-size: cover;

				@include from(tablet) {
					width: $logo--width--tablet;
					height: $logo--height--tablet;
				}
			}

			&--slogan {
				display: none;

				@include from(tablet) {
					position: absolute;
					left: 100%;
					top: 46px;
					display: block;
					width: 400px;
					margin-left: 2px;

					font-family: $logo--font-family;
					font-weight: bold;
					font-size: em($logo--font-size);
				}
			}
		}
	}
}
