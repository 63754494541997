$hr--border-color: #373a3c !default;

$highlight-background--margin--smartphone: 25px !default;
$highlight-background--margin--tablet: 40px !default;
$highlight-background--margin--desktop: 50px !default;

hr {
	margin: 2.222rem 0;
	border-top-color: $hr--border-color;
}

.divider {
	margin-top: 25px;
	padding-bottom: 25px;

	@include from(tablet){
		margin-top: 40px;
		padding-bottom: 40px;
	}

	@include from(desktop){
		margin-top: 50px;
		padding-bottom: 50px;
	}

	&--full-width {
		margin: 0 ($highlight-background--margin--smartphone)*-1;

		@include from(tablet) {
			margin: ($highlight-background--margin--tablet) -50vw;
		}

		@include from(desktop) {
			margin: ($highlight-background--margin--desktop) -50vw;
		}
	}
}