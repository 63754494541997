
@include exports("helper/ScrollableArea") {

	.scrollable-area {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;

		&.scroll-x {
			overflow-x: auto;
		}
		&.scroll-y {
			overflow-y: auto;
		}
	}

}
