@import "../config";

$modal--color: $ci-color2-5 !default;
$modal-title--color: $modal--color !default;

@include exports("modules/Modal") {
	@include critical() {
		[data-toggle="modal"] {
			cursor: pointer;
		}
		.modal {
			color: $modal--color;
			z-index: 9999;
		}

		.modal-header {
			border-color: $modal-title--color;

			.close {
				color: $modal-title--color;
				opacity: 1;
			}
		}
		.modal-title {
			color: $modal-title--color;

			&.has-border {
				border-color: $ci-color2-9;
			}
		}

		.modal-title{
			color:#ccc;
		}

		/* #modal-contact{
			display: block;
			position: absolute;
			background: rgba(0,0,0,0.7);
		}

		.contact-close{
			position: absolute;
			top: 6px;
			right: 15px;
			border: 0;
			color: white;
			background: none;
			font-size: 30px;
			&:hover{
				cursor: pointer;
			}
		}
*/
		h5.modal-title.text-red{
			color: #ee7f00;
			font-weight: bold;
		} 

		.attention{
			border: 10px solid red;
			padding: 20px;
		}
	}
}
