@import "../../config";

$news-article--background-color: #000 !default;
$news-article--border-color: $ci-color2-3 !default;
$news-article--font-size: 16px !default;
$news-article--color: $ci-color2-9 !default;
$news-article-link--color: #fff !default;
$news-article-date--color: $ci-color2-8 !default;
$news-article-more--margin-top: 20px !default;

$news-article--image--scale-factor: 1.15 !default;
$news-article--image--transition-duration: 2s !default;
$news-article--overlay--transition-duration: 0.3s !default;
$news-article--more--transition-duration: 0.3s !default;

@include exports("modules/news/Article") {

	@include critical() {

		.news {

			// Override bootstrap card
			.card {
				transition: border-color $news-article--overlay--transition-duration;

				&-link {
					color: $news-article-link--color;
					text-decoration: none;
				}

				&-date {
					color: $news-article-date--color;
				}

				&-title {
					overflow: hidden;
					margin-top: 0.667rem;
					margin-bottom: 0;
					font-weight: 800;
					height: 90px;

					@include from(smalldesktop){
						height: 65px;
					}
					@include from(largedesktop){
						height: 85px;
					}
				}

				&-text {
					display: none;
					overflow: hidden;
					margin-top: 0.667rem;
					color: $news-article--color;
					height: 65px;

					@include from(largedesktop) {
						display: block;
					}
				}



				&-link {

					&:focus, &:hover, &:active {
						.card {
							border-color: $ci-color2-8;
						}
					}
				}

			}

		}



		// Specials
		.news-article {

			@include from(tablet) {
				h3 + &, .h3 + & {
					margin-top: -15px;
				}
			}

			padding-top: 15px;
			//border-top: 1px solid #fff;


			&-more {
				margin-top: $news-article-more--margin-top;

				@include from(largedesktop) {
					margin-top: ($news-article-more--margin-top + 10);
				}
			}
		}



		// Grid as list
		.news-list,
		.news-article {

			&--item {
				padding-bottom: 20px;

				.card {
					margin-bottom: 0;
					height: 100%;

				}
				.card-img {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 130px;
					img {
						display: block;
						width: 100%;
						height: auto;
						margin: 0 auto;
						max-width: 150px;
						max-height: 100%;
					}

					@include from(smalldesktop) {
						height: 225px;
					}
					@include from(desktop) {
						height: 146px;
					}
					@include from(largedesktop) {
						height: 186px;
					}
				}
			}
		}


	}

}
