@import "../../config";

@include exports("modules/content/HighlightBackground") {}
@import "HighlightBackground";

$container-break--border-color: $ci-border-color-4 !default;
$highlight-white--border-color: #fff !default;

@include exports("modules/content/ContainerBreak") {

	@include critical() {

		.product-new {
			.border-top {
				border-top: 2px solid #222;
			}
		}

		.container-break {
			//background: $highlight-background--background-color;
			position: relative;
			margin: 0 -50vw;
			overflow: hidden;
			//padding: 0 10px ($highlight-background--inner-wrapper--margin);
			// padding: 0 10px 0;
			&:last-child {
				margin-bottom: 0;
				.container-break--line-wrapper {
					border-bottom: 0;
				}
			}
			//@include from(tablet) {
			//	padding: 0 0 ($highlight-background--inner-wrapper--margin--tablet);
			//}
			//
			//@include from(desktop) {
			//	padding: 0 0 ($highlight-background--inner-wrapper--margin--desktop);
			//}

			&--inner-wrapper {
				margin-left:  50vw;
				margin-right:  50vw;
			}

			&--line-wrapper {
				//margin: ($highlight-background--inner-wrapper--margin) 0;
				padding-left:  50vw;
				padding-right:  50vw;

				//@include from(tablet) {
				//	margin-top: $highlight-background--inner-wrapper--margin--tablet;
				//	margin-bottom: $highlight-background--inner-wrapper--margin--tablet;
				//}
				//@include from(desktop) {
				//	margin-top: $highlight-background--inner-wrapper--margin--desktop;
				//	margin-bottom: $highlight-background--inner-wrapper--margin--desktop;
				//}
			}

			.is-bordered--bottom {
				border-bottom: 2px solid $container-break--border-color;
			}

			.is-bordered--top {
				border-top: 2px solid $container-break--border-color;
			}

			&.highlight-white > .is-bordered--bottom  {
				border-bottom: 2px solid $highlight-white--border-color;
			}

			&.has-tabs {

				.container-break--inner-wrapper,
				.container-break--line-wrapper {
					position: relative;
					margin-top: 0;
					margin-bottom: 0;
				}

				.container-break--line-wrapper {
					z-index: 20;
				}

				.container-break--inner-wrapper {
					z-index: 10;
				}

			}
		}
	}
}
