@import "../config";

	@include critical{
    #cardwrapper{
      position:relative;
      margin-top:35px;
      margin-bottom: 30px;
    }

    .card1, .card2{
      position: absolute;
      height: 220px;
    }

    .card3{
      height: 220px;
    }

    #sw2whiteheadline{
      position: absolute;
      margin-top: 0px;
      opacity: 0;
    }
        
    }

    @include non-critical{

        .main-content--wrapper.container{
          width:100vw;
          &>div>div>.row.galery{
            width:100vw;
          }
        }

        h1.light{
          font-size: 20px;
        }

        .center{
          text-align: center;
          &>*{
            text-align: center;
          }
        }

        #sw2whiteheadline{
          transition:4s ease;
        }

        .headervideosb>video{
          margin-top:0px;
        }

        .buttonwrap{
          display:block;
        }

        .productlink{
          margin:0px auto;
        }

        .videoplay{
          display:block;
          margin:0px auto;
        }

        .tforce{
          max-width:200px;
        }

        .border-0{
          border:0px;
        }

        .text-right{
          text-align: right;
        }

        .text-center{
          text-align: center;
        }

        .drslimhighlight{
          border-top: 2px solid #ee7f00;
          &>.text, .text-right{
            padding-top:6%;
          }
        }

        .bubble{
          width:80px;
        }

        .innercapt {
          width:100%;
          min-height: 180px;
          background-color:#000; 
          padding: 20px;
        }
        
        @media (min-width: 10em) and (max-width: 60em) {
          .innercapt {
            min-height: 380px;
          }
        }
        
        .slick-arrow {
            color: #fff !important;
            font-size: 2.5em !important;
            top: 42% !important;
            text-shadow: 2px 2px 10px #000;
        }
        
        .slick-list {
           padding-top:2%;
           bottom: -5px;
        }

        .join {
          float: right;
          z-index: 3000;
          text-align: right;
          margin-top: 0;
        }

        #playingcardsheadline{
          position: absolute;
          margin-top: 4%;
        }

        .exeption{
          color: #666;
          font-size: 12px;
        }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
          &>p{
            color: #666;
            font-size: 12px;
            &>strong{
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .privacytext{
          color: #666;
          font-size: 12px;
        }

        #submit{
          margin-top: .65rem;
          margin-bottom: 20px;
          padding: 8px 40px;
        }

        h2{
          /* text-transform: uppercase; */
          color:#fff;
          font-weight: 700;
        }

        .radio>label{
          &:hover{
            cursor: pointer;
          }
          &>input{
            margin-right: 10px;
          }
          
        }

        .lines{
          opacity:0.6;
        }

        .bubble{
          max-height: 180px;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        strong{
          color:#fff;
        }

        td{
          padding:5px;
        }

          @media (min-width: 576px){
            .main-content--wrapper.container {
              &>div>div>.row{
                width: 540px;
                max-width: 100%;
                margin: 0px auto;
              }
            }
          }

          @media (min-width: 768px) {

            #playingcardsheadline{
              margin-left: 80px;
            }

            .main-content--wrapper.container{
              &>div>div>.row{
                width: 720px;
                max-width: 100%;
                margin: 0px auto;
              }
            }

            .videoplay{
              display:none;
            }

            .headervideosb>video{
              margin-top: 0px;
            }

            .bubble{
              width:140px;
            }

            #sw2whiteheadline{
              margin-top: 0px;
              margin-left: 80px;
            }

            h1.light{
              font-size: 30px;
            }
          }

          @media (min-width: 992px) {
            .main-content--wrapper.container{
              &>div>div>.row{
                width: 960px;
                max-width: 100%;
                margin: 0px auto;
              }
            }
           
          }

          @media (min-width: 1200px) {
            .main-content--wrapper.container{
              &>div>div>.row{
                width: 1240px;
                max-width: 100%;
                margin: 0px auto;
              }
            }
          }
          
        
    }