@import "../config";

	@include critical{

        .reversebutton{
            opacity:1;
            transition:.7s ease;
            width:200px;
            position: relative;
            z-index: 100;
            border-color: #000;
        }

        #reverse, #startarena{
          width:100%;
        }

        #unchain{
            width:100%;
            height:auto;
            display: block;
        }

        .unchaincontent{
            display:none;
        }

        .dr4, #reveal, #intro{
          display:none;
        }

        .form-area{
          width:100%;
        }

        .mouse{
          max-height: 100px;
        }


    }

    @include non-critical{

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .picknow{
          text-align: center;
					margin-bottom: -50px;
					z-index:1337;
        }

        .text{
          margin-top: -5px;
          padding: 0px 34px;
        }

        .textright{
          margin-top: -25px;
          padding: 0px 34px;
        }

        .text-right{
          text-align: right;
        }

        .highlightcopy{
          margin-top:50px;
          position: relative;
          &.left{
          }
        }

        .img.showhide{
          max-height:200px;
        }

        .reveal4{
          &:hover{
            cursor: pointer;
          }
        }

        .revealp4{
          &:hover{
            cursor: pointer;
          }
        }

        .slide-text{
          color:#fff;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
          opacity:0;
          transition:.7s ease;
          margin: 10px 20px;
          padding: 5px 20px;
          background-color: #ee7f00;
          border-radius: 6px;
          &-new{
            float: right;
          }
          &-old{
            float: left;
          }

        }

        .tool{
          margin-top:20px;
        }

        .header-slider--item-inner{
          margin-top: 30px;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
        }

        #submit{
          margin-top: .65rem;
          margin-bottom: 20px;
          padding: 8px 40px;
        }

        h2{
          text-transform: uppercase;
          color:#fff;
          font-weight: 700;
        }

        .radio>label{
          &:hover{
            cursor: pointer;
          }
          &>input{
            margin-right: 10px;
          }

        }

        .lines{
          opacity:0.6;
          width:100%;
          transition:.7s ease;
          margin:0px;
        }

        .join{
          float: right;
          z-index: 10;
          text-align: right;
        }

        .eightyplus{
          margin-top: 20px;
          max-width:350px;
        }

        .bubble{
          max-height: 180px;
          width: 110px;
          float: right;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        strong{
          color:#fff;
        }

        .video, .button{
            text-align: center;
        }

        .button{
            margin-top: 0px;
        }

        .pic{
          position: absolute;
          max-height:260px;
        }

        #comparison{
          min-height:280px;
          height:280px;
        }

        .comparebutton{
          text-align:center;
        }

        .explanation{
          text-align: left;
          margin-top: 20px;
        }

        .showhide{
          margin-top: 15px;
        }

        h3{
          text-align:center;
        }

        td{
          padding:5px;
        }

        .switchbutton{
          text-align: center;
        }

        h1{
          text-transform: uppercase;
          margin-bottom: 0px;
          &.feature{
            margin-bottom: 50px;
            margin-top: 50px;
          }
        }

          @media (min-width: 768px) {

            .button{
              margin-top: -350px;
            }

            .highlight{
              margin-top:20px;
							margin-bottom:20px;
            }

            .highlightcopy{

            }

            .lineone{
              margin-top: -60px;
              margin-bottom: -84px;
            }

            .linetwo{
              margin-top:-10px;
            }

            .textright{
              padding-right: 40px;
              padding-left: 35px;
            }

            .text{
              padding-left: 50px;
              margin-top: -25px;
            }
						.hlcontainer{
							padding-left: 50px;
						}

            .bubble{
              width: 180px;
              margin-right: -70px;
            }

            .join{
              margin-bottom: -180px;
            }

            .reveal4{
              margin-left: 0px;
            }
          }

          @media (min-width: 992px) {
            .reversebutton{
              display:block;
              position: fixed;
              bottom: 80px;
              right: 31px;
            }
          }

          @media (min-width: 1200px) {
            .reveal4{
              margin-left: 10px;
            }
          }


    }
