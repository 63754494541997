@import "../config";

/**
 *
 * @author Dennis Özet
 *
 */

@include exports("modules/Slider") {
	@include critical() {
		.slick-slider {
			display: block;
			box-sizing: border-box;
			user-select: none;
			touch-action: pan-y;

			-khtml-user-select: none;

			-webkit-tap-highlight-color: transparent;
			-webkit-touch-callout: none;

		}

		.slick-list {
			position: relative;
			display: block;
			overflow: hidden;
			margin: 0;
			padding: 0;

			&:focus {
				outline: none;
			}

			&.dragging {
				cursor: pointer;
				cursor: hand;
			}
		}

		.slick-slider .slick-track, .slick-slider .slick-list {
			transform: translate3d(0, 0, 0);
		}

		.slick-track {
			position: relative;
			top: 0;
			left: 0;
			display: block;

			&:before, &:after {
				display: table;
				content: '';
			}

			&:after {
				clear: both;
			}
		}

		.slick-loading .slick-track	{
			visibility: hidden;
		}

		.slick-slide {
			display: none;
			float: left;
			height: 100%;
			min-height: 1px;
			position: relative;

			img {
				display: block;
			}

			&.slick-loading img {
				display: none;
			}

			&.dragging img {
				pointer-events: none;
			}

			.slick-initialized & {
				display: block;
			}

			.slick-loading & {
				visibility: hidden;
			}

			.slick-vertical & {
				display: block;
				height: auto;
				// border: 1px solid transparent;
			}
		}
		[dir='rtl'] .slick-slide {
			float: right;
		}

		.slick-arrow.slick-hidden {
			display: none;
		}

		.slick-arrow, .slick-dots {
			display: none !important;
		}

	}

	@include non_critical() {

		ul {
			padding-left:20px;
		}

		.slick-arrow {
			display: block !important;
			position: absolute;
			top: 50%;
			z-index: 10;
			margin-top: -21px;
			color: $ci-color2-7;
			font-size: em(32px);
			cursor: pointer;

			&-prev {
				left: 0;
				margin-left: 5px;
			}

			&-next {
				right: 0;
				margin-right: 5px;
			}

			@include from(largedesktop) {
				&-prev {
					margin-left: 15px;
				}

				&-next {
					margin-right: 15px;
				}
			}
		}

		.slick-dots {
			display: block !important;
			overflow: hidden;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5px;
			z-index: 10;
			margin: 0;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;
				margin: 0 3px;
				text-align: center;
			}

			button {
				border: none;
				background: none;
				position: relative;
				padding: 4px;

				font-size: 0;
				color: #fff;

				cursor: pointer;

				&:before {
					content: "";
					display: block;
					border: 1px solid #fff;
					border-radius: 50rem;
					width: 10px;
					height: 10px;
					background: none;

					@include from(smalldesktop) {
						border-color: $ci-color2-7;
					}
				}
				&:focus {
					outline: none;
				}
			}
			li.slick-active button:before {
				background: #fff;
			}
		}

		// Carousel
		.carousel-arrow {
			display: block !important;
			position: absolute;
			top: 50%;
			z-index: 10;
			margin-top: -21px;
			color: $ci-color2-7;
			font-size: em(32px);

			&-prev {
				left: 0;
				margin-left: -5px;
			}

			&-next {
				right: 0;
				margin-right: -5px;
			}

		}

		// product-highlight
		.product-highlight {
			display: block;
			position: relative;			
			overflow: hidden;
			color: #fff !important;
			font-size: 1em;
			text-align: center;
			min-height: 320px;
			@media screen and (min-width:768px) {
				min-height: 400px;
			}
			@media screen and (min-width:1200px) {				
				min-height: 600px;
			}
			strong {
				display: block;
				font-weight: 700;
				font-size: 2.25em;
				text-transform: uppercase;				
				@media screen and (min-width:768px) {
					font-size: 2.5em;		
				}
				@media screen and (min-width:1200px) {
					font-size: 3em;
				}
			}
			&-wrapper {
				display: block;
				padding-top: 2em;
				padding-bottom: 2em;
				background: rgba(0,0,0,0.25);
				span {
					display: block;
					width: 1240px;
					max-width: 100%;
					margin: 0 auto;
				}
			}
			img {
				display: block;
				position: absolute;
				width: auto;
				height: 320px;
				object-fit: cover;
				left: 50%;
				top: 50%;
				-webkit-transform: translateX(-50%) translateY(-50%);
				-moz-transform: translateX(-50%) translateY(-50%);
				transform: translateX(-50%) translateY(-50%);
				z-index: -1;
				@media screen and (min-width:768px) {
					width: 100%;
					height: auto;
					object-fit: fill;
				}
			}
		}
	}
}
