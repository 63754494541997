@import "../config";

$pagination--background-color: #000 !default;
$pagination--border-color: $ci-color2-5 !default;
$pagination--font-color: #fff !default;
$pagination--active--font-weight: 700 !default;
$pagination--hover--font-color: $ci-color1 !default;


@include exports("modules/Pagination") {
	@include critical() {
		.pagination {

			.page-link {
				margin-left: 0;
				background-color: $pagination--background-color;
				border: none;
				border-left: 1px solid $pagination--border-color;
				color: $pagination--font-color;

			}


			.page-item {

				&:focus,
				&:hover {
					.page-link {
						background-color: $pagination--background-color;
						border-color: $pagination--border-color;
						color: $pagination--hover--font-color;
					}
				}

				&:active,
				&.active {
					.page-link {
						background-color: $pagination--background-color;
						border-color: $pagination--border-color;
						color: $pagination--font-color;
						font-weight: $pagination--active--font-weight;
						padding-top: 0.55rem;
						padding-bottom: 0.45rem;
					}
				}

				&:first-child .page-link {
					border-left: none;
				}

				&.disabled {
					.page-link,
					.page-link:focus,
					.page-link:hover {
						background-color: $pagination--background-color;
						color: $pagination--font-color;
					}
				}
			}

		}
	}
}
