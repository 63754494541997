@import "../config";

	@include critical{

        .form-area{
          width:100%;
        }

        .dblogo{
            width: 530px;
            position: relative;
            margin-bottom: 35px;
        }

        .bottom, .top {
          position:absolute;
          left:0;
        }

        .bubble{
          max-height: 180px;
          width: 110px;
          float: right;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        .join{
          float: right;
          z-index: 10;
          text-align: right;
        }

        .h3fix>h3{
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom:10px;
        }

        .nomobile{
          display:none;
        }
    }

    @include non-critical{

      h3{
        font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom:10px;
      }

      #cycler{position:relative;}
      #cycler img{position:absolute;z-index:1}
      #cycler img.active{z-index:3}

        .text{
          margin-top: 15px;
          padding:0px;
        }

        .featurebox{
          padding:5px;
          &>img:hover{
            -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
          }
        }

        .rev{
          min-height: 345px;
        }

        span{
            display:inline-block;
        }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
        }

        .features{
          color: #ccc;
          transition: 1s ease;
          &>h3{
            color:#fff;
            transition: 1s ease;
          }
        }

        .colorgray{
          color: #666;
          transition: 1s ease;
        }

        h3{
          
          &.colorgray{
            color: #666;
            transition: 1s ease;
          }
        }

        .outline{
          border: 1px solid #ee7f00;
          border-radius: 10px;
          padding:0px;
          &>img{
            border-radius: 10px 10px 0px 0px;
          }
          &>.text{
            padding:0px 20px;
          }
        }

        .product-variants--container{
          padding-left:10px;
        }

        #facebook{
          background: #fff;
        }

          @media (min-width: 768px) {
            .bubble{
              width: 180px;
              margin-right: -70px;
            }

            .join{
              margin-bottom: -180px;
            }

            .rev{
              min-height: 425px;
            }

            .nomobile{
              display:block;
            }
            
          }

          @media (min-width: 992px) {
            .rev{
              min-height: 565px;
            }
          }

          @media (min-width: 1200px) {
            .rev{
              min-height: 725px;
            }
          }


    }
