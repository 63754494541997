@import "../../config";

@include exports("screen/base/buttons") {}
@import "../../screen/base/buttons";

$tab-item--background-color: #000 !default;
$tab-item--color: #fff !default;
$tab-item--color-hover: $ci-color1 !default;
$tab-item--border-color: $ci-border-color-4 !default;

$tab-pills--border-color: $tab-item--border-color !default;
$tab-pills--active-bgcolor: $ci-orange !default;

$tab-pills--highlight-white-bgcolor: #fff !default;
$tab-pills--highlight-white-border-color: #fff !default;
$tab-pills--highlight-white-color: #000 !default;



@include exports("modules/content/PillsTabs") {

	@include critical() {

		.nav-pills,
		.nav-tabs {
			margin-left: -0.2rem;

			.nav-item {
				margin-left: 0.2rem;
			}
		}

		.nav-tabs {
			border: 0;

			.is-bordered--bottom &,
			.is-bordered--left &,
			.is-bordered--right &,
			.is-bordered--top & {
				margin-bottom: -3px;
			}

			.nav-item {
				margin-bottom: 0;
			}

			.nav-link {

				border: 2px solid transparent;
				font-weight: 500;

				&.active {
					background-color: $tab-item--background-color;
					color: $tab-item--color !important;
					//font-weight: bold;
					border: 2px solid $tab-item--border-color;
					border-bottom-color: #000;
					// padding: 7px 15px;

					&:hover, &:focus {
						background-color: $tab-item--background-color;
						color: $tab-item--color-hover;
						border-color: $tab-item--border-color;
						border-bottom-color: #000;
						// padding: 7px 15px;
					}
				}

				&.series{
					border:2px solid #373737;
				}

				&:hover, &:focus, &:active, &.active {
					border-color: $tab-item--border-color;
					border-bottom-color: transparent;
					background-color: $tab-item--background-color;
					color: $tab-item--color-hover;
					// border-bottom: 2px solid $tab-item--border-color;
					// padding: 6px 18px;
				}

				

			}

			// .nav-item {
			// 	margin-bottom: -4px;
			// }

			.highlight-white {
				// color: $tab-pills--highlight-white-color;

				.nav-link.active {
					background-color: $tab-pills--highlight-white-bgcolor;
					border-color: $tab-pills--highlight-white-border-color;
					color: $tab-pills--highlight-white-color !important;
				}

				&:hover, &:focus {
					color: $tab-pills--highlight-white-color !important;
				}

			}

		}

		.nav-pills {

			.nav-link {
				border: 1px solid $tab-pills--border-color;

				&.series{
					border:2px solid #373737;
				}

				&.active {
					background-color: $tab-pills--active-bgcolor;

					&:focus, &:hover {
						background-color: $tab-pills--active-bgcolor;
					}
				}

			}

			.nav-item {
				margin-bottom: 0.2rem;
			}
		}

		.tab-content {

			// .active.highlight-white {
			// 	background-color: $tab-pills--highlight-white-bgcolor;
			// }
			&.tab-inline--block {
				.tab-pane.active {
					display: inline-block;
				}
			}

			&.has-border--top {

				// border-top: 2px solid $ci-border-color-4;

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: -2px;
					left: 50%;
					width: 100vw;
					height: 100%;
					margin-left: -50vw;
					border-top: 2px solid $ci-border-color-4;
					z-index: -1;
				}
			}

			.highlight-white {

				&:before {
					content: "";
					display: block;
					position: absolute;
					left: 50%;
					width: 100vw;
					height: 100%;
					margin-left: -50vw;
					border-top: 2px solid #fff;
					background-color: #fff;
					z-index: -1;
				}

			}


		}
	}
}
