
$page--max-width: 1280px !default;
$page--padding: 10px !default;
$page--padding-top: 60px !default;
$page--padding-top-smalldesktop: 75px;

@include exports("layout/page") {

	.main--wrapper {
		overflow: hidden;
		padding-top: $page--padding-top;
		@include from(desktop) {
			padding-top: $page--padding-top-smalldesktop;
		}
	}

	.page--wrapper {
		max-width: $page--max-width;
		margin: 0 auto;
		padding: 0 ($page--padding);
	}


	#main {
		position: relative;
		z-index: 10;
	}

	.page--divider {
		margin: 0 -100%;
		border: {
			width: 0;
			style: solid;
			color: $ci-color1;
			bottom: {
				width: 1px;
			}
		}
	}
}
