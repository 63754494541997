@import "../config";

$card--background-color: #000 !default;
$card--border-color: $ci-color2-3 !default;
$card--font-size: 16px !default;
$card--color: $ci-color2-7 !default;



@include exports("modules/Card") {

	@include critical() {

		.card {
			margin-bottom: rem(20px);
			border-color: $card--border-color;
			background-color: $card--background-color;
			font-size: em($card--font-size);
			&.servicearea{
				border: 1px solid #929395;
    			border-radius: 10px!important;
				background: linear-gradient(to bottom right,#303030,#030303);
				height: 380px;
				&.current{
					border: 1px solid #ee7f00;
				}
			}
		}

		.card-header{
			background-color: #000;
			padding-left:0px;
			padding-right:0px;
		}

		@media (min-width: 992px) {
			.card.servicearea{
				height: 370px;
			}
		}

		@media (min-width: 1200px) {
			.card.servicearea{
				height: 370px;
			}
		}

	}

	@include non-critical{
		
	}

}
