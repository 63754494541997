@import "../config";

	@include critical{

        #unchainbutton{
            opacity:1;
            transition:.7s ease;
            width:200px;
        }

        @media (min-width: 768px){
          #unchainbutton{
            opacity:0;
          }
        }

        #unchain{
            width:100%;
            height:auto;
            display: block;
        }

        .unchaincontent{
            display:none;
        }

        .form-area{
          width:100%;
        }

        
    }

    @include non-critical{

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .slide-text{
          color:#fff;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
          opacity:0;
          transition:.7s ease;
          margin: 10px 20px;
          padding: 5px 20px;
          background-color: #ee7f00;
          border-radius: 6px;
          &-new{
            float: right;
          }
          &-old{
            float: left;
          }
          
        }

        .tool{
          margin-top:20px;
        }

        .header-slider--item-inner{
          margin-top: 30px;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
        }

        #submit{
          margin-top: .65rem;
          margin-bottom: 20px;
          padding: 8px 40px;
        }

        h2{
          text-transform: uppercase;
          color:#fff;
          font-weight: 700;
        }

        .radio>label{
          &:hover{
            cursor: pointer;
          }
          &>input{
            margin-right: 10px;
          }
          
        }

        .lines{
          opacity:0.6;
        }

        .eightyplus{
          margin-top: 20px;
          max-width:350px;
        }

        .bubble{
          max-height: 180px;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        strong{
          color:#fff;
        }

        .video, .button{
            text-align: center;
        }

        .button{
            margin-top: 0px;
        }

        .pic{
          position: absolute;
          max-height:260px;
        }

        #comparison{
          min-height:280px;
          height:280px;
        }

        .comparebutton{
          text-align:center;
        }

        .explanation{
          text-align: left;
          margin-top: 20px;
        }

        td{
          padding:5px;
        }

          @media (min-width: 768px) {

            .button{
              margin-top: -350px;
            }

            #comparison{
              min-height:480px;
            }

            .pic{
              max-height:none;
            }
          }

          @media (min-width: 992px) {
            #comparison{
              min-height:610px;
            }
          }

          @media (min-width: 1200px) {
            #comparison{
              min-height:420px;
            }
          }
          
        
    }