@import "../../config";

$headvisual--color: $ci-color2-5 !default;
$headvisual--background-color: $ci-color2-3 !default;
$headvisual--border-color: $ci-color2-3 !default;
$headvisual--border-radius: 0.25rem !default;
$headvisual--font-size: em(40px) !default;

$headvisual--icon-dimension: 70px !default;



@include exports("modules/content/Headvisual") {
	@include critical() {

		.headvisual {
			position: relative;
			border-bottom: 2px solid $headvisual--border-color;

			&--image {
				width: 100%;
			}

			&--icon {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding-bottom: 15px;
				color: $headvisual--color;
			}

			&--icon--wrapper {
				width: $headvisual--icon-dimension;
				height: $headvisual--icon-dimension;
				line-height: $headvisual--icon-dimension;
				border: 1px solid $headvisual--background-color;
				border-radius: $headvisual--border-radius;
				background: $headvisual--background-color;
				text-align: center;

				i {
					font-size: $headvisual--font-size;
				}
			}

		}

	}
}
