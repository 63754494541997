@import "../config";

$search-result--border-color: $ci-color2-4 !default;

@include exports("modules/SearchResults") {

	@include critical() {
		.template-search--results {

			.headvisual {
				max-height: 120px;
				overflow: hidden;
			}

			.search-input {

				.input-group {
					margin-bottom: 40px;
				}

				b {
					font-weight: 700;
					color: #fff;
				}
			}

			.search-result {
				&--item {

					padding: 40px 0;
					border-bottom: 1px solid $search-result--border-color;

					span {
						font-size: 16px;
					}

					@include from(smalldesktop) {
						&--title {
							margin-top: 0;
							margin-bottom: 15px;
						}
					}

				}
			}

			.pagination {
				margin-top: 40px;
			}

		}
	}
}
