@import "../../config";

$navtabs-width: 300px !default;
$navtabs-color: $ci-border-color-4;
$navtabs-fontsize: 20px !default;

$navcontent-hover--color: $ci-color1 !default;
$navcontent-fontsize: 14px !default;

$worldwide-wrapper--background-color: #000 !default;
$worldwide-wrapper--border-color: $ci-border-color-4 !default;
$worldwide-height: 74px !default;
$worldwide-handle--width: 60px !default;
$worldwide-handle--color: $ci-color2-5 !default;
$worldwide-handle--color--hover: $ci-color2-9 !default;

@include exports("modules/header/Worldwide") {

	@include critical() {

		.worldwide {

			&-handle-new{
				&:hover{
					cursor: pointer;
				}
				&.globedesktop{
					display:none;
				}
			}

			li .mainmenu--item {
				border-top: 1px solid #999;
				color: #999;
				.submenu-handle {
					font-size: 22px;
					color: $worldwide-handle--color;
				}
			}

			&-handle, &-wrapper {
				display: none;
		 	}

			&-content--element {
				list-style-type: none;
				padding-left: 0;

				li {
					margin: 5px 0;

					&.active {
						.worldwide-title {
							font-weight: 700;
							color: #fff;
						}

						a {
							&.active {
								font-weight: 700;
								text-decoration: none;
								color: #fff;
							}
						}
					}
				}

				.worldwide-title {
					font-size: 20px;
				}
			}
		}

		@include from(desktop) {

			.mainmenu--worldwide {
				// position: relative;
				top: 0;
				float: left;
				height: $worldwide-height;
			}

			.worldwide {

				visibility: visible;
				// position: relative;

				&-handle {
					display: inline-block;
					// width: $worldwide-handle--width;
					height: $worldwide-height;					
					text-align: center;
					line-height: $worldwide-height - 2px;
					font-size: 14px;
					color: $worldwide-handle--color;

					&:focus {
						color: $worldwide-handle--color;
					}

					.is-opened & {
						color: $ci-color1;
						&:hover {
							color: $ci-color1 !important;
						}
					}

					&:hover {
						color: $worldwide-handle--color--hover !important;
					}

					&-new.globedesktop{
						display: inline-block;
						width: $worldwide-handle--width;
						max-width: 148px;
						height: $worldwide-height;
						text-align: center;
						line-height: $worldwide-height - 2px;
						font-size: 20px;
						color: $worldwide-handle--color;

						&:focus {
							color: $worldwide-handle--color;
						}

						.is-opened & {
							color: $ci-color1;
							&:hover {
								color: $ci-color1 !important;
							}
						}

						&:hover {
							color: $worldwide-handle--color--hover !important;
							cursor: pointer;
						}
					}
				
					&-new.country {
						display: flex;
						align-items: center;
						width: auto;
						padding-left: 16px;
						padding-right: 16px;						
						max-width: 300px;
						height: 74px;
						line-height: normal !important;						
						.icon-sphere {
							display: inline-block;
							margin-right: 8px;
							vertical-align: middle;
						}
						.worldwide-text {
							white-space: nowrap;
							.country-name {
								display: none;
								white-space: pre-line;
								@media screen and (min-width:1280px) {
									display: inline-block;
								}
							}
							.country-code {
								display: none;
								text-transform: uppercase;
								vertical-align: middle;
								@media screen and (max-width:1280px) {
									display: inline-block;
								}
							}
							span {
								display: inline-block;
								vertical-align: middle;
								font-size: 16px;
								text-align: left;								
							}
						}
					}
				}

				.level-1 {
					display: none;
				}
			}
		}
	}

	@include non-critical() {

		.casesize-headline{
			font-size:6px;
			margin-top:-25px;
			display:block;
			text-align:center;
		}

		.casesize-link:hover{
			&>*{
				color:#fff;
			}
			&>.casesize-img{
				filter: brightness(1.5);
			}
		}

		.submitNewLanguage{
			min-width:200px;
		}

		.select2-container {
			z-index: 99999;
		}

		#ModalRegion>.modal-dialog-centered{
			margin-top: 35vh;
		}

		@include from(desktop) {

			.worldwide.is-opened {

				.worldwide {
					&-wrapper {
						display: block;
						// position: fixed;
						position: absolute;
						top: 75px;
						// left: 0;
						left: 50%;
						margin-left: -50vw;
						width: 100vw;
						// right: 0;
						background: $worldwide-wrapper--background-color;
						border-bottom: 1px solid $worldwide-wrapper--border-color;

						&--inner {
							display: flex;
						}

						.tab-content {
							width: 100%;
							flex: 1 1 auto;
						}

					}

					&-tabs {
						border-right: 1px solid $navtabs-color;
						//float: left;
						width: $navtabs-width;
						flex: 0 1 auto;
						.nav-tabs {
							border-bottom: 0px;
							padding: 10% 0 0;


							@include from(desktop) {
								padding: 15% 0 0;
							}

							.nav-item {
								float: none;
								margin: 3px 0;

								a {
									padding: 14px 0 14px 20px;
									border: 0;
									background: transparent;
									border-bottom-left-radius: .25rem;
									border-top-left-radius: .25rem;
									border-top-right-radius: 0;
									font-size: $navtabs-fontsize;
									font-weight: 500;
									&.active {
										border-top: 1px solid $navtabs-color;
										border-left: 1px solid $navtabs-color;
										border-bottom: 1px solid $navtabs-color;
										color: #fff;
										font-weight: 900;
										margin-right: -1px;
										background: $worldwide-wrapper--background-color;
									}

									&:hover {
										background-color: $navtabs-color;
									}
								}
							}
						}
					}

					&-title {
						float: left;
						font-size: $navtabs-fontsize;
					}

					&-content {
						padding: 0 0 0 30px;
					}

					&-content--item {
						margin: 24px 0;
						font-weight: 200;

						&.active {
							.worldwide-title {
								font-weight: 700;
								color: #fff;
							}
						}

						a {
							display: inline-block;
							padding: 0;
							text-decoration: underline;
							font-size: $navcontent-fontsize;
							background-color: transparent;

							&.active {
								font-weight: 700;
								text-decoration: none;
								color: #fff;
							}

							&:hover {
								color: $navcontent-hover--color;
							}
						}

					}

					&-image {
						img {
							max-width: 80%;
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
}
