@import "../config";

@include exports("modules/ProductCompare") {

	@include critical() {

		.template-product-compare {

			.product-category-teaser--item {
				border: 1px solid $ci-color2-11;
				border-radius: $default--border-radius;
				overflow: hidden;
				margin-bottom: 20px;
				padding: 0;
			}

			.product-category-teaser .btn {
				max-width: 190px;
				margin: 0 auto 10px;
			}

			.product-category-teaser--title {
				font-weight: 200;
				font-size: 26px;
			}
		}
	}
}