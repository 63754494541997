@import '../../config';

/**
 * list
 *
 *
 * @author Björn Hase
 *
 */

$list--icon--color: $ci-color1 !default;

$list--arrow--link--color: $text--color !default;

$list--arrow--li--font-size: 17px !default;
$list--arrow--li--icon: arrow-right2 !default;

$list--icon--font-size: 18px !default;
$list--li--font-size: 24px !default;

$list--contrast--link--color: white !default;

@include exports("modules/helper/List") {
	 @include critical() {

		// list
		.list {

			&--base-icon {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}

			&--icon {
				margin-left: 35px;
				margin-bottom: 10px;
				text-indent: -21px;

				[class*=" icon-"],
				[class^="icon-"] {
					&:before {
						font-size: em($list--icon--font-size);
						color: $list--icon--color;
						margin: 0 15px 0 0;
					}
				}

				li {
					font-size: em($list--li--font-size);
					margin: 15px 0 0;

					@include from(tablet) {
						margin-top: 20px;
					}
				}

				@extend .list--base-icon;
			}

			&--arrow {

				li {
					margin-bottom: 7px;
					font-size: em($list--arrow--li--font-size);
					font-weight: normal;

					a {
						color: $list--arrow--link--color;
					}

					&:before {
						margin-right: 5px;

						@include iconfont-icon($list--arrow--li--icon);
					}
				}

				&--contrast {
					li {
						a {
							color: $list--contrast--link--color;
						}

						&:before {
							color: $list--contrast--link--color;
						}
					}
				}

				@extend .list--base-icon;

			}
		}
	}
}
