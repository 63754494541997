@import "../config";
@import "Slider";

/**
 *
 * @author Dennis Özet
 *
 */

 @include exports("modules/Carousel") {

	@include critical() {
		.carousel {

			overflow: hidden;
			list-style: none;
			margin: 0;
			padding: 0;

			&--item {
				float: left;
				text-align: center;
			}

			&--link {
				display: inline-block;
				padding: 15px 30px;
			}
		}
	}
 }