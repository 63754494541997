@import "../../config";

$collapse-icon--background-color: $ci-color2-2 !default;
$collapse-icon--color: $ci-color2-9 !default;
$arrow-width: 50px !default;
$arrow-height: $arrow-width !default;
$arrow-icon--color: $ci-color2-7 !default;
$arrow-icon--size: 18px !default;

$awards--teaser-row--height: 85px !default;
$awards--teaser-row--height-tablet: 70px !default;
$awards--teaser-row--height-smalldesktop: 90px !default;
$awards--teaser-row--height-largedesktop: 70px !default;

@include exports("modules/product/AwardsCollapse") {
	@include critical() {

		#product-awards--result-list--container .row{
			width:100%;
		}
		.awards {

			margin-bottom: 50px;
			cursor: default;

			.collapse {
				display: block;
				height: 0;
				// @INFO: This value has to be calculated dynamically with JS
				// min-height: 135px;
				overflow: hidden;
				.no-js & {
					height: auto !important;
				}
			}
			.collapse.in {
				height: auto;
			}
			.collapsing {
				// @INFO: These values has to be calculated dynamically with JS
				// height: 135px;
				// min-height: 135px;
			}

			&--collapse-icon {
				display: inline-block;
				margin-top: 9px;

				@include from(largedesktop) {
					margin-top: 15px;
				}

				&:before {
					@include iconfont-icon(minus);
				}


				&-bg {
					display: inline-block;
					height: 40px;
					width: 40px;
					background: $collapse-icon--background-color;
					color: $collapse-icon--color;
					margin-top: 25px;

					@include from(tablet) {
						margin-top: 18px;
					}
					@include from(smalldesktop) {
						margin-top: 22px;
					}
					@include from(desktop) {
						margin-top: 18px;
					}
					@include from(largedesktop) {
						height: 54px;
						width: 54px;
						margin-top: 15px;
					}

					&.collapsed .awards--collapse-icon:before {
						@include iconfont-icon(plus);
					}

				}
			}

			.col-md-1 {
				margin-bottom: 20px;
			}

			.awardfile {
				overflow: hidden;
				// display: inline-block;
				display: block;
				margin-bottom: 20px;
				// height: $awards--teaser-row--height;

				img {
					opacity: 0.6;
					max-height: 115px;

				}
				&:hover {
					img {
						opacity: 1;
					}
				}
			}

		}

	}
}
