/**
 * Basic styles for typo
 *
 *
 * @author Dennis Özet
 */

$base--font-size: 16px !default;
$base--line-height: 24px !default;
$base--font-family: $ci-font !default;
$base--font-weight: 200;

$text-color: #000 !default;

$link--color: #000 !default;
$link--color--hover: $ci-color1 !default;

@include exports("base/typo") {

	html,
	body {
		font-size: $base--font-size;
		line-height: line-height($base--line-height);
		font-family: $base--font-family;
		font-weight: $base--font-weight;

		color: $ci-color2-9;
	}



	p {
		a {
			color: $link--color;
			text-decoration: underline;
		}
	}

	small, .small {
		font-size: em(12px);
	}





	// @TODO: Nachfolgende Klassen ggf. noch verschieben?!

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	.hide {
		display: none;
	}
}
