@import "../config";

	@include critical{

        .form-area{
          width:100%;
        }

        .bottom, .top {
          position:absolute;
          left:0;
        }

        .bubble{
          max-height: 180px;
          width: 110px;
          float: right;
          &:hover{
            cursor: pointer;
          }
        }

        .contestbubble{
          position: absolute;
          right: 0;
          max-width: 200px;
        }

        .join{
          float: right;
          z-index: 3000;
          text-align: right;
        }

        .h3fix>h3{
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom:10px;
        }

        .nomobile{
          display:none;
        }

        .hoverplay{
          max-width:100%;
        }

        .headervideosb{
          &>video{
            max-width:100%;
            margin-top: -110px;
            z-index: 0;
          }
        }

        .header-slider--item-inner{
          z-index: 3000;
          position: inherit;
        }
    }

    @include non-critical{

      .btn-cta{
        &:hover{
          &>a{
            color: #ee7f00;
          }
        }
      }

      .header-slider--item-subtitle>p{
        margin-bottom:0px;
      }

      h3{
        font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom:10px;
      }

      .light{
        font-size: 15px;
      }

      label{
        cursor: pointer;
      }

      #cycler{position:relative;}
      #cycler img{position:absolute;z-index:1}
      #cycler img.active{z-index:3}

        .text{
          margin-top: 15px;
          padding:0px;
        }

        .case{
          width: 400px;
          max-width:100%;
          margin: 0px auto;
        }

        .featurebox{
          padding:5px;
          &>img:hover{
            -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
          }
        }

        .hoverplay{
          cursor: pointer;
        }

        .center{
          text-align: center;
        }

        .m700{
          width: 700px;
          max-width:100%;
        }

        .buttonwrap{
          text-align: center;
          margin-top: -30px;
          &>*{
            margin:0px auto;
          }
        }

        button{
          margin: 0px auto;
        }

        #tnx{
          margin-top: 60px;
          transition:.7s ease;
        }

        .rules{
          text-align: center;
          margin-bottom: 20px;
          &>a{
            font-weight: bold;
            text-decoration: underline;
          }
          &>p{
            color: #666;
            font-size: 12px;
            &>strong{
              color: #fff;
              font-size: 18px;
            }
          }
          
        }

        .features{
          color: #ccc;
          transition: 1s ease;
          &>h3{
            color:#fff;
            transition: 1s ease;
          }
        }

        .colorgray{
          color: #666;
          transition: 1s ease;
        }

        h3{
          
          &.colorgray{
            color: #666;
            transition: 1s ease;
          }
        }

        .outline{
          border: 1px solid #ee7f00;
          border-radius: 10px;
          padding:0px;
          &>img{
            border-radius: 10px 10px 0px 0px;
          }
          &>.text{
            padding:0px 20px;
          }
        }

        .product-variants--container{
          padding-left:10px;
        }

        #facebook{
          background: #fff;
        }

        .pt-2vid{
          padding-top:6px;
        }

          @media (min-width: 768px) {

            .join{
              margin-bottom: -160px;
              padding-right: 0px;
              margin-top: -80px;
            }

            .rev{
              min-height: 425px;
            }

            .nomobile{
              display:block;
            }

            .light{
              font-size: 24px;
            }

            .buttonwrap{
              margin-top:-120px;
            }

            .pt-2vid{
              padding-top:10px;
            }
            
          }

          @media (min-width: 992px) {
            
            .bubble{
              width: 180px;
            }
            
            .rev{
              min-height: 565px;
            }

            .pt-2vid{
              padding-top:16px;
            }
          }

          @media (min-width: 1200px) {
            .rev{
              min-height: 725px;
            }

            .pt-2vid{
              padding-top:22px;
            }
          }


    }
