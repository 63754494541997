@import "../../config";

$img--teaser-text: 14px !default;

@include exports("modules/helper/Img") {
	@include critical() {
		.img {
			&--teaser-text {
				font-size: em($img--teaser-text);
			}
		}
	}
}
