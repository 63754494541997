
%clearfix {
	@include pie-clearfix();
}

@include exports("helper/clearfix") {

	.clearfix {
		@extend %clearfix;
	}
}
