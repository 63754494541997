@import "../../config";

$readmore--trigger--padding: 10px !default;
$readmore--trigger--wrapper--margin: 20px !default;
$readmore--trigger--icon--size: 20px !default;

@include exports("modules/content/Readmore") {
	@include critical() {

		.readmore {

			&--trigger--wrapper {
				margin: ($readmore--trigger--wrapper--margin) 0;

				.no-js {
					display: none;
				}
			}
			&--trigger {
				padding: $readmore--trigger--padding;

				i {
					display: inline-block;
					font-size: rem($readmore--trigger--icon--size);
				}
				&.collapsed .icon-chevron-thin-down {
					transform: rotate(0);
				}
				.icon-chevron-thin-down {
					transform: rotate(180deg);
				}
			}

		}

	}
}
