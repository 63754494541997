@import "../../config";

$model-compare--title--color: $ci-color2-15 !default;
$model-compare--title--font-size: em(20px) !default;

$model-compare--border-color: $ci-color2-6 !default;
$model-compare--bg-color: $ci-color2-6 !default;

$model-compare--header--bg-color: $ci-color2-3 !default;

$model-compare--image--bg-color: $ci-color2-11 !default;
$model-compare--image-filled--bg-color: #000 !default;

@include exports("modules/product/ProductCollector") {

	@include critical() {
		.icon-button--rounded.add-to-product-collector {
			display: none;
			@include from(smalldesktop) {
				display: inline-block;
			}
		}
	}

	@include non-critical() {

		.product-collector {

			position: fixed;
			left: 0;
			bottom: 0;
			z-index: 1200;
			width: 100%;

			border-top: 1px solid $model-compare--border-color;
			background-color: $model-compare--bg-color;

			&-content {
				transition: height 400ms linear;
				height: 0;
			}
			&.is-open &-content {
				height: 185px;
			}


			&-header {
				padding: 15px 0;
				background-color: $model-compare--header--bg-color;
				@include from(smartphone) {
					&--intro-3{
						display: none;
					}
					&--intro-2{
						display: none;
					}
				}
				@include from(smalldesktop) {
					&--intro-3{
						display: none;
					}
					&--intro-2{
						display: inline;
					}
				}
				@include from(desktop) {
					&--intro-3{
						display: inline;
					}
					&--intro-2{
						display: none;
					}
				}
			}

			&-column {
				padding-top: 20px;
				padding-bottom: 10px;
			}

			&--image {
				overflow: hidden;
				position: relative;
				height: 125px;
				background-color: $model-compare--image--bg-color;

				&--filled {
					background-color: $model-compare--image-filled--bg-color;

					i {
						display: none;
					}
				}

				i {
					position: absolute;
					left: 50%;
					top: 50%;
					margin: -12px 0 0 -9px;
				}
			}

			&--title {
				padding-top: 10px;
				font-size: em(16px);
				font-weight: 500;
				color: $model-compare--title--color;
			}

			&--handler {
				display: inline-block;
				font-size: em(14px);
				color: #fff;
				cursor: pointer;

				&--hide {
					display: none;
				}
				&--show {
					display: inline-block;
				}
			}
			&.is-open &--handler {
				&--hide {
					display: inline-block;
				}
				&--show {
					display: none;
				}
			}


			.form-group {
				margin-top: -3px;
				margin-bottom: 0;
			}

			.custom-radio {
				vertical-align: top;
				// margin-top: 3px;
				padding-left: 9px;
			}

			.close {
				position: absolute;
				top: 0;
				right: 0;
				opacity: 1;
				margin-right: 10px;
				font-size: 2.5rem;
				color: $ci-color2-11;
				text-shadow: none;
			}

		}

	}

}
