
$offcanvas--breakpoints: (smartphone, tablet) !default;

@include exports("helper/breakpoints") {

	// Communicate between CSS/Sass and JavaScript via JSON
	$__breakpoints: ();
	@for $i from 1 through length($slicer-breakpoint-names) {
		$__breakpoint-name: nth($slicer-breakpoint-names, $i);
		// $__col-name: nth($gridlayout--column-classes, $i);
		$__startPoint: strip-units(nth($slicer-breakpoints, $i));

		$__breakpoints: map-merge($__breakpoints, (
			$__breakpoint-name: $__startPoint
		));
	}
	@include cssConfig((
		breakpoints: $__breakpoints,
	));


	#css-config {
		display: none;
		font-size: 0;
		// IE won't accept the `content` property!
		font-family: json-encode($css-conf);
	}

	$__breakpoints-from: ();
	@each $__breakpoint-name in $slicer-breakpoint-names {

		$__breakpoints-from: append($__breakpoints-from, $__breakpoint-name);

		// I know it's ugly...
		$showOffcanvasMenu: index($offcanvas--breakpoints, $__breakpoint-name) != null;

		@include from($__breakpoint-name) {
			#breakpoint-config {
				// IE won't accept the `content` property!
				font-family: json-encode((
					active-breakpoint: $__breakpoint-name,
					from: $__breakpoints-from,
					// showOffcanvasMenu: $showOffcanvasMenu,
				));
			}
		}

		// @TODO: Implement configurations for the corresponding mixin `to`, etc.
	}

}
